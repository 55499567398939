import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, connect, useDispatch } from 'react-redux';
import { Avatar, Modal, Dropdown, Space } from 'antd';
import {
  UserOutlined,
  ExclamationCircleOutlined,
  LogoutOutlined,
} from '@ant-design/icons';
import ProfileModal from '../../profile/Profile';
import { destroyTabs } from '../redux/modules/layout';
import Cookies from 'js-cookie';
import { resetInitAuthorization } from '../redux/modules/authorization';
function SiderProfile(props) {
  const [visible, setVisibility] = useState(false);
  const [countP, setCountP] = useState(false);
  const [open, setOpen] = useState(false);
  const { tourReferences, userName } = useSelector(
    ({ tour, authorization }) => ({
      tourReferences: tour.tourReferences,
      userName: authorization.initAuthorization.userName,
    })
  );
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const handleCancel = () => {
    setVisibility(false);
    setCountP(false);
  };

  const showProfile = () => {
    setVisibility(true);
    const timeout = setTimeout(() => {
      setCountP(true);
    }, 1000);
    return () => clearTimeout(timeout);
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  const logOutFunction = () => {
    Modal.confirm({
      title: 'Logout',
      icon: <ExclamationCircleOutlined />,
      content:
        'To securely log out of the Portal please close all windows of your current Internet Browser.',
      onOk: () => {
        dispatch(destroyTabs());
        dispatch(resetInitAuthorization());
        Cookies.set('continueSession', false, { expires: 365 });
        navigate('/');
      },
    });
  };
  const items = [
    {
      label: (
        <a onClick={() => showProfile()}>
          <UserOutlined /> My Profile
        </a>
      ),
      key: '0',
    },
    {
      label: (
        <a onClick={() => logOutFunction()}>
          <LogoutOutlined /> Logout
        </a>
      ),
      key: '1',
    },
  ];
  return (
    <>
      <ProfileModal
        visible={visible}
        title='My Profile'
        okText='Save'
        onCancel={handleCancel}
        {...props}
        handleVisibility={() => setVisibility(!visible)}
        showWalkthrough={visible}
        countP={countP}
      />
      <Space wrap></Space>
      <Dropdown
        menu={{
          items,
        }}
        trigger={['click']}
        placement='bottomRight'>
        <a onClick={(e) => e.preventDefault()}>
          <Avatar
            ref={tourReferences.profileSpaceTour}
            title={userName}
            className='user-avatar'
            size='medium'
            style={{ cursor: 'pointer' }}>
            {userName ? userName.charAt(0).toUpperCase() : ''}
          </Avatar>
          <span className='user-name'> {userName}</span>
        </a>
      </Dropdown>
    </>
  );
}

const mapStateToProps = ({ authorization }) => {
  return {
    userName: authorization.initAuthorization.userName,
  };
};

const mapDispatchToProps = (_dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SiderProfile);
