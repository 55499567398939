import React, { useState, useEffect } from 'react';
import { Checkbox, Tooltip } from 'antd';

const CptCustomRowCheckboxRenderer = (props) => {
  const [selected, setSelected] = useState(false);

  const handleClick = (event) => {
    setSelected(event.target.checked);
    props.node.setSelected(event.target.checked);
    props.api.refreshHeader();
  };

  useEffect(() => {
    if (props.node.isSelected()) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [props.node.isSelected()]);

  const getErrorDetails = () => {
    let consolErrLength = Object.getOwnPropertyNames(
      props.node.data.errors
    ).length;
    let errorsConsolidated = [];
    if (consolErrLength > 0) {
      for (const property in props.node.data.errors) {
        if (props.node.data.errors[property].length) {
          errorsConsolidated.push(props.node.data.errors[property][0]);
        }
      }
    }
    if (errorsConsolidated.length) {
      return errorsConsolidated;
    }
    return [];
  };

  const getErrorContent = () => {
    const errorsConsolidated = getErrorDetails();
    if (errorsConsolidated.length) {
      return (
        <>
          {errorsConsolidated.map((data) => (
            <div style={{ fontSize: 10 }} key={data}>
              {data}
            </div>
          ))}
        </>
      );
    }
    return null;
  };

  const hasAccess = () => {
    if (props.view === 'all') {
      return props.data.history_flag === 'N';
    } else {
      return props.data.status !== 'REMOVED';
    }
  };

  return (
    <>
      {(() => {
        if (hasAccess()) {
          return (
            <Tooltip
              title={getErrorDetails().length ? getErrorContent() : ''}
              placement='rightBottom'>
              <span
                className={`row-checkbox-container ${
                  getErrorDetails().length ? 'row-has-error' : null
                }`}>
                <Checkbox
                  checked={selected}
                  defaultChecked={false}
                  onClick={handleClick}
                />
              </span>
            </Tooltip>
          );
        } else {
          return <span></span>;
        }
      })()}
    </>
  );
};

export default CptCustomRowCheckboxRenderer;
