/**
 * Created this file to Download ACP excel template using "Excel Download" menu option.
 */
/* Importing supports files  */
import React, { useState, useEffect } from 'react';
import { Modal, Button, Radio, Form } from 'antd';
const RadioGroup = Radio.Group;

/* Defining the Api service call to fetch the details of the download excel templates based on the user access */
const ExcelTemplate = (props) => {
  const [value, setSelectedValue] = useState('');

  const onChangeRadio = (e) => {
    setSelectedValue(e.target.value);
  };

  useEffect(() => {
    const templates = props.data.excelTemplate;
    if (props.visible && templates.length === 1) {
      setSelectedValue(templates[0].value);
    }
  }, [props.visible]);

  // Created the below function to hide the Download excel template popup
  const handleCancel = () => {
    props.handlevisiblity(false);
  };

  return (
    <Modal
      title='Download Excel Template'
      visible={props.visible}
      onCancel={() => props.handlevisiblity(false)}
      footer={[
        <a
          key='any-name'
          href={value}
          target='_blank'
          style={{ marginRight: 5 }}>
          <Button key='submit' type='primary'>
            Download
          </Button>
        </a>,
        <Button key='submitCancel' type='default' onClick={handleCancel}>
          Cancel
        </Button>,
      ]}>
      {/* Here we used the map method to parse the json objects into the form components to display the options in the radio button */}
      <Form>
        {props.data.excelTemplate &&
          props.data.excelTemplate.map((excelTemplate) => {
            return (
              <RadioGroup
                key={excelTemplate.value}
                onChange={onChangeRadio}
                value={value}>
                <Radio value={excelTemplate.value}>{excelTemplate.label}</Radio>
              </RadioGroup>
            );
          })}
      </Form>
    </Modal>
  );
};

export default ExcelTemplate;
