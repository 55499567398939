import { message } from 'antd';
import { getLastRowIndex } from '../../../../helpers/utils';
import service from '../../../../service/Service';
import store from '../../../../redux/store';
import Cookie from 'js-cookie';
import { getUUID } from 'helpers/utils';

const AMSADMIN_USER_UPDATE = 'amsadmin/store/AMSADMIN_USER_UPDATE';
const AMSADMIN_USER_UPDATE_SUCCESS =
  'amsadmin/store/AMSADMIN_USER_UPDATE_SUCCESS';
const AMSADMIN_USER_DETAIL = 'amsadmin/store/AMSADMIN_USER_DETAIL';
const AMSADMIN_USER_DETAIL_SUCCESS =
  'amsadmin/store/AMSADMIN_USER_DETAIL_SUCCESS';
const ADD_AMSADMIN_USER = 'amsadmin/store/ADD_AMSADMIN_USER';
const ADD_AMSADMIN_USER_SUCCESS = 'amsadmin/store/ADD_AMSADMIN_USER_SUCCESS';
const ADD_AMSADMIN_USER_FAIL = 'amsadmin/store/ADD_AMSADMIN_USER_FAIL';
const AMSADMIN_USER_LIST_COLUMNS = 'amsadmin/store/AMSADMIN_USER_LIST_COLUMNS';
const AMSADMIN_USER_LIST_COLUMNS_SUCCESS =
  'ums/amsadmin/store/AMSADMIN_USER_LIST_COLUMNS_SUCCESS';
const UMSADMIN_USER_LIST_COLUMNS_FAIL =
  'ums/amsadmin/UMSADMIN_USER_LIST_COLUMNS_FAIL';
const AMSADMIN_USER_LIST_ROWS_INFO_SUCCESS =
  'ams/store/AMSADMIN_USER_LIST_ROWS_INFO_SUCCESS';
const GET_AMSADMIN_LOADING = 'amsadmin/store/GET_AMSADMIN_LOADING';

const AMSADMIN_CREATE_USER_DROPDOWN_DATA_LOADING =
  'amsadmin/store/AMSADMIN_CREATE_USER_DROPDOWN_DATA_LOADING';
const AMSADMIN_CREATE_USER_DROPDOWN_DATA_SUCCESS =
  'amsadmin/store/AMSADMIN_CREATE_USER_DROPDOWN_DATA_SUCCESS';
const AMSADMIN_CREATE_USER_DROPDOWN_DATA_FAIL =
  'amsadmin/store/AMSADMIN_CREATE_USER_DROPDOWN_DATA_FAIL';

const ADD_DEPOT_DETAILS = 'amsadmin/store/ADD_DEPOT_DETAILS';
const ADD_DEPOT_DETAILS_SUCCESS = 'amsadmin/store/ADD_DEPOT_DETAILS_SUCCESS';

const DELETE_DEPOT_DETAILS = 'amsadmin/store/DELETE_DEPOT_DETAILS';
const DELETE_DEPOT_DETAILS_SUCCESS =
  'amsadmin/store/DELETE_DEPOT_DETAILS_SUCCESS';

const initialState = {
  isLoading: false,
  amsAdminCreateUserDropdownDataLoaded: false,
  amsAdminCreateUserDropdownDataLoading: false,
  amsAdminCreateUserDropdownData: [],
  addUserMsg: '',
  userName: '',
  userDetails: [],
  addCpDetails: [],
  deleteCpDetails: [],
  updateUserMsg: '',
  updatingUserState: false,
  storeColumns: {},
  rowsInfo: {
    endRow: 0,
    lastRow: 0,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case AMSADMIN_CREATE_USER_DROPDOWN_DATA_LOADING:
      return { ...state, amsCreateUserDropdownDataLoaded: true };
    case AMSADMIN_CREATE_USER_DROPDOWN_DATA_SUCCESS:
      return {
        ...state,
        amsAdminCreateUserDropdownDataLoaded: true,
        amsAdminCreateUserDropdownDataLoading: false,
        amsAdminCreateUserDropdownData: action.payload,
        error: null,
      };
    case AMSADMIN_CREATE_USER_DROPDOWN_DATA_FAIL:
      return {
        ...state,
        amsAdminCreateUserDropdownDataLoading: false,
        error: action.payload,
      };

    case ADD_AMSADMIN_USER:
      return { ...state, isLoading: true };
    case ADD_AMSADMIN_USER_SUCCESS:
      return { ...state, addUserMsg: action.data, isLoading: false };
    case ADD_AMSADMIN_USER_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case AMSADMIN_USER_UPDATE:
      return { ...state, isLoading: true };
    case AMSADMIN_USER_UPDATE_SUCCESS:
      return { ...state, updateUserMsg: 'SUCCESS', isLoading: false };
    case AMSADMIN_USER_DETAIL:
      return { ...state, isLoading: true };
    case AMSADMIN_USER_DETAIL_SUCCESS:
      return { ...state, userDetails: action.data, isLoading: false };
    case AMSADMIN_USER_LIST_COLUMNS:
      return { ...state, isLoading: true };
    case AMSADMIN_USER_LIST_COLUMNS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        storeColumns: action.payload,
        error: null,
      };
    case UMSADMIN_USER_LIST_COLUMNS_FAIL:
      return { ...state, isLoading: false, error: action.payload };
    case AMSADMIN_USER_LIST_ROWS_INFO_SUCCESS:
      return { ...state, rowsInfo: action.payload };
    case GET_AMSADMIN_LOADING:
      return { ...state, createUserDropdownPlantsDataLoaded: false };
    case ADD_DEPOT_DETAILS:
      return { ...state };
    case ADD_DEPOT_DETAILS_SUCCESS:
      return { ...state, addCpDetails: action.data };
    case DELETE_DEPOT_DETAILS:
      return { ...state };
    case DELETE_DEPOT_DETAILS_SUCCESS:
      return { ...state, deleteCpDetails: action.data };
    default:
      return state;
  }
}

export function getColumns() {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: AMSADMIN_USER_LIST_COLUMNS });
    return api
      .post(
        'ums',
        formatParams(
          { type: 'AG_GRID_COLUMNS_AMS', view: 'UMSADMIN' },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data) {
          dispatch({ type: AMSADMIN_USER_LIST_COLUMNS_SUCCESS, payload: data });
        } else {
          dispatch({
            type: UMSADMIN_USER_LIST_COLUMNS_FAIL,
            payload: 'Something went wrong! Please try again.',
          });
        }
      });
  };
}

export function getRows() {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      params.api.showLoadingOverlay();
      return api
        .post(
          'ums',
          formatParams(
            {
              type: 'AG_GRID_ROWS_AMS',
              view: 'UMSADMIN',
              gridParams: { ...params.request },
            },
            store.getState
          )
        )
        .then((response) => {
          const { data } = response;
          if (Object.keys(data).length) {
            params.success({
              rowData: data.rowData,
              rowCount: getLastRowIndex(params.request, data.rowData),
            });
            const endRow =
              params.request.endRow > data.lastRow
                ? data.lastRow
                : params.request.endRow;
            store.dispatch({
              type: AMSADMIN_USER_LIST_ROWS_INFO_SUCCESS,
              payload: { lastRow: data.lastRow, endRow },
            });
            // Hide overlay loading on success
            params.api.hideOverlay();
            const rowsPerRequest = process.env.REACT_APP_AG_GRID_ROWS_LIMIT;
            if (
              !data.rowData.length &&
              params.request.startRow < rowsPerRequest
            ) {
              params.api.showNoRowsOverlay();
            }
          } else {
            params.fail();
          }
        })
        .catch((_error) => {
          params.fail();
        });
    },
  };
}

export function fetchDropdownData() {
  return (dispatch, getState, { api, formatParams }) => {
    const authorized = Object.keys(
      getState().authorization.initAuthorization
    ).length;
    if (authorized) {
      dispatch({ type: AMSADMIN_CREATE_USER_DROPDOWN_DATA_LOADING });
      return api
        .post(
          'ums',
          formatParams(
            {
              type: 'UMS_ADMIN_DROPDOWN_DATA',
            },
            getState
          )
        )
        .then((response) => {
          const { data } = response;
          if (data) {
            dispatch({
              type: AMSADMIN_CREATE_USER_DROPDOWN_DATA_SUCCESS,
              payload: data,
            });
          } else {
            dispatch({
              type: AMSADMIN_CREATE_USER_DROPDOWN_DATA_FAIL,
              payload: 'Error in fetching dropdown data',
            });
          }
          return response;
        });
    }
  };
}

export function add(formattedValues) {
  return (dispatch, _getState, { api }) => {
    dispatch({ type: ADD_AMSADMIN_USER });
    let userDetails = [];
    let getRole = formattedValues.user_role;
    userDetails.push({
      user_email: formattedValues.email,
      user_name: formattedValues.user_name,
      role: getRole,
      editable: 'true',
      userdetailsarray: formattedValues.userDetailsArray,
    });

    return api
      .post('ums', {
        TXN: {
          header: {
            transaction_type: 'MODULE_ADMIN',
            type: 'ADD_USER',
            uuid: getUUID(),
            customer: 'SANM',
            requestor: Cookie.get('email'),
            source: 'PORTAL',
            excel: 'NO',
          },
          details: userDetails,
        },
      })
      .then((response) => {
        const { data } = response;
        const TXNGET = data.TXN;
        if (TXNGET[0].return_msg === 'SUCCESS') {
          dispatch(update(data));
        } else {
          dispatch({
            type: ADD_AMSADMIN_USER_FAIL,
            payload: 'Error in fetching data',
          });
        }
        return TXNGET;
      });
  };
  function update(data) {
    return { type: ADD_AMSADMIN_USER_SUCCESS, data };
  }
}

export function getUserDetails(email, id) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: AMSADMIN_USER_DETAIL });
    return api
      .post(
        'ums',
        formatParams(
          {
            type: 'UMS_ADMIN_VIEW_SPECIFIC_USER',
            details: {
              email: email,
              id: id,
            },
          },
          getState
        )
      )
      .then((response) => {
        if (response) {
          const data = response.data.result.userdetails[0];
          if (data) {
            dispatch(update(data));
          }
        }
        return response;
      });
  };
  function update(data) {
    return { type: AMSADMIN_USER_DETAIL_SUCCESS, data };
  }
}

export function updateUserDetails(formattedValues) {
  return (dispatch, _getState, { api }) => {
    dispatch({ type: AMSADMIN_USER_UPDATE });
    let userDetails = [];
    let getRole = formattedValues.user_role_edit;
    userDetails.push({
      user_email: formattedValues.email_edit,
      user_name: formattedValues.user_id_edit,
      role: getRole,
      userdetailsarray: formattedValues.userdetailsarray,
      // id: formattedValues.id,
    });

    return api
      .post('ums', {
        TXN: {
          header: {
            transaction_type: 'MODULE_ADMIN',
            type: 'EDIT_USER',
            uuid: getUUID(),
            customer: 'SANM',
            requestor: Cookie.get('email'),
            source: 'PORTAL',
            excel: 'NO',
          },
          details: userDetails,
        },
      })
      .then((response) => {
        const { data } = response;
        const TXNGET = data.TXN;
        if (TXNGET[0].return_msg === 'SUCCESS') {
          dispatch(update());
        } else {
          dispatch({
            type: ADD_AMSADMIN_USER_FAIL,
            payload: 'Error in updating user',
          });
        }
        return TXNGET[0].return_msg;
      });
  };
  function update() {
    return { type: AMSADMIN_USER_UPDATE_SUCCESS };
  }
}

export function deleteUser(formattedValues) {
  return (_dispatch, _getState, { api }) => {
    let userDetails = [];
    userDetails.push({
      user_email: formattedValues.userDetailsArray[0].user_email,
      role: formattedValues.userDetailsArray[0].role,
      userdetailsarray: formattedValues.userDetailsArray,
    });
    return api
      .post('ums', {
        TXN: {
          header: {
            transaction_type: 'MODULE_ADMIN',
            type: 'REMOVE_USER',
            uuid: getUUID(),
            customer: 'SANM',
            requestor: Cookie.get('email'),
            source: 'PORTAL',
            excel: 'NO',
          },
          details: userDetails,
        },
      })
      .then((response) => {
        const { data } = response;
        const TXNGET = data.TXN;
        if (TXNGET[0].return_msg === 'SUCCESS') {
          message.success('User deleted successfully');
        } else {
          message.error('Something went wrong. Please try again!');
        }
      });
  };
}

export function deleteRole(formattedValues) {
  return (_dispatch, _getState, { api }) => {
    let userDetails = [];
    userDetails.push({
      user_email: formattedValues.user_email,
      user_name: formattedValues.user_name,
      role: formattedValues.role,
      userdetailsarray: formattedValues.userDetailsArray,
    });
    return api
      .post('ums', {
        TXN: {
          header: {
            transaction_type: 'MODULE_ADMIN',
            type: 'DELETE_USER',
            uuid: getUUID(),
            customer: 'SANM',
            requestor: Cookie.get('email'),
            source: 'PORTAL',
            excel: 'NO',
          },
          details: userDetails,
        },
      })
      .then((response) => {
        const { data } = response;
        const TXNGET = data.TXN;
        if (TXNGET[0].return_msg === 'SUCCESS') {
          message.success('User role deleted successfully');
        } else if (TXNGET[0].return_code == '2') {
          message.error('The selected user role does not exists!');
        } else {
          message.error('Something went wrong. Please try again!');
        }
      });
  };
}

export function cpDetailsAdd(formattedValues) {
  return (dispatch) => {
    dispatch({ type: ADD_DEPOT_DETAILS });
    dispatch(update(formattedValues));
    return true;
  };
  function update(data) {
    return { type: ADD_DEPOT_DETAILS_SUCCESS, data };
  }
}

export function cpDetailsDelete(formattedValues) {
  return (dispatch) => {
    dispatch({ type: DELETE_DEPOT_DETAILS });
    dispatch(update(formattedValues));
    return true;
  };
  function update(data) {
    return { type: DELETE_DEPOT_DETAILS_SUCCESS, data };
  }
}
