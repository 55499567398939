import React from 'react';
import { Modal, Button } from 'antd';

const BroadcastMsg = (props) => {
  const handleOk = (_e) => props.handleVisiblity(false);
  const handleCancel = () => props.handleVisiblity(false);

  return (
    <div>
      <Modal
        title='Broadcast Message'
        open={props.visibility}
        onOk={handleOk}
        centered
        width={400}
        onCancel={handleCancel}
        className='broadcastMsg'
        footer={[
          <Button key='submit' type='primary' onClick={handleOk}>
            OK
          </Button>,
        ]}>
        <p>{props.broadcastMsg}</p>
      </Modal>
    </div>
  );
};

export default BroadcastMsg;
