import React from 'react';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setWalkthrough } from './redux/tour';

const TourActionButton = (props) => {
  const { label, screen, closeTour } = props;

  const defaultAccess = useSelector(
    ({ authorization: { initAuthorization } }) =>
      initAuthorization.defaultAccess
  );

  const dispatch = useDispatch();

  const handleWalkthrough = () => {
    dispatch(setWalkthrough(defaultAccess, screen, label));
    if (closeTour) closeTour();
  };

  return (
    <Button
      size='small'
      type='primary'
      className='skip-walkthrough'
      onClick={handleWalkthrough}>
      {label}
    </Button>
  );
};

export default TourActionButton;
