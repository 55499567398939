const SUPPORT_DOCUMENT = 'support-document/SUPPORT_DOCUMENT';
const SUPPORT_DOCUMENT_SUCCESS = 'support-document/SUPPORT_DOCUMENT_SUCCESS';
const SUPPORT_DOCUMENT_FAIL = 'support-document/SUPPORT_DOCUMENT_FAIL';

const initialState = {
  data: {
    systemRequirements: {},
    supportDocuments: [],
    supportDetails: [],
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SUPPORT_DOCUMENT:
      return { ...state, loading: true };
    case SUPPORT_DOCUMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case SUPPORT_DOCUMENT_FAIL:
      return { ...state, loading: false };
    default:
      return state;
  }
}

export function getSupportDocs() {
  return (dispatch, getState, { api, formatParams }) => {
    const authorized = Object.keys(
      getState().authorization.initAuthorization
    ).length;
    dispatch({ type: SUPPORT_DOCUMENT });
    if (authorized) {
      const { defaultAccess } = getState().authorization.initAuthorization;
      return api
        .post(
          defaultAccess.toLowerCase(),
          formatParams({ type: 'SUPPORT_DOCUMENTS' }, getState)
        )
        .then((response) => {
          dispatch({
            type: SUPPORT_DOCUMENT_SUCCESS,
            payload: response.data,
          });

          return response;
        });
    }
  };
}
