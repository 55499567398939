import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import store from '../../../../redux/store';
import service from '../../../../service/Service';
import {
  getLastRowIndex,
  getUUID,
  gaPageviewTimingTitle,
  getEpochTime,
} from '../../../../helpers/utils';
import selectColumn from '../../../common/ag-grid-select-column';
import Cookie from 'js-cookie';
import { message, Modal } from 'antd';
import {
  exportDTo,
  getMassDownloadGridParams,
} from '../../../../helpers/export';
import CptCustomRowCheckboxRenderer from '../../../common/CptCustomRowCheckboxRenderer';

export const CPT_FORM_OPEN_COLUMNS = 'cpt/cpt-form/CPT_FORM_OPEN_COLUMNS';
export const CPT_FORM_OPEN_COLUMNS_SUCCESS =
  'cpt/cpt-form/CPT_FORM_OPEN_COLUMNS_SUCCESS';
export const CPT_FORM_OPEN_COLUMNS_FAIL =
  'cpt/cpt-form/CPT_FORM_OPEN_COLUMNS_FAIL';

export const CPT_FORM_PREVIOUS_COLUMNS =
  'cpt/cpt-form/CPT_FORM_PREVIOUS_COLUMNS';
export const CPT_FORM_PREVIOUS_COLUMNS_SUCCESS =
  'cpt/cpt-form/CPT_FORM_PREVIOUS_COLUMNS_SUCCESS';
export const CPT_FORM_PREVIOUS_COLUMNS_FAIL =
  'cpt/cpt-form/CPT_FORM_PREVIOUS_COLUMNS_FAIL';

export const CPT_FORM_ALL_COLUMNS = 'cpt/cpt-form/CPT_FORM_ALL_COLUMNS';
export const CPT_FORM_ALL_COLUMNS_SUCCESS =
  'cpt/cpt-form/CPT_FORM_ALL_COLUMNS_SUCCESS';
export const CPT_FORM_ALL_COLUMNS_FAIL =
  'cpt/cpt-form/CPT_FORM_ALL_COLUMNS_FAIL';

export const CPT_FORM_OPEN_ROWS_INFO = 'cpt/cpt-form/CPT_FORM_OPEN_ROWS_INFO';
export const CPT_FORM_PREVIOUS_ROWS_INFO =
  'cpt/cpt-form/CPT_FORM_PREVIOUS_ROWS_INFO';
export const CPT_FORM_ALL_ROWS_INFO = 'cpt/cpt-form/CPT_FORM_ALL_ROWS_INFO';

export const GET_AUDIT_HISTORY_ROWS = 'cpt/cpt-form/GET_AUDIT_HISTORY_ROWS';
export const GET_AUDIT_HISTORY_ROWS_SUCCESS =
  'cpt/cpt-form/GET_AUDIT_HISTORY_ROWS_SUCCESS';
export const GET_AUDIT_HISTORY_ROWS_FAIL =
  'cpt/cpt-form/GET_AUDIT_HISTORY_ROWS_FAIL';

export const CPT_DATA_SUBMIT = 'cpt/cpt-form/CPT_DATA_SUBMIT';
export const CPT_DATA_SUBMIT_SUCCESS = 'cpt/cpt-form/CPT_DATA_SUBMIT_SUCCESS';
export const CPT_DATA_SUBMIT_FAIL = 'acp/candidate-item/CPT_DATA_SUBMIT_FAIL';

const initialState = {
  loadingOpen: false,
  loadingPrevious: false,
  loadingAll: false,
  auditHistoryLoading: false,
  rowsInfoOpen: {
    endRow: 0,
    lastRow: 0,
  },
  rowsInfoPrevious: {
    endRow: 0,
    lastRow: 0,
  },
  rowsInfoAll: {
    endRow: 0,
    lastRow: 0,
  },
  cptFormOpenColumns: {
    rowModelType: null,
  },
  cptFormPreviousColumns: {
    rowModelType: null,
  },
  cptFormAllColumns: {
    rowModelType: null,
  },
  auditHistoryRows: [],
  submittingItem: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case CPT_FORM_OPEN_COLUMNS:
      return { ...state, loadingOpen: true };
    case CPT_FORM_PREVIOUS_COLUMNS:
      return { ...state, loadingPrevious: true };
    case CPT_FORM_ALL_COLUMNS:
      return { ...state, loadingAll: true };

    case CPT_FORM_OPEN_COLUMNS_SUCCESS:
      return {
        ...state,
        loadingOpen: false,
        loadingPrevious: false,
        loadingAll: false,
        loadedOpen: true,
        cptFormOpenColumns: action.payload,
        errorOpen: null,
      };
    case CPT_FORM_PREVIOUS_COLUMNS_SUCCESS:
      return {
        ...state,
        loadingOpen: false,
        loadingPrevious: false,
        loadingAll: false,
        loadedPrevious: true,
        cptFormPreviousColumns: action.payload,
        errorPrevious: null,
      };
    case CPT_FORM_ALL_COLUMNS_SUCCESS:
      return {
        ...state,
        loadingOpen: false,
        loadingPrevious: false,
        loadingAll: false,
        loadedAll: true,
        cptFormAllColumns: action.payload,
        errorAll: null,
      };

    case CPT_FORM_OPEN_COLUMNS_FAIL:
      return { ...state, loadingOpen: false, errorOpen: action.payload };
    case CPT_FORM_PREVIOUS_COLUMNS_FAIL:
      return {
        ...state,
        loadingPrevious: false,
        errorPrevious: action.payload,
      };
    case CPT_FORM_ALL_COLUMNS_FAIL:
      return { ...state, loadingAll: false, errorAll: action.payload };

    case CPT_FORM_OPEN_ROWS_INFO:
      return { ...state, rowsInfoOpen: action.payload, loadingOpen: false };
    case CPT_FORM_PREVIOUS_ROWS_INFO:
      return {
        ...state,
        rowsInfoPrevious: action.payload,
        loadingPrevious: false,
      };
    case CPT_FORM_ALL_ROWS_INFO:
      return { ...state, rowsInfoAll: action.payload, loadingAll: false };

    case GET_AUDIT_HISTORY_ROWS:
      return { ...state, auditHistoryLoading: true };
    case GET_AUDIT_HISTORY_ROWS_SUCCESS:
      return {
        ...state,
        auditHistoryLoading: false,
        auditHistoryLoaded: true,
        auditHistoryRows: action.payload,
      };

    case CPT_DATA_SUBMIT:
      return { ...state, submittingItem: true };
    case CPT_DATA_SUBMIT_SUCCESS:
      return { ...state, submittingItem: false, submittedItem: true };
    case CPT_DATA_SUBMIT_FAIL:
      return { ...state, submittingItem: false };
    default:
      return state;
  }
}

function getCptOpenColumns() {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: CPT_FORM_OPEN_COLUMNS });
    const currentTime = getEpochTime();
    return api
      .post(
        'cpt',
        formatParams(
          {
            type: 'AG_GRID_COLUMNS',
            view: 'CPT_FORM_OPEN',
            details: {
              email: Cookie.get('email'),
            },
          },
          getState
        )
      )
      .then((response) => {
        gaPageviewTimingTitle(`/CPT/getCptOpenColumns`, currentTime);
        const { data } = response;
        if (data && data.statusCode === '200') {
          if (Cookie.get('cpt_userrole') != 'admin') {
            let selectColumnModify = {
              ...selectColumn,
              cellRenderer: (props) => (
                <CptCustomRowCheckboxRenderer {...props} view={'open'} />
              ),
            };
            data.result.columnDefs.unshift(selectColumnModify);
          }

          dispatch({
            type: CPT_FORM_OPEN_COLUMNS_SUCCESS,
            payload: data.result,
          });
        } else {
          dispatch({
            type: CPT_FORM_OPEN_COLUMNS_FAIL,
            payload: 'Error in loading Open View!',
          });
        }
      });
  };
}

function getCptOpenRows() {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      params.api.showLoadingOverlay();
      const currentTime = getEpochTime();
      return api
        .post(
          'cpt',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: 'CPT_FORM_OPEN',
              gridParams: { ...params.request },
              details: { email: Cookie.get('email') },
            },
            store.getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(`/CPT/getCptOpenRows`, currentTime);
          const { data } = response;
          if (data && data.statusCode === '200') {
            let rowData = data.result.rowData.map((row) => {
              return {
                ...row,
                isEditing: false,
                errors: {},
                hasError: false,
              };
            });
            params.success({
              rowData: rowData,
              rowCount: getLastRowIndex(params.request, rowData),
            });
            const endRow =
              params.request.endRow > data.result.lastRow
                ? data.result.lastRow
                : params.request.endRow;
            store.dispatch({
              type: CPT_FORM_OPEN_ROWS_INFO,
              payload: { lastRow: data.result.lastRow, endRow },
            });
            // Hide overlay loading on success
            params.api.hideOverlay();
            const rowsPerRequest = process.env.REACT_APP_AG_GRID_ROWS_LIMIT;
            if (!rowData.length && params.request.startRow < rowsPerRequest) {
              params.api.showNoRowsOverlay();
            }
          } else {
            params.fail();
          }
        })
        .catch(() => {
          params.fail();
        });
    },
  };
}

function getCptPreviousColumns() {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: CPT_FORM_PREVIOUS_COLUMNS });
    const currentTime = getEpochTime();
    return api
      .post(
        'cpt',
        formatParams(
          {
            type: 'AG_GRID_COLUMNS',
            view: 'CPT_FORM_PREVIOUS',
            details: {
              email: Cookie.get('email'),
            },
          },
          getState
        )
      )
      .then((response) => {
        gaPageviewTimingTitle(`/CPT/getCptPreviousColumns`, currentTime);
        const { data } = response;
        if (data && data.statusCode === '200') {
          if (Cookie.get('cpt_userrole') != 'admin') {
            let selectColumnModify = {
              ...selectColumn,
              cellRenderer: (props) => (
                <CptCustomRowCheckboxRenderer {...props} view={'previous'} />
              ),
            };
            data.result.columnDefs.unshift(selectColumnModify);
          }
          dispatch({
            type: CPT_FORM_PREVIOUS_COLUMNS_SUCCESS,
            payload: data.result,
          });
        } else {
          dispatch({
            type: CPT_FORM_PREVIOUS_COLUMNS_FAIL,
            payload: 'Error in loading All View!',
          });
        }
      });
  };
}

function getCptPreviousRows() {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      params.api.showLoadingOverlay();
      const currentTime = getEpochTime();
      return api
        .post(
          'cpt',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: 'CPT_FORM_PREVIOUS',
              gridParams: { ...params.request },
              details: { email: Cookie.get('email') },
            },
            store.getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(`/CPT/getCptPreviousRows`, currentTime);
          const { data } = response;
          if (data && data.statusCode === '200') {
            let rowData = data.result.rowData.map((r) => {
              return {
                ...r,
                isEditing: false,
                errors: {},
                hasError: false,
              };
            });
            params.success({
              rowData,
              rowCount: getLastRowIndex(params.request, rowData),
            });
            const endRow =
              params.request.endRow > data.result.lastRow
                ? data.result.lastRow
                : params.request.endRow;
            store.dispatch({
              type: CPT_FORM_PREVIOUS_ROWS_INFO,
              payload: { lastRow: data.result.lastRow, endRow },
            });
            // Hide overlay loading on success
            params.api.hideOverlay();
            const rowsPerRequest = process.env.REACT_APP_AG_GRID_ROWS_LIMIT;
            if (!rowData.length && params.request.startRow < rowsPerRequest) {
              params.api.showNoRowsOverlay();
            }
          } else {
            params.fail();
          }
        })
        .catch(() => {
          params.fail();
        });
    },
  };
}

function getCptAllColumns() {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: CPT_FORM_ALL_COLUMNS });
    const currentTime = getEpochTime();
    return api
      .post(
        'cpt',
        formatParams(
          {
            type: 'AG_GRID_COLUMNS',
            view: 'CPT_FORM_ALL',
            details: {
              email: Cookie.get('email'),
            },
          },
          getState
        )
      )
      .then((response) => {
        gaPageviewTimingTitle(`/CPT/getCptAllColumns`, currentTime);
        const { data } = response;
        if (data && data.statusCode === '200') {
          if (Cookie.get('cpt_userrole') != 'admin') {
            let selectColumnModify = {
              ...selectColumn,
              cellRenderer: (props) => (
                <CptCustomRowCheckboxRenderer {...props} view={'all'} />
              ),
            };
            data.result.columnDefs.unshift(selectColumnModify);
          }
          dispatch({
            type: CPT_FORM_ALL_COLUMNS_SUCCESS,
            payload: data.result,
          });
        } else {
          dispatch({
            type: CPT_FORM_ALL_COLUMNS_FAIL,
            payload: 'Error in loading All View!',
          });
        }
      });
  };
}

function getCptAllRows() {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      params.api.showLoadingOverlay();
      const currentTime = getEpochTime();
      return api
        .post(
          'cpt',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: 'CPT_FORM_ALL',
              gridParams: { ...params.request },
              details: { email: Cookie.get('email') },
            },
            store.getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(`/CPT/getCptAllRows`, currentTime);
          const { data } = response;
          if (data && data.statusCode === '200') {
            let rowData = data.result.rowData.map((rw) => {
              return {
                ...rw,
                isEditing: false,
                errors: {},
                hasError: false,
              };
            });
            params.success({
              rowData,
              rowCount: getLastRowIndex(params.request, rowData),
            });
            const endRow =
              params.request.endRow > data.result.lastRow
                ? data.result.lastRow
                : params.request.endRow;
            store.dispatch({
              type: CPT_FORM_ALL_ROWS_INFO,
              payload: { lastRow: data.result.lastRow, endRow },
            });
            // Hide overlay loading on success
            params.api.hideOverlay();
            const rowsPerRequest = process.env.REACT_APP_AG_GRID_ROWS_LIMIT;
            if (!rowData.length && params.request.startRow < rowsPerRequest) {
              params.api.showNoRowsOverlay();
            }
          } else {
            params.fail();
          }
        })
        .catch(() => {
          params.fail();
        });
    },
  };
}

function getAuditHistory(arg1, arg2, arg3, arg4) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: GET_AUDIT_HISTORY_ROWS });
    const currentTime = getEpochTime();
    return api
      .post(
        'cpt',
        formatParams(
          {
            type: 'GET_AUDIT_HISTORY',
            details: {
              email: Cookie.get('email'),
              po_number: arg1,
              po_line_no: arg2,
              customer_plant: arg3,
              supplier_code: arg4,
            },
          },
          getState
        )
      )
      .then((response) => {
        gaPageviewTimingTitle(`/CPT/getAuditHistory`, currentTime);
        const { data } = response;
        if (data) {
          dispatch({
            type: GET_AUDIT_HISTORY_ROWS_SUCCESS,
            payload: data,
          });
        } else {
          dispatch({
            type: GET_AUDIT_HISTORY_ROWS_FAIL,
            payload: 'Error in loading Open View!',
          });
        }
        return response;
      });
  };
}

function confirmCptData(updatedData, gridApi, nodes) {
  return (dispatch, _getState, { api }) => {
    dispatch({ type: CPT_DATA_SUBMIT });
    const currentTime = getEpochTime();
    return api
      .post('cptsubmitaction  ', {
        TXN: {
          header: {
            transaction_type: 'PO_COMMIT',
            status_type: 'SUBMIT',
            uuid: getUUID(),
            customer: 'SANM',
            requestor: Cookie.get('email'),
            source: 'PORTAL',
            excel: 'NO',
          },
          details: updatedData,
        },
      })
      .then((response) => {
        gaPageviewTimingTitle(`/CPT/submitConfirmCptData`, currentTime);
        const { data } = response;
        if (data) {
          const { TXN } = data;
          if (TXN) {
            const overallStatus = TXN[0].overall_txn_status;
            if (overallStatus === 'SUCCESS') {
              message.success('Data updated successfully!');
            } else if (overallStatus === 'FAILURE') {
              const failureResponse = TXN[0].FAILURE;
              Modal.error({
                title: 'Failed transactions!',
                icon: <ExclamationCircleOutlined />,
                content: (
                  <div
                    style={{
                      maxHeight: 200,
                      minHeight: 100,
                      overflowY: 'auto',
                    }}>
                    <div style={{ borderBottom: '1px solid #CCC', padding: 5 }}>
                      Status
                    </div>
                    {failureResponse.map((item) => (
                      <div
                        key={item.status}
                        style={{
                          borderBottom: '1px solid #CCC',
                          padding: 5,
                          fontSize: 12,
                        }}>
                        {item.status}
                      </div>
                    ))}
                  </div>
                ),
              });
            } else {
              message.error('Something went wrong. Please try again!');
            }
            //Refresh grid
            nodes.map((node) => {
              node.setSelected(false);
              node.updateData({
                ...node.data,
                isEditing: false,
                errors: {},
                hasError: false,
              });
            });
            gridApi.refreshServerSide({ purge: true });
            gridApi.refreshCells({ force: true });
          } else {
            message.error('Something went wrong. Please try again!');
          }
        } else {
          message.error('Something went wrong. Please try again!');
        }
        return response;
      });
  };
}

export function getMassDownloadReport({
  gridApi,
  exportType,
  totalRows,
  cb,
  columnApi,
  fileName,
  view,
}) {
  return (_dispatch, getState, { api, formatParams }) => {
    if (totalRows >= process.env.REACT_APP_MASS_DOWNLOAD_ENABLE_LIMIT) {
      return api
        .post(
          'cpt',
          formatParams(
            {
              type: 'MASS_DOWNLOAD',
              view: view,
              exportType,
              totalRows,
              email: Cookie.get('email'),
              gridParams: {
                ...getMassDownloadGridParams(gridApi, columnApi),
              },
            },
            getState
          )
        )
        .then((response) => {
          cb(true);
          return response;
        })
        .catch((error) => {
          cb(false);
          return error;
        });
    } else {
      const currentTime = getEpochTime();
      return api
        .post(
          'cpt',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: view,
              gridParams: {
                ...getMassDownloadGridParams(gridApi, columnApi),
                startRow: 0,
                endRow: totalRows,
              },
              details: { email: Cookie.get('email') },
            },
            store.getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(
            `/CPT/get${view}MassDownloadReport`,
            currentTime
          );
          const { data } = response;
          if (data) {
            const { result } = data;
            if (Object.keys(result).length) {
              exportDTo({
                columnApi,
                fileName: fileName,
                data: result.rowData,
                exportType,
              }) && cb(true);
            }
          }

          return response;
        });
    }
  };
}

export {
  getCptOpenColumns,
  getCptOpenRows,
  getCptAllColumns,
  getCptAllRows,
  getCptPreviousColumns,
  getCptPreviousRows,
  getAuditHistory,
  confirmCptData,
};
