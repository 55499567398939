const GET_THEME_INIIT = 'app/theme/GET_THEME_INIIT';
const GET_THEME_SUCCESS = 'app/theme/GET_THEME_SUCCESS';
const GET_THEME_ERROR = 'app/theme/GET_THEME_ERROR';

const initialState = {
  themeLoading: false,
  error: '',
  config: {
    token: {
      colorPrimary: '#EF373E',
      colorPrimaryActive: '#fff2f0',
      colorTextSecondary: 'rgba(0, 0, 0, 0.45)',
      colorText: '#465660',
      fontFamily: '"Avenir", sans-serif',
      colorBorder: '#d9d9d9',
      colorTextDisabled: 'rgba(0, 0, 0, 0.25)',
      fontSize: 14,
      colorError: '#f5222d',
      colorWarning: '#faad14',
      colorSuccess: '#52c41a',
      colorLink: '#1890ff',
    },
    components: {
      Layout: {
        colorBgHeader: 'rgba(77, 82, 90, 0.91)',
        colorBgBody: '#f0f2f5',
        colorBgContainer: '#FFFFFF',
        colorFooterText: '#465660',
      },
      Modal: {
        titleLineHeight: 2,
        titleFontSize: 16,
      },
      Card: {
        headerBg: '#EF373E',
        colorTextHeading: '#FFFFFF',
      },
      Avatar: {
        borderRadius: 2,
      },
    },
  },
};

export default function theme(state = initialState, action) {
  switch (action.type) {
    case GET_THEME_INIIT:
      return { ...state, themeLoading: true };
    case GET_THEME_SUCCESS:
      return { ...state, config: action.payload, themeLoading: false };
    case GET_THEME_ERROR:
      return {
        ...state,
        themeLoading: false,
        error:
          'Error in loading theme. Please reload the app again to apply theme!',
      };
    default:
      return state;
  }
}

const setTheme = (theme) => {
  return (dispatch) => {
    dispatch({ type: GET_THEME_SUCCESS, payload: theme });
  };
};

export { setTheme };
