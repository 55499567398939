import CustomDetailRowCheckboxRenderer from './CustomDetailRowCheckboxRenderer';

export default {
  width: 44,
  filter: false,
  sortable: false,
  headerName: '',
  field: 'sub-check',
  suppressMenu: true,
  suppressSizeToFit: true,
  suppressMovable: true,
  suppressNavigable: true,
  suppressColumnsToolPanel: true,
  suppressFiltersToolPanel: true,
  editable: false,
  suppressCellFlash: true,
  resizable: false,
  colId: 'sub-check',
  cellRenderer: CustomDetailRowCheckboxRenderer,
  lockPosition: true,
};
