import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Input, Button, message, Popconfirm, QRCode } from 'antd';
import {
  CheckCircleOutlined,
  LockOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';
import {
  saveMfaPreference,
  generateSMSOtp,
  generateTOTP,
  updateMfaPreference,
} from './redux/modules/mfa';
import { useShallowEqualSelector } from '../../helpers/utils';

const MfaSettings = () => {
  const [mfaVerificationCode, setVerificationCode] = useState(0);
  const [phone, setMobileNum] = useState(0);
  const {
    mfaUpdateMsg,
    mfaUpdateError,
    enableMfaSmsOtp,
    enableMfaTotp,
    enableMfaMobile,
    secretCode,
    userDetails,
    mfaPref,
    preferenceSelected,
  } = useShallowEqualSelector(({ profile: { mfa, user } }) => ({
    mfaUpdateMsg: mfa.mfaUpdateMsg,
    mfaUpdateError: mfa.error,
    enableMfaSmsOtp: mfa.enableMfaSmsOtp,
    enableMfaTotp: mfa.enableMfaTotp,
    enableMfaMobile: mfa.enableMfaMobile,
    secretCode: mfa.secretCode,
    loading: mfa.loading,
    loaded: mfa.loaded,
    userDetails: user.userDetails,
    mfaPref: mfa.mfaPreference,
    preferenceSelected: mfa.preferenceSelected,
  }));

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleMfaChange = (type) => {
    const preference = getPreference();
    if (type !== preference) {
      dispatch({
        type: 'profile/mfa/MFA_PREFERENCE_SELECTED',
        payload: type,
      });
      if (type == 'NO_MFA') {
        dispatch(saveMfaPreference(preference, 'NO_MFA'));
      } else if (type == 'SMS_MFA') {
        dispatch({ type: 'profile/mfa/MFA_ENABLE_MOBILE' });
      } else if (type == 'SOFTWARE_TOKEN_MFA') {
        dispatch(generateTOTP());
      }
    } else {
      message.error('Please select different option to proceed!');
    }
  };

  const getPreference = () => {
    return mfaPref || userDetails.mfa_preference;
  };

  const handleSubmit = () => {
    form.validateFields().then(() => {
      if (enableMfaMobile && !enableMfaSmsOtp && !enableMfaTotp) {
        if (phone) {
          dispatch(generateSMSOtp(phone));
        } else if (userDetails.user_mobile_number) {
          dispatch(saveMfaPreference(getPreference(), 'SMS_MFA'));
        } else {
          message.error('Please update mobile number to proceed!');
        }
      } else {
        if (mfaVerificationCode) {
          dispatch(
            updateMfaPreference(preferenceSelected, mfaVerificationCode)
          );
        } else {
          message.error('Preference already updated!');
        }
      }
    });
  };

  const resetMfaSettings = () => {
    setVerificationCode(0);
    setMobileNum(0);
    dispatch({ type: 'profile/mfa/MFA_RESET_SUCCESS' });
  };

  const setMobile = (e) => {
    e.preventDefault();
    setMobileNum(e.target.value);
  };

  const getQRCode = (secrtCode) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
        <QRCode
          type='svg'
          size={150}
          errorLevel='L'
          style={{ flex: 'auto' }}
          value={`otpauth://totp/Sanmina?secret=${secrtCode}&issuer=Amazon`}
        />
      </div>
    );
  };

  if (userDetails.email && userDetails.email.indexOf('sanmina') !== -1) {
    return null;
  }

  return (
    <div className='mfa-settings-btn' style={{ marginTop: 10 }}>
      {mfaUpdateMsg && <p>{mfaUpdateMsg}</p>}
      {preferenceSelected == 'SOFTWARE_TOKEN_MFA' && secretCode
        ? getQRCode(secretCode)
        : null}
      {mfaUpdateError && <p>{mfaUpdateError}</p>}
      <Popconfirm
        title='Are you sure switch NO_MFA option?'
        onConfirm={() => handleMfaChange('NO_MFA')}
        okText='Ok'
        cancelText='No'>
        <Button
          type={getPreference() == 'NO_MFA' && 'primary'}
          icon={getPreference() == 'NO_MFA' && <CheckCircleOutlined />}>
          NO MFA {preferenceSelected == 'NO_MFA' ? <CaretDownOutlined /> : null}
        </Button>
      </Popconfirm>
      <Button
        type={getPreference() == 'SMS_MFA' && 'primary'}
        icon={getPreference() == 'SMS_MFA' && <CheckCircleOutlined />}
        onClick={() => handleMfaChange('SMS_MFA')}>
        SMS {preferenceSelected == 'SMS_MFA' ? <CaretDownOutlined /> : null}
      </Button>
      <Popconfirm
        title='Are you sure switch TOTP option?'
        onConfirm={() => handleMfaChange('SOFTWARE_TOKEN_MFA')}
        okText='Ok'
        cancelText='No'>
        <Button
          type={getPreference() == 'SOFTWARE_TOKEN_MFA' && 'primary'}
          icon={
            getPreference() == 'SOFTWARE_TOKEN_MFA' && <CheckCircleOutlined />
          }>
          TOTP{' '}
          {preferenceSelected == 'SOFTWARE_TOKEN_MFA' ? (
            <CaretDownOutlined />
          ) : null}
        </Button>
      </Popconfirm>
      <Form
        form={form}
        name='mfaSettings'
        onFinish={handleSubmit}
        className='mfa-settings-form'
        style={{ marginTop: 10 }}
        initialValues={{
          phone: userDetails.user_mobile_number,
        }}>
        {(() => {
          let mfaPreferenceElem = [];
          if (
            (enableMfaMobile || getPreference() == 'SMS_MFA') &&
            !enableMfaTotp
          ) {
            mfaPreferenceElem.push(
              <>
                <Form.Item
                  name='phone'
                  rules={[
                    {
                      required: true,
                      message: `Please input your mobile number`,
                    },
                  ]}>
                  <Input
                    prefix={<LockOutlined />}
                    placeholder={`Please enter your mobile number`}
                    onChange={setMobile}
                  />
                </Form.Item>
                {!enableMfaTotp && !enableMfaSmsOtp && (
                  <>
                    <Popconfirm
                      title='Are you sure switch SMS option?'
                      onConfirm={handleSubmit}
                      okText='Ok'
                      cancelText='No'>
                      <Button type='primary' htmlType='submit'>
                        Save
                      </Button>
                    </Popconfirm>
                    <Button onClick={resetMfaSettings}>Cancel</Button>
                  </>
                )}
              </>
            );
          }

          if (enableMfaTotp || enableMfaSmsOtp) {
            mfaPreferenceElem.push(
              <>
                <Form.Item
                  name='mfaVerificationCode'
                  rules={[
                    {
                      required: true,
                      message: `Please input your verification code`,
                    },
                  ]}>
                  <Input
                    prefix={<LockOutlined />}
                    placeholder={`Please enter your verification code`}
                    onBlur={(e) => setVerificationCode(e.target.value)}
                  />
                </Form.Item>
                <Button type='primary' htmlType='submit'>
                  Save
                </Button>
                <Button onClick={resetMfaSettings}>Cancel</Button>
              </>
            );
          }

          return mfaPreferenceElem;
        })()}
      </Form>
    </div>
  );
};

export default MfaSettings;
