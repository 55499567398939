import { combineReducers } from 'redux';
import agilityAdmin from '../agility-admin/redux/agility-redux-ducks';
import supplier from '../supplier/redux/supplier-redux-ducks';
import rsmPlant from '../rsm-plant/redux/rsm-redux-ducks';

const candidateReducer = combineReducers({
  agilityAdmin,
  supplier,
  rsmPlant,
});

export default candidateReducer;
