import React from 'react';
import { ConfigProvider } from 'antd';
import { useSelector } from 'react-redux';
import Routes from '../routes/Routes';

const ThemeProvider = () => {
  // Set default theme initially
  const theme = useSelector(({ theme }) => theme.config);

  return (
    <ConfigProvider theme={theme}>
      <Routes />
    </ConfigProvider>
  );
};

export default ThemeProvider;
