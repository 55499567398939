import service from '../../../../service/Service';
import store from '../../../../redux/store';
import {
  exportDTo,
  getMassDownloadGridParams,
} from '../../../../helpers/export';
import {
  getLastRowIndex,
  gaPageviewTimingTitle,
  getEpochTime,
} from '../../../../helpers/utils';
import Cookies from 'js-cookie';

export const IMPLEMENTATION_STATUS_COLUMNS =
  'acp/IMPLEMENTATION_STATUS_COLUMNS';
export const IMPLEMENTATION_STATUS_COLUMNS_SUCCESS =
  'acp/IMPLEMENTATION_STATUS_COLUMNS_SUCCESS';
export const IMPLEMENTATION_STATUS_COLUMNS_FAIL =
  'acp/IMPLEMENTATION_STATUS_COLUMNS_FAIL';
export const IMPLEMENTATION_STATUS_ROWS_INFO_SUCCESS =
  'acp/IMPLEMENTATION_STATUS_ROWS_INFO_SUCCESS';

const initialState = {
  loaded: false,
  implementationStatusColumns: {
    rowModelType: null,
  },
  rowsInfo: {
    endRow: 0,
    lastRow: 0,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case IMPLEMENTATION_STATUS_COLUMNS:
      return { ...state, loading: true };
    case IMPLEMENTATION_STATUS_COLUMNS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        implementationStatusColumns: action.payload,
        error: null,
      };
    case IMPLEMENTATION_STATUS_COLUMNS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case IMPLEMENTATION_STATUS_ROWS_INFO_SUCCESS:
      return { ...state, rowsInfo: action.payload };
    default:
      return state;
  }
}

export function getColumns() {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: IMPLEMENTATION_STATUS_COLUMNS });
    const currentTime = getEpochTime();
    return api
      .post(
        'acp',
        formatParams(
          {
            type: 'AG_GRID_COLUMNS',
            view: 'IMPLEMENTATION_STATUS',
            details: {
              email: Cookies.get('email'),
            },
          },
          getState
        )
      )
      .then((response) => {
        gaPageviewTimingTitle(
          `/ACP/getImplementationStatusColumns`,
          currentTime
        );
        const { data } = response;
        if (data && data.statusCode === '200') {
          dispatch({
            type: IMPLEMENTATION_STATUS_COLUMNS_SUCCESS,
            payload: data.result,
          });
        } else {
          dispatch({
            type: IMPLEMENTATION_STATUS_COLUMNS_FAIL,
            payload: 'Error in loading Open View!',
          });
        }
      });
  };
}

export function getRows() {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      params.api.showLoadingOverlay();
      const currentTime = getEpochTime();
      return api
        .post(
          'acp',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: 'IMPLEMENTATION_STATUS',
              gridParams: { ...params.request },
              details: { email: Cookies.get('email') },
            },
            store.getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(
            `/ACP/getImplementationStatusRows`,
            currentTime
          );
          const { data } = response;
          if (Object.keys(data).length) {
            params.success({
              rowData: data.result.rowData,
              rowCount: getLastRowIndex(params.request, data.result.rowData),
            });
            const endRow =
              params.request.endRow > data.result.lastRow
                ? data.result.lastRow
                : params.request.endRow;
            store.dispatch({
              type: IMPLEMENTATION_STATUS_ROWS_INFO_SUCCESS,
              payload: { lastRow: data.result.lastRow, endRow },
            });
            // Hide overlay loading on success
            params.api.hideOverlay();
            const rowsPerRequest = process.env.REACT_APP_AG_GRID_ROWS_LIMIT;
            if (
              !data.result.rowData.length &&
              params.request.startRow < rowsPerRequest
            ) {
              params.api.showNoRowsOverlay();
            }
          } else {
            params.fail();
          }
        })
        .catch(() => {
          params.fail();
        });
    },
  };
}

export function getMassDownloadReport(
  gridApi,
  exportType,
  totalRows,
  cb,
  columnApi
) {
  return (_dispatch, getState, { api, formatParams }) => {
    if (totalRows >= process.env.REACT_APP_MASS_DOWNLOAD_ENABLE_LIMIT) {
      return api
        .post(
          'acp',
          formatParams(
            {
              type: 'MASS_DOWNLOAD',
              view: 'IMPLEMENTATION_STATUS',
              exportType,
              totalRows,
              email: Cookies.get('email'),
              gridParams: {
                ...getMassDownloadGridParams(gridApi, columnApi),
              },
            },
            getState
          )
        )
        .then((response) => {
          cb(true);
          return response;
        })
        .catch((error) => {
          cb(false);
          return error;
        });
    } else {
      const currentTime = getEpochTime();
      return api
        .post(
          'acp',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: 'IMPLEMENTATION_STATUS',
              gridParams: {
                ...getMassDownloadGridParams(gridApi, columnApi),
                startRow: 0,
                endRow: totalRows,
              },
              details: { email: Cookies.get('email') },
            },
            store.getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(
            `/ACP/getImplementationStatusMassDownloadReport`,
            currentTime
          );
          const { data } = response;
          if (Object.keys(data).length) {
            exportDTo({
              columnApi,
              fileName: 'ACP_IMPLEMENTATION_STATUS_REPORT',
              data: data.result.rowData,
              exportType,
            }) && cb(true);
          }

          return response;
        });
    }
  };
}
