import service from 'service/Service';
import store from 'redux/store';
import { exportDTo, getMassDownloadGridParams } from 'helpers/export';
import {
  getLastRowIndex,
  gaPageviewTimingTitle,
  getEpochTime,
  isObject,
} from 'helpers/utils';
import Cookies from 'js-cookie';

const REPORT_HIST_LIST = 'cds/REPORT_HIST_LIST';
const REPORT_HIST_LIST_SUCCESS = 'cds/REPORT_HIST_LIST_SUCCESS';
const REPORT_HIST_LIST_FAIL = 'cds/REPORT_HIST_LIST_FAIL';

const CDS_REPORT_HISTORY_COLUMNS = 'cds/CDS_REPORT_HISTORY_COLUMNS';
const CDS_REPORT_HISTORY_COLUMNS_SUCCESS =
  'cds/CDS_REPORT_HISTORY_COLUMNS_SUCCESS';
const CDS_REPORT_HISTORY_COLUMNS_FAIL = 'cds/CDS_REPORT_HISTORY_COLUMNS_FAIL';
const CDS_REPORT_HISTORY_ROWS_INFO_SUCCESS =
  'cds/CDS_REPORT_HISTORY_ROWS_INFO_SUCCESS';

const initialState = {
  loaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case REPORT_HIST_LIST:
      return { ...state, loading: true };
    case REPORT_HIST_LIST_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        reportHistoryList: action.payload,
        error: null,
      };
    case REPORT_HIST_LIST_FAIL:
      return { ...state, loading: false, error: action.payload };
    case CDS_REPORT_HISTORY_COLUMNS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: true,
        },
      };
    case CDS_REPORT_HISTORY_COLUMNS_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          loaded: true,
          columns: action.payload,
          error: null,
        },
      };
    case CDS_REPORT_HISTORY_COLUMNS_FAIL:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          error: action.payload,
        },
      };
    case CDS_REPORT_HISTORY_ROWS_INFO_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          rowsInfo: action.payload,
        },
      };
    default:
      return state;
  }
}

export function getReportHistList(customerDetails = undefined) {
  let customerCode = '',
    customerName = '';
  if (customerDetails) {
    let customerDetailsSplit = customerDetails.split('$$');
    customerCode = customerDetailsSplit[0];
    customerName = customerDetailsSplit[1];
  }
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: REPORT_HIST_LIST });
    return api
      .post(
        'cds',
        formatParams(
          {
            type: 'REPORT_HISTORY_LIST',
            details: {
              email: Cookies.get('email'),
              ...(customerDetails
                ? { customerCode: customerCode, customerName: customerName }
                : {}),
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data.statusCode == '200' && data.result.length) {
          dispatch({
            type: REPORT_HIST_LIST_SUCCESS,
            payload: data.result,
          });
        } else {
          dispatch({
            type: REPORT_HIST_LIST_FAIL,
            error: 'Something went wrong! Please try again.',
          });
        }
      });
  };
}

export function getColumns(type, tabKey) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: CDS_REPORT_HISTORY_COLUMNS, tabKey });
    const currentTime = getEpochTime();
    return api
      .post(
        'cds',
        formatParams({ type: 'AG_GRID_COLUMNS', view: type }, getState)
      )
      .then((response) => {
        gaPageviewTimingTitle(`/CDS/GET_${type}_COLUMNS`, currentTime);
        const { data } = response;
        if (data && isObject(data.result)) {
          dispatch({
            type: CDS_REPORT_HISTORY_COLUMNS_SUCCESS,
            payload: data.result,
            tabKey,
          });
        } else {
          dispatch({
            type: CDS_REPORT_HISTORY_COLUMNS_FAIL,
            payload: 'Something went wrong! Please try again.',
            tabKey,
          });
        }
      });
  };
}

export function getRows(type, tabKey) {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      params.api.showLoadingOverlay();
      const currentTime = getEpochTime();
      return api
        .post(
          'cds',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: type,
              gridParams: { ...params.request },
              details: {
                email: Cookies.get('email'),
                screen: 'reports',
              },
            },
            store.getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(`/CDS/GET_${type}_ROWS`, currentTime);
          const { data } = response;
          if (Object.keys(data).length) {
            params.success({
              rowData: data.result.rowData,
              rowCount: getLastRowIndex(params.request, data.result.rowData),
            });
            const endRow =
              params.request.endRow > data.result.lastRow
                ? data.result.lastRow
                : params.request.endRow;
            store.dispatch({
              type: CDS_REPORT_HISTORY_ROWS_INFO_SUCCESS,
              payload: { lastRow: data.result.lastRow, endRow },
              tabKey,
            });
            // Hide overlay loading on success
            params.api.hideOverlay();
            const rowsPerRequest = process.env.REACT_APP_AG_GRID_ROWS_LIMIT;
            if (
              !data.result.rowData.length &&
              params.request.startRow < rowsPerRequest
            ) {
              params.api.showNoRowsOverlay();
            }
          } else {
            params.fail();
          }
        })
        .catch(() => {
          params.fail();
        });
    },
  };
}

export function getMassDownloadReport(
  gridApi,
  exportType,
  totalRows,
  cb,
  columnApi,
  type
) {
  return (_dispatch, getState, { api, formatParams }) => {
    if (totalRows >= process.env.REACT_APP_MASS_DOWNLOAD_ENABLE_LIMIT) {
      return api
        .post(
          'cds',
          formatParams(
            {
              type: 'MASS_DOWNLOAD',
              view: type,
              exportType,
              totalRows,
              email: Cookies.get('email'),
              gridParams: {
                ...getMassDownloadGridParams(gridApi, columnApi),
              },
              details: {
                email: Cookies.get('email'),
                screen: 'reports',
              },
            },
            getState
          )
        )
        .then((response) => {
          cb(true);
          return response;
        })
        .catch((error) => {
          cb(false);
          return error;
        });
    } else {
      const currentTime = getEpochTime();
      return api
        .post(
          'cds',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: type,
              gridParams: {
                ...getMassDownloadGridParams(gridApi, columnApi),
                startRow: 0,
                endRow: totalRows,
              },
              details: {
                email: Cookies.get('email'),
                screen: 'reports',
              },
            },
            store.getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(
            `/CDS/GET_${type}_MASS_DOWNLOAD_REPORT`,
            currentTime
          );
          const { data } = response;
          if (Object.keys(data).length) {
            exportDTo({
              columnApi,
              fileName: `CDS_${type}`,
              data: data.result.rowData,
              exportType,
            }) && cb(true);
          }

          return response;
        });
    }
  };
}
