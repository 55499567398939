import { combineReducers } from 'redux';
import dashboard from '../ssp-dashboard/redux/ssp-dashboard';
import requestForm from '../request-form/redux/request-form';
import questionnaire from '../questionnaire/redux/questionnaire';
import testingForm from '../testing-form/redux/testing-form';
import testLogs from '../test-logs/redux/test-logs';
import ediSelection from '../edi-selection-form/redux/edi-selection';
import guidelines from '../guidelines/redux/guidelines';

const sspReducer = combineReducers({
  dashboard,
  requestForm,
  questionnaire,
  testingForm,
  testLogs,
  ediSelection,
  guidelines,
});

export default sspReducer;
