import React from 'react';
import { Empty } from 'antd';
import PropTypes from 'prop-types';

const OverlayNoRows = ({ description }) => {
  return (
    <div className='overlay-no-rows'>
      <Empty description={description} />
    </div>
  );
};

OverlayNoRows.propTypes = {
  description: PropTypes.string.isRequired,
};

OverlayNoRows.defaultProps = {
  description: 'No Results Found!',
};

export default OverlayNoRows;
