import Cookies from 'js-cookie';
import { isMobile } from 'react-device-detect';
import { useSelector, shallowEqual } from 'react-redux';
import ReactGA from 'react-ga4';
import { v4 as uuidv4 } from 'uuid';

function authHeader() {
  let accessToken = Cookies.get('accessToken');

  if (accessToken) {
    return { Authorization: 'Bearer ' + accessToken };
  } else {
    return {};
  }
}

function isLogged() {
  let accessToken = Cookies.get('accessToken');
  const continueSession = Cookies.get('continueSession');
  const sessionExpired = Cookies.get('sessionExpired');

  if (!getLoginStatus()) {
    return false;
  }

  const notSessionExpired =
    sessionExpired !== undefined && sessionExpired !== 'true';

  const sessionContinue =
    continueSession !== undefined && continueSession !== 'false';

  return !!(accessToken && sessionContinue && notSessionExpired);
}

function deviceTypeCheck() {
  return isMobile === true ? 'mobile' : 'computer';
}

function isDevInstance() {
  return (
    !process.env.NODE_ENV ||
    process.env.NODE_ENV === 'development' ||
    process.env.REACT_APP_HOST === 'development'
  );
}

function isStageInstance() {
  return (
    !process.env.NODE_ENV ||
    process.env.NODE_ENV === 'stage' ||
    process.env.REACT_APP_HOST === 'stage'
  );
}

function shallowEqualCheck(object1, object2) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }

  return true;
}

function useShallowEqualSelector(selector) {
  return useSelector(selector, shallowEqual);
}

function extractException(exception) {
  if (exception) {
    let extractedException = exception.split('(');
    return extractedException[0].trim();
  }
}

function isOnline() {
  let status = navigator.onLine;
  if (!status) {
    return "You're offline.";
  }
}

function getLoginStatus() {
  let loginStatus = Cookies.get('loginStatus');

  return !(loginStatus !== undefined && loginStatus == 'false');
}

function truncate(str, n) {
  return str.length > n ? str.substr(0, n - 1) + '...' : str;
}

function countryCodeExtractor(data) {
  return data.substring(data.indexOf('(') + 1, data.indexOf(')'));
}

function timeFormatter(data, delimiter) {
  return data.substr(0, data.lastIndexOf(delimiter));
}

const isMassDownload = (totalRows) =>
  totalRows > process.env.REACT_APP_MASS_DOWNLOAD_ENABLE_LIMIT;

function getLastRowIndex(request, results) {
  if (!results) return undefined;
  let currentLastRow = request.startRow + results.length;
  return currentLastRow < request.endRow ? currentLastRow : undefined;
}

function getAgGridEnterPriseModule() {
  return import(
    /* webpackChunkName: "ag-grid-enterprise" */ 'ag-grid-enterprise'
  );
}

function setAgGridLicense() {
  getAgGridEnterPriseModule().then(({ LicenseManager }) => {
    LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_LICENSE_KEY);
  });
}

/**
 *
 * @param {number} daysCount How many days to return
 * @param {string} rangeType Type of days calculation prev|next
 * @returns {array} Array of dates in YYYY-MM-DD format.
 */
function getDateArrayByDays(daysCount, rangeType = 'prev') {
  const currDate = new Date();
  let dates = [];
  const pad = (number) => (number < 10 ? `0${number}` : number);
  for (let i = 1; i <= daysCount; i++) {
    const date = new Date();
    if (rangeType == 'prev') {
      date.setUTCDate(currDate.getUTCDate() - i);
    } else if (rangeType == 'next') {
      date.setUTCDate(currDate.getUTCDate() + i);
    }
    const formattedDate = `${date.getUTCFullYear()}-${pad(
      date.getUTCMonth() + 1
    )}-${pad(date.getUTCDate())}`;
    dates.push(formattedDate);
  }
  return dates;
}

function getArrayOfMondays(n) {
  let dates = [];
  let date = new Date();
  let day = date.getDay();
  let prevMonday = new Date();
  if (date.getDay() == 0) {
    prevMonday.setDate(date.getDate() - 6);
  } else {
    prevMonday.setDate(date.getDate() - (day - 1));
  }
  for (let i = 1; i <= n; i++) {
    const formattedDate = `${prevMonday.getFullYear()}-${
      prevMonday.getMonth() + 1
    }-${prevMonday.getDate()}`;
    dates.push(formattedDate);
    prevMonday.setDate(prevMonday.getDate() - 7);
  }
  return dates;
}

function getAgGridDisplayedRowsData(gridApi) {
  let rows = [];
  gridApi.forEachNode((node) => {
    if (node !== undefined) {
      rows.push(node.data);
    }
  });
  return rows;
}

function getUserExportDelimiter(store) {
  const state = store.getState();
  const { export_delimiter } = state.profile.user.userDetails;
  if (export_delimiter !== '') {
    if (export_delimiter === '\\t') {
      return '\t';
    }
    return export_delimiter;
  }

  return ',';
}

const ensureGridApiHasBeenSet = (component) => {
  return waitForAsyncCondition(() => {
    return component.instance().api !== undefined;
  }, 5);
};

const waitForAsyncCondition = (condition, maxAttempts, attempts = 0) =>
  new Promise(function (resolve, reject) {
    (function waitForCondition() {
      // we need to wait for the gridReady event before we can start interacting with the grid
      // in this case we're looking at the api property in our App component, but it could be
      // anything (ie a boolean flag)
      if (condition()) {
        // once our condition has been met we can start the tests
        return resolve();
      }
      attempts++;

      if (attempts >= maxAttempts) {
        reject('Max timeout waiting for condition');
      }

      // not set - wait a bit longer
      setTimeout(waitForCondition, 10);
    })();
  });

const setFilterModel = (colId, value, api) => {
  if (!colId && !value) {
    api.setFilterModel(null);
    return;
  }
  let filterInstance = api.getFilterInstance(colId);
  filterInstance.setModel({ values: [value] });
  api.onFilterChanged();
};

const setSortModel = (colId, sort, api) => {
  if (!colId && !sort) {
    api.setSortModel(null);
    return;
  }
  api.setSortModel([{ colId, sort }]);
};

const getWalkthrough = (module = 'cds', all = false) => {
  const walkthrough = Cookies.get('walkthrough');
  if (walkthrough !== undefined) {
    const parsedWalkthrough = JSON.parse(walkthrough);
    if (all) {
      return parsedWalkthrough;
    } else {
      if (parsedWalkthrough !== undefined) {
        const getModule = module.toLowerCase();
        if (getModule in parsedWalkthrough) {
          return parsedWalkthrough[getModule];
        }
      }
    }
  }
};

const UseGAEventsTracker = (category = 'Event Category') => {
  return (action = 'action', label = 'label') => {
    ReactGA.event({ category, label, action });
  };
};

const getGATrackingCode = () => {
  const env = process.env.REACT_APP_HOST;
  if (env === 'production') return process.env.REACT_APP_PROD_GA_TRACKING_CODE;
  else if (env === 'development')
    return process.env.REACT_APP_DEV_GA_TRACKING_CODE;
  else if (env === 'stage') return process.env.REACT_APP_STAGE_GA_TRACKING_CODE;
  else if (env === 'uat') return process.env.REACT_APP_UAT_GA_TRACKING_CODE;
  else return '';
};

// For Ga time calculations
const gatrackingTimecal = (timeInSeconds) => {
  let LoadTime = timeInSeconds / 5; // For added get page average time.
  LoadTime = Math.abs(LoadTime);
  let floorVal = Math.floor(LoadTime) * 5 + 5;
  return Math.floor(LoadTime) * 5 + '-' + floorVal + ' seconds';
};

// to get start/end epoch time for loading a particular page/component
const getEpochTime = () => {
  let currentunixTimeInMilliSec = new Date().getTime();
  return Math.floor(Math.abs(currentunixTimeInMilliSec / 1000));
};

// to show page load timing calculation as title for a particular component as pageview and send it to GA
const gaPageviewTimingTitle = (pageName, startTime) => {
  let endTime = getEpochTime();
  let diffTime = endTime - startTime;
  let avgTime = gatrackingTimecal(diffTime);
  ReactGA.send({ hitType: 'pageview', page: pageName, title: avgTime });
};

const getUUID = () => uuidv4();

const strFormat = (params) => {
  return params.replace(/[- )(]/g, '');
};

/**
 * Simple is object check.
 * @param item
 * @returns {boolean}
 */
const isObject = (item) => {
  return (
    item && typeof item === 'object' && !Array.isArray(item) && item !== null
  );
};

const currentDateGenerator = () => {
  let newDate = new Date();
  let separator = '-';
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  const monthFormatted = month < 10 ? `0${month}` : `${month}`;

  return `${year}${separator}${monthFormatted}${separator}${date}`;
};

const wordSplitter = (value) => {
  let splitWords = value.split(' ');
  let filterdValues = splitWords.filter((words) => words != '');
  return filterdValues.length;
};

const getSymbol = () => String(Symbol('partnerconnect'));

export {
  authHeader,
  isLogged,
  deviceTypeCheck,
  isDevInstance,
  shallowEqualCheck,
  useShallowEqualSelector,
  extractException,
  isOnline,
  getLoginStatus,
  truncate,
  countryCodeExtractor,
  timeFormatter,
  isMassDownload,
  getLastRowIndex,
  getAgGridEnterPriseModule,
  setAgGridLicense,
  getDateArrayByDays,
  getArrayOfMondays,
  getAgGridDisplayedRowsData,
  getUserExportDelimiter,
  ensureGridApiHasBeenSet,
  setFilterModel,
  setSortModel,
  getWalkthrough,
  UseGAEventsTracker,
  getGATrackingCode,
  gatrackingTimecal,
  gaPageviewTimingTitle,
  getEpochTime,
  getUUID,
  strFormat,
  isObject,
  currentDateGenerator,
  wordSplitter,
  isStageInstance,
  getSymbol,
};
