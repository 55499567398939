import Cookies from 'js-cookie';
import { extractException } from 'helpers/utils';

const MFA_UPDATE = 'profile/mfa/MFA_UPDATE';
const MFA_UPDATE_SUCCESS = 'profile/mfa/MFA_UPDATE_SUCCESS';
const MFA_UPDATE_FAIL = 'profile/mfa/MFA_UPDATE_FAIL';
const MFA_SMS_OTP_GENERATE = 'profile/mfa/MFA_SMS_OTP_GENERATE';
const MFA_SMS_OTP_GENERATE_SUCCESS = 'profile/mfa/MFA_SMS_OTP_GENERATE_SUCCESS';
const MFA_SMS_OTP_GENERATE_FAIL = 'profile/mfa/MFA_SMS_OTP_GENERATE_FAIL';
const MFA_TOTP_GENERATE = 'profile/mfa/MFA_TOTP_GENERATE';
const MFA_TOTP_GENERATE_SUCCESS = 'profile/mfa/MFA_TOTP_GENERATE_SUCCESS';
const MFA_TOTP_GENERATE_FAIL = 'profile/mfa/MFA_TOTP_GENERATE_FAIL';
const MFA_RESET_SUCCESS = 'profile/mfa/MFA_RESET_SUCCESS';
const MFA_ENABLE_MOBILE = 'profile/mfa/MFA_ENABLE_MOBILE';
const MFA_PREFERENCE_UPDATE_SUCCESS =
  'profile/mfa/MFA_PREFERENCE_UPDATE_SUCCESS';
const MFA_PREFERENCE_SELECTED = 'profile/mfa/MFA_PREFERENCE_SELECTED';

const initialState = {
  loaded: false,
  preferenceSelected: null,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case MFA_UPDATE:
    case MFA_SMS_OTP_GENERATE:
    case MFA_TOTP_GENERATE:
      return { ...state, loading: true };
    case MFA_UPDATE_SUCCESS:
      return {
        ...state,
        mfaUpdateMsg: action.payload,
        error: null,
        enableMfaSmsOtp: false,
        enableMfaTotp: false,
        enableMfaMobile: false,
        secretCode: null,
        loaded: true,
        preferenceSelected: null,
      };
    case MFA_SMS_OTP_GENERATE_SUCCESS:
      return {
        ...state,
        mfaUpdateMsg: action.payload,
        enableMfaSmsOtp: true,
        enableMfaTotp: false,
        error: null,
        loaded: true,
      };
    case MFA_TOTP_GENERATE_SUCCESS:
      return {
        ...state,
        mfaUpdateMsg: action.payload,
        enableMfaTotp: true,
        enableMfaSmsOtp: false,
        error: null,
        secretCode: action.secretCode,
        loaded: true,
      };
    case MFA_RESET_SUCCESS:
      return {
        ...state,
        mfaUpdateMsg: '',
        error: '',
        enableMfaSmsOtp: false,
        enableMfaTotp: false,
        enableMfaMobile: false,
        loaded: true,
        secretCode: null,
        preferenceSelected: null,
      };
    case MFA_PREFERENCE_UPDATE_SUCCESS:
      return { ...state, mfaPreference: action.payload };
    case MFA_ENABLE_MOBILE:
      return {
        ...state,
        enableMfaMobile: true,
        enableMfaSmsOtp: false,
        enableMfaTotp: false,
        secretCode: null,
        mfaUpdateMsg: '',
        error: '',
      };
    case MFA_PREFERENCE_SELECTED:
      return {
        ...state,
        preferenceSelected: action.payload,
      };
    case MFA_UPDATE_FAIL:
    case MFA_SMS_OTP_GENERATE_FAIL:
    case MFA_TOTP_GENERATE_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}

export function saveMfaPreference(currentPreference, preference) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: MFA_UPDATE });
    return api
      .post(
        'ums',
        formatParams(
          {
            type: 'MFA_PREFERENCE_REQUEST',
            details: {
              email: Cookies.get('email'),
              accessToken: Cookies.get('accessToken'),
              mfaPreference: preference,
              currentMFAPreference: currentPreference,
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data && data.returnMsg == 'SUCCESS') {
          if (data.result && data.result.mfaUpdateStatus) {
            dispatch({
              type: MFA_UPDATE_SUCCESS,
              payload: data.result.mfaUpdateStatus,
            });
            dispatch({
              type: MFA_PREFERENCE_UPDATE_SUCCESS,
              payload: preference,
            });
          } else {
            dispatch(error());
          }
        } else {
          dispatch(error());
        }
      });
  };

  function error() {
    return {
      type: MFA_UPDATE_FAIL,
      payload: 'MFA preference update failed. Please try again..',
    };
  }
}

export function generateSMSOtp(phoneNumber) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: MFA_SMS_OTP_GENERATE });
    return api
      .post(
        'ums',
        formatParams(
          {
            type: 'UPDATE_PHONE_NUMBER_REQUEST',
            details: {
              email: Cookies.get('email'),
              accessToken: Cookies.get('accessToken'),
              attributeName: 'phone_number',
              attributeValue: phoneNumber,
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data && data.returnMsg == 'SUCCESS') {
          const { codeDeliveryDetailsList, mfaUpdateStatus } = data.result;
          if (mfaUpdateStatus) {
            dispatch({ type: MFA_UPDATE_SUCCESS, payload: mfaUpdateStatus });
          } else if (codeDeliveryDetailsList === null) {
            dispatch({
              type: MFA_UPDATE_FAIL,
              payload: 'Mobile number already verified!',
            });
          } else if (
            codeDeliveryDetailsList &&
            codeDeliveryDetailsList.length
          ) {
            dispatch({
              type: MFA_SMS_OTP_GENERATE_SUCCESS,
              payload: `We have delivered the authentication code by SMS to ${codeDeliveryDetailsList[0]['destination']}. Please enter the code to complete authentication.`,
            });
          } else {
            dispatch(error('Error in generating SMS authentication code.'));
          }
        } else {
          let errorMessage = 'Error in generating SMS authentication code.';
          if (data.result && data.result.errorMessage) {
            errorMessage = extractException(data.result.errorMessage);
          }

          dispatch(error(errorMessage));
        }
      });
  };

  function error(data) {
    return {
      type: MFA_SMS_OTP_GENERATE_FAIL,
      payload: data,
    };
  }
}

export function generateTOTP() {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: MFA_TOTP_GENERATE });
    return api
      .post(
        'ums',
        formatParams(
          {
            type: 'ASSOCIATE_SOFTWARE_TOKEN_REQUEST',
            details: {
              email: Cookies.get('email'),
              accessToken: Cookies.get('accessToken'),
              session: '',
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data && data.returnMsg == 'SUCCESS') {
          const { secretCode } = data.result;
          if (secretCode) {
            dispatch({
              type: MFA_TOTP_GENERATE_SUCCESS,
              secretCode: secretCode,
              payload: `Please generate and enter authentication code from your passcode app by using the secret ${secretCode}`,
            });
          } else {
            dispatch(
              error(
                'Error in generating Software authentication code. Please try again!'
              )
            );
          }
        } else {
          let errorMessage =
            'Error in generating Software authentication code. Please try again!';
          if (data.result && data.result.errorMessage) {
            errorMessage = extractException(data.result.errorMessage);
          }

          dispatch(error(errorMessage));
        }
      });
  };

  function error(data) {
    return {
      type: MFA_TOTP_GENERATE_FAIL,
      payload: data,
    };
  }
}

export function updateMfaPreference(type, otp) {
  let details, reqType;
  const email = Cookies.get('email');
  const accessToken = Cookies.get('accessToken');

  if (type == 'SMS_MFA') {
    reqType = 'VERIFY_USER_ATTRIBUTE_REQUEST';
    details = {
      attributeName: 'phone_number',
      verificationCode: otp,
    };
  } else if (type == 'SOFTWARE_TOKEN_MFA') {
    reqType = 'VERIFY_SOFTWARE_TOKEN_REQUEST';
    details = {
      session: '',
      friendlyDeviceName: '',
      userCode: otp,
    };
  }

  const requestDetails = { ...details, email, accessToken };

  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: MFA_UPDATE });
    return api
      .post(
        'ums',
        formatParams({ type: reqType, details: requestDetails }, getState)
      )
      .then((response) => {
        const { data } = response;
        if (data && data.returnMsg == 'SUCCESS') {
          const { mfaUpdateStatus } = data.result;
          if (mfaUpdateStatus) {
            dispatch({
              type: MFA_UPDATE_SUCCESS,
              payload: mfaUpdateStatus,
            });
            dispatch({
              type: MFA_PREFERENCE_UPDATE_SUCCESS,
              payload: type,
            });
          } else {
            dispatch(error());
          }
        } else {
          dispatch(error());
        }
      });
  };

  function error() {
    return {
      type: MFA_UPDATE_FAIL,
      payload: 'Invalid code received . Please try again!',
    };
  }
}
