import React from 'react';
import PropTypes from 'prop-types';
import { List, Avatar } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import { getSymbol } from 'helpers/utils';

const DocumentList = (props) => {
  return (
    <List
      itemLayout='horizontal'
      dataSource={props.supportDocuments}
      loading={{
        spinning: props.loading,
        tip: 'Loading documents...',
      }}
      locale={{
        emptyText: (
          <div>
            <SmileOutlined rotate={180} /> No documents available for this
            module.
          </div>
        ),
      }}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            avatar={
              <Avatar>
                {item.category.split(' ').map((i) => i.charAt(0).toUpperCase())}
              </Avatar>
            }
            title={item.category}
            description={
              <>
                {item.documents.map((data) => {
                  return (
                    <List.Item.Meta
                      key={getSymbol()}
                      description={
                        <a href={data.url} target='_blank'>
                          {data.title}
                        </a>
                      }
                    />
                  );
                })}
              </>
            }
          />
        </List.Item>
      )}
    />
  );
};

DocumentList.propTypes = {
  supportDocuments: PropTypes.arrayOf(Object).isRequired,
  loading: PropTypes.bool,
};

export default DocumentList;
