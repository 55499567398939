import store from '../../../../redux/store';
import service from '../../../../service/Service';
import {
  getLastRowIndex,
  getUUID,
  gaPageviewTimingTitle,
  getEpochTime,
} from '../../../../helpers/utils';
import selectColumn from '../../../common/ag-grid-select-column';
import Cookie from 'js-cookie';
import {
  exportDTo,
  getMassDownloadGridParams,
} from '../../../../helpers/export';
import splitColumn from '../../../common/split-row/ag-grid-split-renderer';
import splitRowCheckbox from '../../../common/split-row/ag-grid-split-row-checkbox';

import CustomTextEditor from '../../../common/CustomTextEditor';
import OverlayLoading from '../../../common/OverlayLoading';
import OverlayNoRows from '../../../common/OverlayNoRows';
import Datepicker from '../../../common/Datepicker';
import CustomSelectEditor from '../../../common/CustomSelectEditor';
import { message } from 'antd';
import moment from 'moment';

export const GET_REQUEST_NO = 'ssp/testing-form/GET_REQUEST_NO';
export const GET_REQUEST_NO_SUCCESS = 'ssp/testing-form/GET_REQUEST_NO_SUCCESS';
export const GET_REQUEST_NO_FAIL = 'ssp/testing-form/GET_REQUEST_NO_FAIL';
export const TESTING_FORM_COLUMNS = 'ssp/testing-form/TESTING_FORM_COLUMNS';
export const TESTING_FORM_COLUMNS_SUCCESS =
  'ssp/testing-form/TESTING_FORM_COLUMNS_SUCCESS';
export const TESTING_FORM_COLUMNS_FAIL =
  'ssp/testing-form/TESTING_FORM_COLUMNS_FAIL';

export const TESTING_FORM_ROWS_INFO = 'ssp/testing-form/TESTING_FORM_ROWS_INFO';

export const TESTING_FORM_SUBMIT = 'ssp/testing-form/TESTING_FORM_SUBMIT';
export const TESTING_FORM_SUBMIT_SUCCESS =
  'ssp/testing-form/TESTING_FORM_SUBMIT_SUCCESS';
export const TESTING_FORM_SUBMIT_FAIL =
  'ssp/testing-form/TESTING_FORM_SUBMIT_FAIL';

export const DOCS_STD_SUCCESS = 'ssp/testing-form/DOCS_STD_SUCCESS';

export const DESTROY_TESTING_FORM_BY_KEY =
  'ssp/testing-form/DESTROY_TESTING_FORM_BY_KEY';

const initialState = {
  newTab0: {
    req_number: [],
    loading: false,
    rowsInfo: {
      endRow: 0,
      lastRow: 0,
    },
    testingFormColumns: {},
    doc_std_value: '',
    submittingItem: false,
    submittedItem: true,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_REQUEST_NO:
      return {
        ...state,
        [action.tabKey]: { ...state[action.tabKey], loadingRequestNos: true },
      };
    case GET_REQUEST_NO_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loadingRequestNos: false,
          req_number: action.payload,
        },
      };
    case GET_REQUEST_NO_FAIL:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loadingRequestNos: false,
          error: action.payload,
        },
      };
    case TESTING_FORM_COLUMNS:
      return {
        ...state,
        [action.tabKey]: { ...state[action.tabKey], loading: true },
      };
    case TESTING_FORM_COLUMNS_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          testingFormColumns: action.payload,
        },
      };
    case TESTING_FORM_COLUMNS_FAIL:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          error: action.payload,
        },
      };
    case TESTING_FORM_ROWS_INFO:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          rowsInfo: action.payload,
        },
      };
    case DOCS_STD_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          doc_std_value: action.payload,
        },
      };

    case TESTING_FORM_SUBMIT:
      return {
        ...state,
        [action.tabKey]: { ...state[action.tabKey], submittingItem: true },
      };
    case TESTING_FORM_SUBMIT_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          submittingItem: false,
          submittedItem: true,
        },
      };
    case TESTING_FORM_SUBMIT_FAIL:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          submittingItem: false,
          error: action.payload,
        },
      };
    case DESTROY_TESTING_FORM_BY_KEY:
      delete state[action.tabKey];
      return state;
    default:
      return state;
  }
}

const getCellStyle = (params) => {
  if (params.data.splitRecords.length) {
    const [splitData] = params.data.splitRecords;
    if (Object.getOwnPropertyNames(splitData).length) {
      return { backgroundColor: '#81ecec' };
    }
  }
};

function getRequestNo(tabKey) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: GET_REQUEST_NO, tabKey });
    return api
      .post(
        'ssp',
        formatParams(
          {
            type: 'TESTING_FORM_DETAILS',
            details: {
              email: Cookie.get('email'),
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data) {
          dispatch({
            type: GET_REQUEST_NO_SUCCESS,
            payload: data.b2b_req_no_list,
            tabKey,
          });
        } else {
          dispatch({
            type: GET_REQUEST_NO_FAIL,
            payload: 'Error in loading B2B request numbers!',
            tabKey,
          });
        }
      });
  };
}

function getTestingFormColumns(b2bnumber, doctype, tabKey) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: TESTING_FORM_COLUMNS, tabKey });
    const currentTime = getEpochTime();
    return api
      .post(
        'ssp',
        formatParams(
          {
            type: 'AG_GRID_COLUMNS',
            view: `TESTING_FORM_${doctype}`,
            details: {
              b2b_req_no: b2bnumber,
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        gaPageviewTimingTitle(
          `/SSP/TestingForm/getTestingFormColumns`,
          currentTime
        );
        if (data && data.statusCode === '200') {
          let configs = data.result;

          const splitColProps = {
            components: {
              agCustomTextEditor: CustomTextEditor,
              customLoadingOverlay: OverlayLoading,
              customNoRowsOverlay: OverlayNoRows,
              datePicker: Datepicker,
              agRichSelectCellEditor: CustomSelectEditor,
            },
            suppressRowHoverHighlight: true,
            stopEditingWhenCellsLoseFocus: false,
          };

          if (doctype == 810) {
            configs.columnDefs.unshift({
              ...selectColumn,
              headerComponent: null,
            });
          } else {
            configs = {
              ...configs,
              detailCellRendererParams: {
                ...configs.detailCellRendererParams,
                detailGridOptions: {
                  ...configs.detailCellRendererParams.detailGridOptions,
                  ...splitColProps,
                  columnDefs: [
                    splitRowCheckbox,
                    ...configs.detailCellRendererParams.detailGridOptions
                      .columnDefs,
                  ],
                },
              },
              columnDefs: [
                { ...selectColumn, headerComponent: null },
                {
                  ...splitColumn,
                  cellStyle: getCellStyle,
                },
                ...configs.columnDefs,
              ],
            };
          }
          dispatch({
            type: TESTING_FORM_COLUMNS_SUCCESS,
            payload: configs,
            tabKey,
          });
        } else {
          dispatch({
            type: TESTING_FORM_COLUMNS_FAIL,
            payload: 'Error in loading testing form!',
            tabKey,
          });
        }
      });
  };
}

const calculateRowsInvoice = (result, doctype) => {
  return result.rowData.map((row) => {
    let invoiceCalcs = {};
    if (doctype == 810) {
      invoiceCalcs = {
        invoice_date: moment().format('YYYY-MM-DD'),
        total_amt:
          row.total_amt != ''
            ? row.total_amt
            : parseFloat(row.inv_qty * row.price).toFixed(6),
        tax_amt: row.tax_amt != '' ? row.tax_amt : '0.000000',
      };

      if (row.tax_prcnt != '' && row.inv_qty != '' && row.price != '') {
        let tax_amt = parseFloat(row.inv_qty * row.price * row.tax_prcnt) / 100;
        tax_amt = parseFloat(tax_amt).toFixed(6);
        let total_amt =
          parseFloat(row.inv_qty * row.price) + parseFloat(tax_amt);
        total_amt = parseFloat(total_amt).toFixed(6);
        invoiceCalcs = {
          ...invoiceCalcs,
          tax_amt,
          total_amt,
        };
      }
    }

    return {
      ...row,
      isEditing: false,
      errors: {},
      hasError: false,
      ...invoiceCalcs,
    };
  });
};
function getTestingFormRows(b2bnumber, doctype, tabKey) {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      params.api.showLoadingOverlay();
      const currentTime = getEpochTime();
      return api
        .post(
          'ssp',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: `TESTING_FORM_${doctype}`,
              details: {
                b2b_req_no: b2bnumber,
                email: Cookie.get('email'),
              },
              gridParams: { ...params.request },
            },
            store.getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(
            `/SSP/TestingForm/getTestingFormRows`,
            currentTime
          );
          const { data } = response;
          if (data && data.statusCode === '200') {
            if (data.result.doc_std) {
              store.dispatch({
                type: DOCS_STD_SUCCESS,
                payload: data.result.doc_std,
                tabKey,
              });
            }

            let rowData = calculateRowsInvoice(data.result, doctype);

            params.success({
              rowData,
              rowCount: getLastRowIndex(params.request, rowData),
            });

            const endRow =
              params.request.endRow > data.result.lastRow
                ? data.result.lastRow
                : params.request.endRow;
            store.dispatch({
              type: TESTING_FORM_ROWS_INFO,
              payload: { lastRow: data.result.lastRow, endRow },
              tabKey,
            });

            // Hide overlay loading on success
            params.api.hideOverlay();
            if (!rowData.length) {
              params.api.showNoRowsOverlay();
            }
          } else {
            params.fail();
          }
        })
        .catch(() => {
          params.fail();
        });
    },
  };
}

const calculateTotalPoQty = (nodes) => {
  return nodes.reduce((sum, data) => {
    sum += parseFloat(data.po_qty);
    return sum;
  }, 0);
};

const prepare850 = (data, splitNodes) => {
  const [node] = data;
  let sch = [
    {
      request_date: moment(node.request_date).format('YYYYMMDD'),
      po_qty: node.po_qty,
      split_id: 0,
    },
  ];

  if (splitNodes.length) {
    sch = [];
    splitNodes.forEach((d) => {
      sch.push({
        request_date: moment(d.request_date).format('YYYYMMDD'),
        po_qty: d.po_qty,
        split_id: d.split_id,
      });
    });
  }

  return {
    hdr: {
      po_number: node.po_number,
      tp_code: node.tp_code,
      release: node.po_types,
      release_value: node.po_types == 'RELEA' ? '1' : '0',
      supplier_name: node.supplier_name,
      supplier_number: node.supplier_number,
      ship_to_code: node.ship_to_code,
      ship_to_name: node.ship_to_desc,
      bill_to_code: node.bill_to_code,
      bill_to_name: node.bill_to_desc,
      creation_date: moment().format('YYYYMMDD'),
      detail: [
        {
          po_line_number: node.po_line_number,
          mfg: node.mfg,
          part: node.part,
          price: node.price,
          po_qty: node.po_qty,
          total_po_qty: splitNodes.length
            ? calculateTotalPoQty(splitNodes)
            : node.po_qty,
          sch: sch,
        },
      ],
    },
  };
};
const getMaxPromiseDate = (nodes) => {
  if (nodes.length) {
    const max = nodes.reduce((maxDate, data) => {
      const date = moment(data.promise_date);
      if (date > maxDate) {
        maxDate = date;
      }
      return maxDate;
    }, moment());

    return max.format('YYYY-MM-DD');
  }
};

const sumSchQty = (nodes) => {
  return nodes.reduce((sum, data) => {
    sum += parseFloat(data.sch_qty);
    return sum;
  }, 0);
};

const prepare855 = (data, splitNodes, b2b_req_no) => {
  const [node] = data;
  let sch = [
    {
      req_date: node.req_date,
      sch_uom: node.sch_uom,
      lin_uom: node.lin_uom,
      lin_qty: node.lin_qty,
      lin_status_code: node.lin_status_code,
      sch_qty: splitNodes.length ? sumSchQty(splitNodes) : node.sch_qty,
      promise_date: splitNodes.length
        ? getMaxPromiseDate(splitNodes)
        : node.promise_date,
      split_id: 0,
    },
  ];

  if (splitNodes.length) {
    splitNodes.forEach((d) => {
      sch.push({
        req_date: node.req_date,
        sch_uom: node.sch_uom,
        lin_uom: node.lin_uom,
        lin_qty: node.lin_qty,
        lin_status_code: node.lin_status_code,
        sch_qty: d.sch_qty,
        promise_date: d.promise_date,
        split_id: d.split_id,
      });
    });
  }

  return {
    hdr: {
      cust_plant: node.customer_plant,
      b2b_req_no: b2b_req_no,
      cust_code: node.customer,
      po_number: node.po_num,
      po_date: node.po_date,
      po_rel_num: node.po_rel_num,
      curr_code: node.curr_code,
      ship_to_name: node.ship_to_name,
      ship_to_code: node.ship_to_code,
      routing: node.routing,
      mfg_part_num: node.mfg_part_num,
      part_desc: node.part_desc,
      st_addr1: node.st_addr1,
      st_addr2: node.st_addr1,
      st_city: node.st_city,
      st_state: node.st_state,
      st_postal_code: node.st_postal_code,
      st_country_code: node.st_country_code,
      se_to_name: node.se_to_name,
      se_to_code: node.se_to_code,
      se_addr1: node.se_addr1,
      se_addr2: node.se_addr2,
      se_state: node.se_state,
      se_postal_code: node.se_postal_code,
      se_country_code: node.se_country_code,
      detail: [
        {
          action: node.action,
          mfg_part_num: node.mfg_part_num,
          buy_part_num: node.buy_part_num,
          price: node.price,
          supplier_price: node.supplier_price,
          po_qty: node.po_qty,
          total_po_qty: node.po_qty,
          po_uom: node.po_uom,
          po_lin_num: node.po_line_num,
          so_line_number: node.so_line_number,
          sch: sch,
        },
      ],
    },
  };
};
const prepare856 = (data, splitNodes, b2b_req_no) => {
  const [node] = data;
  let details = [
    {
      ship_lin_num: node.ship_line_num,
      ship_unit: node.ship_unit,
      uom: node.uom,
      po_number: node.po_num,
      po_rel_num: node.po_rel_num,
      po_date: node.po_date,
      cust_po_lin_num: node.cust_po_line_num,
      serial_num: splitNodes.length ? node.serial_num : '',
      split_id: 0,
      buy_part_num: node.buy_part_num,
      so_line_number: node.so_line_number,
    },
  ];

  if (splitNodes.length) {
    splitNodes.forEach((d) => {
      details.push({
        ship_lin_num: node.ship_line_num,
        ship_unit: node.ship_unit,
        uom: node.uom,
        po_number: node.po_num,
        po_rel_num: node.po_rel_num,
        po_date: node.po_date,
        cust_po_lin_num: node.cust_po_line_num,
        serial_num: d.serial_num,
        split_id: d.split_id,
        buy_part_num: node.buy_part_num,
        so_line_number: node.so_line_number,
      });
    });
  }

  return {
    hdr: {
      cust_plant: node.cust_plant,
      b2b_req_no: b2b_req_no,
      cust_code: node.cust_code,
      po_number: node.po_num,
      ship_date: node.ship_date,
      ship_num: node.ship_num,
      weight: '',
      weight_unit: '',
      ship_to_name: node.ship_to_name,
      ship_to_code: node.ship_to_code,
      pak_slip_num: node.pak_slip_num,
      waybill_num: node.waybill_num,
      routing: node.routing,
      mfg_part_num: node.mfg_part_num,
      part_desc: node.part_desc,
      se_to_name: node.se_to_name,
      se_to_code: node.se_to_code,
      st_addr1: node.st_addr1,
      st_addr2: node.st_addr2,
      st_city: node.st_city,
      st_state: node.st_state,
      st_postal_code: node.st_postal_code,
      st_country_code: node.st_country_code,
      detail: details,
    },
  };
};

const prepare810 = (data, b2b_req_no) => {
  const [node] = data;
  return {
    hdr: {
      cust_plant: node.cust_plant,
      b2b_req_no: b2b_req_no,
      cust_code: node.cust_code,
      po_number: node.po_num,
      bill_to_name: node.bill_to_name,
      bill_to_code: node.bill_to_code,
      curr_code: node.curr_code,
      po_date: node.po_date,
      po_rel_num: node.po_rel_num,
      inv_date: node.invoice_date,
      inv_num: node.inv_num,
      tax_code: node.tax_code,
      tax_prcnt: node.tax_prcnt,
      routing: node.routing,
      mfg_part_num: node.mfg_part_num,
      part_desc: node.part_desc,
      st_idf_code: node.st_idf_code,
      st_name: node.st_name,
      st_addr1: node.st_addr1,
      st_addr2: node.st_addr2,
      st_city: node.st_city,
      st_state: node.st_state,
      st_postal_code: node.st_postal_code,
      st_country_code: node.st_country_code,
      detail: [
        {
          inv_qty: node.inv_qty,
          uom: node.uom,
          buy_part_num: node.buy_part_num,
          price: node.price,
          pak_slip_num: node.pak_slip_num,
          tax_amt: node.tax_amt,
          total_amt: node.total_amt,
          so_line_number: node.so_line_number,
          po_line_number: node.po_line_number,
        },
      ],
    },
  };
};
export function getValidate850(formattedValues) {
  return (dispatch, getState, { api, formatParams }) => {
    return api
      .post(
        'ssp',
        formatParams(
          {
            type: 'SSP_850_CHECK',
            details: { request_number: formattedValues },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data.statusCode == 200) {
          if (data.returnCode == '0' && data.result != undefined) {
            return data.result;
          } else {
            message.error('Something went wrong. Please try again.');
          }
        } else {
          message.error('Something went wrong. Please try again.');
        }
      });
  };
}
function submitData(nodes, splitNodes, doc_type, b2b_req_no, callback, tabKey) {
  let headers = {};
  let b2b_type;
  let senderID;

  if (nodes.length) {
    const [data] = nodes;
    b2b_type = data.b2b_type;
    senderID = data.sender_id ? data.sender_id : 'PORTALDEMO';
  }

  if (doc_type == 850) {
    headers = prepare850(nodes, splitNodes);
  } else if (doc_type == 860) {
    headers = prepare850(nodes, splitNodes);
  } else if (doc_type == 855) {
    headers = prepare855(nodes, splitNodes, b2b_req_no);
  } else if (doc_type == 865) {
    headers = prepare855(nodes, splitNodes, b2b_req_no);
  } else if (doc_type == 856) {
    headers = prepare856(nodes, splitNodes, b2b_req_no);
  } else if (doc_type == 810) {
    headers = prepare810(nodes, b2b_req_no);
  }

  return (dispatch, _getState, { api }) => {
    dispatch({ type: TESTING_FORM_SUBMIT, tabKey });
    return api
      .post('sspsubmitaction', {
        TXN: [
          {
            transaction_type: `${doc_type}_COMMIT`,
            b2b_req_no,
            testing_type: b2b_type,
            uuid: getUUID(),
            cust_code: 'SANM',
            requestor: senderID,
            source: 'SANM',
            submitter: Cookie.get('email'),
            ...headers,
          },
        ],
      })
      .then((response) => {
        const { data } = response;
        if (
          data == 1 ||
          data == 'SUCCESS' ||
          (data.TXN && data.TXN[0].return_msg == 'SUCCESS')
        ) {
          callback(true);
          message.success('Data saved successfully.');
        } else {
          callback(false);
          message.error('Something went wrong. Please try again.');
        }
      });
  };
}

export function getMassDownloadReport({
  gridApi,
  exportType,
  totalRows,
  cb,
  columnApi,
  fileName,
  view,
}) {
  return (_dispatch, getState, { api, formatParams }) => {
    if (totalRows >= process.env.REACT_APP_MASS_DOWNLOAD_ENABLE_LIMIT) {
      return api
        .post(
          'ssp',
          formatParams(
            {
              type: 'MASS_DOWNLOAD',
              view: view,
              exportType,
              totalRows,
              email: Cookie.get('email'),
              gridParams: {
                ...getMassDownloadGridParams(gridApi, columnApi),
              },
            },
            getState
          )
        )
        .then((response) => {
          cb(true);
          return response;
        })
        .catch((error) => {
          cb(false);
          return error;
        });
    } else {
      const currentTime = getEpochTime();
      return api
        .post(
          'spp',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: view,
              gridParams: {
                ...getMassDownloadGridParams(gridApi, columnApi),
                startRow: 0,
                endRow: totalRows,
              },
              details: { email: Cookie.get('email') },
            },
            store.getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(
            `/SSP/TestingForm/getMassDownloadReport`,
            currentTime
          );
          const { data } = response;
          if (data) {
            const { result } = data;
            if (Object.keys(result).length) {
              exportDTo({
                columnApi,
                fileName: fileName,
                data: result.rowData,
                exportType,
              }) && cb(true);
            }
          }

          return response;
        });
    }
  };
}

const destroyTestingFormByKey = (tabKey) => {
  return (dispatch) => {
    dispatch({ type: DESTROY_TESTING_FORM_BY_KEY, tabKey });
  };
};

export {
  getRequestNo,
  getTestingFormColumns,
  getTestingFormRows,
  submitData,
  destroyTestingFormByKey,
};
