import React from 'react';
import { Spin } from 'antd';

const Loading = (props) => (
  <div className='loading'>
    <Spin size='large' tip={props.tip ? props.tip : 'Loading...'} />
  </div>
);

export default Loading;
