import { combineReducers } from 'redux';
import candidateItem from '../candidate-item/redux/candidate-reducer';
import dashboard from '../acp-dashboard/redux/dashboard-redux-ducks';
import supplierResponseTime from '../reports/redux/supplier-response-time-report';
import implementationStatus from '../reports/redux/implementation-status-report';
import supplierSummary from '../reports/redux/supplier-summary-report';
import exportHistory from '../export-history/redux/export-history';
import excelTemplate from '../excel-template/redux/excel-template';

const acpReducer = combineReducers({
  candidateItem,
  dashboard,
  implementationStatus,
  supplierSummary,
  supplierResponseTime,
  exportHistory,
  excelTemplate,
});

export default acpReducer;
