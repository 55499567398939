import Cookies from 'js-cookie';
import { getUUID } from '../../../../helpers/utils';
const NEW_REPORT = 'cds/update-report/NEW_REPORT';
const GET_CUSTOMERCODE_SUCCESS = 'cds/update-report/GET_CUSTOMERCODE_SUCCESS';
const GET_CUSTOMERCODE_FAIL = 'cds/update-report/GET_CUSTOMERCODE_FAIL';
const GET_CURRENTPLANTS_SUCCESS = 'cds/update-report/GET_CURRENTPLANTS_SUCCESS';
const CDS_CUSTOMER_ADD_SUCCESS = 'cds/update-report/CDS_CUSTOMER_ADD_SUCCESS';
const CDS_COLUMN_ADD_SUCCESS = 'cds/update-report/CDS_COLUMN_ADD_SUCCESS';
const REPORT_FREQUENCY_SUCCESS = 'cds/update-report/REPORT_FREQUENCY_SUCCESS';
const SET_CURRENTSTEPS_SUCCESS = 'cds/update-report/SET_CURRENTSTEPS_SUCCESS';
const GET_CURRENTPLANTS_FAIL = 'cds/update-report/GET_CURRENTPLANTS_FAIL';
const VIEW_COLUMNS = 'cds/update-report/VIEW_COLUMNS';
const VIEW_COLUMNS_SUCCESS = 'cds/update-report/VIEW_COLUMNS_SUCCESS';
const VIEW_COLUMNS_SAMPLE_FAILS = 'cds/update-report/VIEW_COLUMNS_SAMPLE_FAILS';
const VIEW_COLUMNS_SAMPLE_SUCCESS =
  'cds/update-report/VIEW_COLUMNS_SAMPLE_SUCCESS';
const VIEW_COLUMNS_SAMPLE_FAIL = 'cds/update-report/VIEW_COLUMNS_SAMPLE_FAIL';
const REPORT_COLUMNS_SUCCESS = 'cds/update-report/REPORT_COLUMNS_SUCCESS';
const REPORT_CUSTOMERS_SUCCESS = 'cds/update-report/REPORT_CUSTOMERS_SUCCESS';
const REPORT_CUSTOMERS_INDEX_SUCCESS =
  'cds/update-report/REPORT_CUSTOMERS_INDEX_SUCCESS';
const REPORT_COMMUNICATION_SUCCESS =
  'cds/update-report/REPORT_COMMUNICATION_SUCCESS';
const REPORT_REFRESH_SUCCESS = 'cds/update-report/REPORT_REFRESH_SUCCESS';
const VIEW_COLUMNS_FAIL = 'cds/update-report/VIEW_COLUMNS_FAIL';
const CREATE_REPORT_SUCCESS = 'cds/update-report/CREATE_REPORT_SUCCESS';
const CREATE_REPORT_FAIL = 'cds/update-report/CREATE_REPORT_FAIL';
const CDS_REFRESH_VALIDATE_SUCCESS =
  'cds/update-report/CDS_REFRESH_VALIDATE_SUCCESS';
const CDS_REFRESH_VALIDATE_FAIL = 'cds/update-report/CDS_REFRESH_VALIDATE_FAIL';
const CDS_CUSTOMER_VALIDATE_SUCCESS =
  'cds/update-report/CDS_CUSTOMER_VALIDATE_SUCCESS';
const CDS_CUSTOMER_VALIDATE_FAIL =
  'cds/update-report/CDS_CUSTOMER_VALIDATE_FAIL';
const VIEW_SPECIFIC_REPORT_SUCCESS =
  'cds/update-report/VIEW_SPECIFIC_REPORT_SUCCESS';
const VIEW_SPECIFIC_REPORT_FAIL = 'cds/update-report/VIEW_SPECIFIC_REPORT_FAIL';
const SET_REPORTSTATUS_SUCCESS = 'cds/update-report/SET_REPORTSTATUS_SUCCESS';
const REPORT_SUBMIT_SUCCESS = 'cds/update-report/REPORT_SUBMIT_SUCCESS';
const GET_CURRENTINPUTSOURCE_SUCCESS =
  'cds/update-report/GET_CURRENTINPUTSOURCE_SUCCESS';
const GET_CURRENTINPUTSOURCE_FAIL =
  'cds/update-report/GET_CURRENTINPUTSOURCE_FAIL';
const SET_CHANGE_FORM_SUCCESS_SETUP =
  'cds/update-report/SET_CHANGE_FORM_SUCCESS_SETUP';
const SET_CHANGE_FORM_SUCCESS_COLUMN =
  'cds/update-report/SET_CHANGE_FORM_SUCCESS_COLUMN';
const SET_CHANGE_FORM_SUCCESS_REFRESH =
  'cds/update-report/SET_CHANGE_FORM_SUCCESS_REFRESH';
const SET_CHANGE_FORM_SUCCESS_CUSTOMER =
  'cds/update-report/SET_CHANGE_FORM_SUCCESS_CUSTOMER';
const SET_CHANGE_FORM_SUCCESS_COMMUNICATION =
  'cds/update-report/SET_CHANGE_FORM_SUCCESS_COMMUNICATION';
const SET_CHANGE_FORM_SUCCESS_SERVER =
  'cds/update-report/SET_CHANGE_FORM_SUCCESS_SERVER';
const initialState = {
  newTab0: {
    currentStep: 0,
    viewedSample: false,
    customerDropdownData: [],
    currentTabPlants: [],
    currentTabInputSource: [],
    customerDetailsdata: [],
    customerColumndata: [],
    customerColumndataCount: 0,
    reportFrequncy: [],
    reportSetupData: {},
    reportColumnData: {},
    reportRefreshData: {},
    reportCustomersData: {},
    reportCommunicationData: {},
    reportCustomersDataIndex: [],
    reportStatus: true,
    reportDrop: 'N',
    reportCrNo: '',
    reportChangeSetup: false,
    reportChangeColumn: false,
    reportChangeRefresh: false,
    reportChangeCustomer: false,
    reportChangeCommunication: false,
    reportChangeServer: false,
    loading: false,
    gotSpecific: false,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case NEW_REPORT:
      return { ...state, loading: true };
    case GET_CURRENTPLANTS_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          currentTabPlants: action.payload,
          error: null,
        },
      };
    case GET_CURRENTINPUTSOURCE_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          currentTabInputSource: action.payload,
          error: null,
        },
      };
    case SET_CURRENTSTEPS_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          currentStep: action.payload,
          error: null,
        },
      };
    case SET_CHANGE_FORM_SUCCESS_SETUP:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          reportChangeSetup: action.payload,
          error: null,
        },
      };
    case SET_CHANGE_FORM_SUCCESS_COLUMN:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          reportChangeColumn: action.payload,
          error: null,
        },
      };
    case SET_CHANGE_FORM_SUCCESS_REFRESH:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          reportChangeRefresh: action.payload,
          error: null,
        },
      };
    case SET_CHANGE_FORM_SUCCESS_CUSTOMER:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          reportChangeCustomer: action.payload,
          error: null,
        },
      };
    case SET_CHANGE_FORM_SUCCESS_COMMUNICATION:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          reportChangeCommunication: action.payload,
          error: null,
        },
      };
    case SET_CHANGE_FORM_SUCCESS_SERVER:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          reportChangeServer: action.payload,
          error: null,
        },
      };
    case SET_REPORTSTATUS_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          reportStatus: action.payload,
          error: null,
        },
      };
    case CREATE_REPORT_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
        },
      };
    case CREATE_REPORT_FAIL:
    case GET_CURRENTPLANTS_FAIL:
    case GET_CUSTOMERCODE_FAIL:
    case GET_CUSTOMERCODE_FAIL:
    case VIEW_COLUMNS_SAMPLE_FAIL:
    case CDS_CUSTOMER_VALIDATE_FAIL:
    case CDS_REFRESH_VALIDATE_FAIL:
    case VIEW_SPECIFIC_REPORT_FAIL:
    case VIEW_COLUMNS_FAIL:
    case GET_CURRENTPLANTS_FAIL:
    case GET_CURRENTINPUTSOURCE_FAIL:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          error: action.payload,
        },
      };
    case GET_CUSTOMERCODE_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          customerDropdownData: action.payload,
          error: null,
        },
      };
    case CDS_CUSTOMER_ADD_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          customerDetailsdata: action.payload,
          error: null,
        },
      };
    case CDS_COLUMN_ADD_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          reportSetupData: {
            ...state[action.tabKey].reportSetupData,
            ColumnSelection: action.payload,
          },
          customerColumndataCount: action.count,
          error: null,
        },
      };
    case REPORT_FREQUENCY_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          reportFrequncy: action.payload,
          error: null,
        },
      };
    case VIEW_COLUMNS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: true,
          error: null,
        },
      };
    case VIEW_COLUMNS_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          reportSetupData: action.payload,
          error: null,
          viewedSample: false,
        },
      };
    case VIEW_COLUMNS_SAMPLE_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          viewedSample: true,
          error: null,
        },
      };
    case REPORT_REFRESH_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          reportRefreshData: action.payload,
          error: null,
        },
      };
    case CDS_REFRESH_VALIDATE_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          reportRefreshData: {
            ...state[action.tabKey].reportRefreshData,
            validation: action.payload,
          },
          error: null,
        },
      };
    case CDS_CUSTOMER_VALIDATE_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          reportCustomersData: {
            ...state[action.tabKey].reportCustomersData,
            validation: action.payload,
          },
          error: null,
        },
      };
    case REPORT_CUSTOMERS_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          reportCustomersData: action.payload,
          error: null,
        },
      };
    case REPORT_CUSTOMERS_INDEX_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          reportCustomersDataIndex: action.payload,
          error: null,
        },
      };
    case REPORT_COMMUNICATION_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          reportCommunicationData: action.payload,
          error: null,
        },
      };
    case REPORT_COLUMNS_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          reportColumnData: action.payload,
          reportSetupData: {
            ...state[action.tabKey].reportSetupData,
            ColumnSelection: action.dataLoad,
          },
          error: null,
        },
      };
    case REPORT_SUBMIT_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          reportDrop: action.payloadDrop,
          reportCrNo: action.payloadCrNo,
          error: null,
        },
      };
    case VIEW_SPECIFIC_REPORT_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          customerDetailsdata: action.customerDetailsdata,
          reportSetupData: action.reportSetupData,
          reportColumnData: action.reportColumnData,
          reportFrequncy: action.reportFrequncy,
          reportRefreshData: action.reportRefreshData,
          reportCustomersData: action.reportCustomersData,
          reportCommunicationData: action.reportCommunicationData,
          gotSpecific: action.gotSpecific,
          reportDrop: action.reportDrop,
          reportStatus: action.reportStatus,
          customerColumndataCount: action.customerColumndataCount,
          error: null,
        },
      };
    default:
      return state;
  }
}

export function addCurrentTabPlants(currentTabPlants, targetKey) {
  return (dispatch) => {
    if (currentTabPlants) {
      dispatch({
        type: GET_CURRENTPLANTS_SUCCESS,
        tabKey: targetKey,
        payload: currentTabPlants,
      });
    } else {
      dispatch({
        type: GET_CURRENTPLANTS_FAIL,
        tabKey: targetKey,
        payload: 'Something went wrong!',
      });
    }
  };
}
export function addCurrentTabInputSource(currentTabInputSource, targetKey) {
  return (dispatch) => {
    if (currentTabInputSource) {
      dispatch({
        type: GET_CURRENTINPUTSOURCE_SUCCESS,
        tabKey: targetKey,
        payload: currentTabInputSource,
      });
    } else {
      dispatch({
        type: GET_CURRENTINPUTSOURCE_FAIL,
        tabKey: targetKey,
        payload: 'Something went wrong!',
      });
    }
  };
}
export function setCurrentSteps(currentStep, targetKey) {
  return (dispatch) => {
    if (currentStep >= 0) {
      dispatch({
        type: SET_CURRENTSTEPS_SUCCESS,
        tabKey: targetKey,
        payload: currentStep,
      });
    }
  };
}
export function setFormChangeSetup(status, targetKey) {
  return (dispatch, _getState) => {
    if (status) {
      dispatch({
        type: SET_CHANGE_FORM_SUCCESS_SETUP,
        tabKey: targetKey,
        payload: status,
      });
    }
  };
}
export function setFormChangeColumn(status, targetKey) {
  return (dispatch, _getState) => {
    if (status) {
      dispatch({
        type: SET_CHANGE_FORM_SUCCESS_COLUMN,
        tabKey: targetKey,
        payload: status,
      });
    }
  };
}
export function setFormChangeRefresh(status, targetKey) {
  return (dispatch, _getState) => {
    if (status) {
      dispatch({
        type: SET_CHANGE_FORM_SUCCESS_REFRESH,
        tabKey: targetKey,
        payload: status,
      });
    }
  };
}
export function setFormChangeCustomer(status, targetKey) {
  return (dispatch, _getState) => {
    if (status) {
      dispatch({
        type: SET_CHANGE_FORM_SUCCESS_CUSTOMER,
        tabKey: targetKey,
        payload: status,
      });
    }
  };
}
export function setFormChangeCommunication(status, targetKey) {
  return (dispatch, _getState) => {
    if (status) {
      dispatch({
        type: SET_CHANGE_FORM_SUCCESS_COMMUNICATION,
        tabKey: targetKey,
        payload: status,
      });
    }
  };
}
export function setFormChangeServer(status, targetKey) {
  return (dispatch, _getState) => {
    if (status) {
      dispatch({
        type: SET_CHANGE_FORM_SUCCESS_SERVER,
        tabKey: targetKey,
        payload: status,
      });
    }
  };
}
export function setReportStatus(reportStatus, targetKey) {
  return (dispatch) => {
    dispatch({
      type: SET_REPORTSTATUS_SUCCESS,
      tabKey: targetKey,
      payload: reportStatus,
    });
  };
}

export function customerDetailsAdd(formattedValues, targetKey) {
  return (dispatch) => {
    dispatch({
      type: CDS_CUSTOMER_ADD_SUCCESS,
      tabKey: targetKey,
      payload: formattedValues,
    });
  };
}

export function customerColumnAdd(formattedValues, getCount, targetKey) {
  return (dispatch) => {
    dispatch({
      type: CDS_COLUMN_ADD_SUCCESS,
      tabKey: targetKey,
      payload: formattedValues,
      count: getCount,
    });
  };
}

export function reportColumnAdd(formattedValues, targetKey) {
  return (dispatch) => {
    dispatch({
      type: REPORT_COLUMNS_SUCCESS,
      tabKey: targetKey,
      payload: formattedValues,
      dataLoad: formattedValues.columnDetailsStore,
    });
  };
}

export function reportSubmitUpdate(formattedValues, targetKey) {
  return (dispatch, _getState) => {
    dispatch({
      type: REPORT_SUBMIT_SUCCESS,
      tabKey: targetKey,
      payloadDrop: formattedValues.reportDrop,
      payloadCrNo: formattedValues.crNo,
    });
  };
}

export function reportFrequencyAdd(formattedValues, targetKey) {
  return (dispatch) => {
    dispatch({
      type: REPORT_FREQUENCY_SUCCESS,
      tabKey: targetKey,
      payload: formattedValues,
    });
  };
}

export function reportRefreshAdd(formattedValues, targetKey) {
  return (dispatch) => {
    dispatch({
      type: REPORT_REFRESH_SUCCESS,
      tabKey: targetKey,
      payload: formattedValues,
    });
  };
}

export function reportSetupAdd(formattedValues, targetKey) {
  return (dispatch) => {
    let storeReportSetup = {
      CustomerDetails: formattedValues.CustomerDetails,
      ColumnSelection: formattedValues.ColumnSelection,
      oldReportName: formattedValues.oldReportName,
      reportDrop: formattedValues.reportDrop,
      reportId: formattedValues.reportId,
      reportSetup: 'submitted',
      reportStatus: formattedValues.reportStatus,
      schemaName: formattedValues.schemaName,
      source: formattedValues.source,
      viewName: formattedValues.viewName,
      confirm: formattedValues.confirm,
    };
    dispatch({
      type: VIEW_COLUMNS_SUCCESS,
      tabKey: targetKey,
      payload: storeReportSetup,
    });
  };
}

export function reportCustomersAdd(formattedValues, targetKey) {
  return (dispatch) => {
    dispatch({
      type: REPORT_CUSTOMERS_SUCCESS,
      tabKey: targetKey,
      payload: formattedValues,
    });
  };
}
export function reportCustomersDataIndexAdd(formattedValues, targetKey) {
  return (dispatch) => {
    dispatch({
      type: REPORT_CUSTOMERS_INDEX_SUCCESS,
      tabKey: targetKey,
      payload: formattedValues,
    });
  };
}
export function reportCommunicationAdd(formattedValues, targetKey) {
  return (dispatch) => {
    dispatch({
      type: REPORT_COMMUNICATION_SUCCESS,
      tabKey: targetKey,
      payload: formattedValues,
    });
  };
}

export function getCustomerCodePlant(targetKey) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({
      type: VIEW_COLUMNS,
      tabKey: targetKey,
      payload: '',
    });
    return api
      .post(
        'cds',
        formatParams(
          {
            type: 'CDS_CUSTOMER_CODE_PLANT',
            details: {
              email: Cookies.get('email'),
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data.statusCode == 200) {
          if (data.returnMsg == 'SUCCESS') {
            dispatch({
              type: GET_CUSTOMERCODE_SUCCESS,
              tabKey: targetKey,
              payload: data.result,
            });
          } else {
            dispatch({
              type: GET_CUSTOMERCODE_FAIL,
              tabKey: targetKey,
              payload: data.returnMsg,
            });
          }
        }

        return data;
      });
  };
}

const getCustomerDetails = (result) => {
  let data = [];
  if (
    result.ReportFormArray[0].ReportSetup &&
    result.ReportFormArray[0].ReportSetup.CustomerDetails
  ) {
    data = result.ReportFormArray[0].ReportSetup.CustomerDetails;
  }
  return data;
};

const getReportSetupData = (result) => {
  return {
    CustomerDetails: result.ReportFormArray[0].ReportSetup.CustomerDetails
      ? result.ReportFormArray[0].ReportSetup.CustomerDetails
      : [],
    source: result.ReportFormArray[0].ReportSetup.source
      ? result.ReportFormArray[0].ReportSetup.source
      : '',
    viewName: result.ReportFormArray[0].ReportSetup.view_name
      ? result.ReportFormArray[0].ReportSetup.view_name
      : '',
    schemaName: result.ReportFormArray[0].ReportSetup.schema_name
      ? result.ReportFormArray[0].ReportSetup.schema_name
      : '',
    reportSetup: 'submitted',
    reportDrop: result.ReportFormArray[0].ReportSetup.report_drop
      ? result.ReportFormArray[0].ReportSetup.report_drop
      : '',
    reportStatus: result.ReportFormArray[0].ReportSetup.report_status
      ? result.ReportFormArray[0].ReportSetup.report_status
      : '',
    ColumnSelection: result.ReportFormArray[0].ColumnSelection
      ? result.ReportFormArray[0].ColumnSelection
      : [],
    reportId: result.reportId ? result.reportId : '',
    oldReportName: result.ReportFormArray[0].ReportSetup.old_report_name || '',
  };
};

const getReportName = (data) => {
  let value = '';
  if (data.new_report_name) {
    value = data.new_report_name;
  } else if (data.old_report_name) {
    value = data.old_report_name;
  }

  return value;
};

const getReportColumnData = (result) => {
  return {
    reportColumns: 'submitted',
    columnDetails: result.ReportFormArray[0].ColumnSelection
      ? result.ReportFormArray[0].ColumnSelection
      : [],
    RefreshDetails: result.ReportFormArray[0].ColumnSelection
      ? result.ReportFormArray[0].ColumnSelection
      : [],
    reportName: getReportName(result.ReportFormArray[0].ReportSetup),
  };
};

const getReportFrequencyData = (result) => {
  return result.ReportFormArray[0].RefreshDetails &&
    result.ReportFormArray[0].RefreshDetails.send_frequency != undefined &&
    typeof result.ReportFormArray[0].RefreshDetails.send_frequency === 'string'
    ? result.ReportFormArray[0].RefreshDetails.send_frequency.split(',')
    : [];
};

const getReportRefreshData = (result) => {
  return {
    reportRefresh: 'submitted',
    refreshDetails: result.ReportFormArray[0].RefreshDetails
      ? result.ReportFormArray[0].RefreshDetails
      : [],
    sendFrequency:
      result.ReportFormArray[0].RefreshDetails &&
      typeof result.ReportFormArray[0].RefreshDetails.send_frequency ===
        'string'
        ? result.ReportFormArray[0].RefreshDetails.send_frequency.split(',')
        : [],
  };
};

const getReportCustomersData = (result) => {
  return {
    reportCustomers: 'submitted',
    customersDetails: result.ReportFormArray[0].CustomerServerDetails
      ? result.ReportFormArray[0].CustomerServerDetails
      : [],
  };
};

const getReportCommunicationData = (result) => {
  return {
    reportCommunication: 'submitted',
    communicationDetails: result.ReportFormArray[0].Communication
      ? result.ReportFormArray[0].Communication
      : [],
  };
};

export function getReportDetails(reportId, targetKey) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({
      type: VIEW_COLUMNS,
      tabKey: targetKey,
      payload: '',
    });
    return api
      .post(
        'cds',
        formatParams(
          {
            type: 'VIEW_SPECIFIC_REPORT',
            details: {
              email: Cookies.get('email'),
              report_id: reportId,
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data.statusCode == 200 && data.returnMsg == 'SUCCESS') {
          dispatch({
            type: VIEW_SPECIFIC_REPORT_SUCCESS,
            tabKey: targetKey,
            customerDetailsdata: getCustomerDetails(data.result),
            reportSetupData: getReportSetupData(data.result),
            reportColumnData: getReportColumnData(data.result),
            reportFrequncy: getReportFrequencyData(data.result),
            reportRefreshData: getReportRefreshData(data.result),
            reportCustomersData: getReportCustomersData(data.result),
            reportCommunicationData: getReportCommunicationData(data.result),
            gotSpecific: true,
            reportDrop: 'N',
            reportStatus:
              data.result.ReportFormArray[0].ReportSetup.report_status,
            customerColumndataCount: data.result.ReportFormArray[0]
              .ColumnSelection
              ? data.result.ReportFormArray[0].ColumnSelection.length
              : 0,
          });
        } else {
          dispatch({
            type: VIEW_SPECIFIC_REPORT_FAIL,
            tabKey: targetKey,
            payload: data.returnMsg,
          });
        }

        return data;
      });
  };
}

export function getValidateRefresh(formattedValues, targetKey) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({
      type: VIEW_COLUMNS,
      tabKey: targetKey,
      payload: '',
    });
    return api
      .post(
        'cds',
        formatParams(
          {
            type: 'REFRESH_SFTP_VIEW_MANUALLY',
            uuid: getUUID(),
            details: formattedValues,
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data.statusCode == 200) {
          if (data.returnMsg == 'SUCCESS') {
            if (
              data &&
              data.result &&
              data.result.message &&
              data.result.message == 'SUCCESS'
            ) {
              dispatch({
                type: CDS_REFRESH_VALIDATE_SUCCESS,
                tabKey: targetKey,
                payload: true,
              });
            } else {
              dispatch({
                type: CDS_REFRESH_VALIDATE_FAIL,
                tabKey: targetKey,
                payload: false,
              });
            }
          } else {
            dispatch({
              type: CDS_REFRESH_VALIDATE_FAIL,
              tabKey: targetKey,
              payload: false,
            });
          }
        } else {
          dispatch({
            type: CDS_REFRESH_VALIDATE_FAIL,
            tabKey: targetKey,
            payload: false,
          });
        }
        return data;
      });
  };
}

export function getValidateCustomers(formattedValues, targetKey) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({
      type: VIEW_COLUMNS,
      tabKey: targetKey,
      payload: '',
    });
    return api
      .post(
        'cds',
        formatParams(
          {
            type: 'CDS_SEND_REPORTS',
            uuid: getUUID(),
            details: formattedValues,
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data.statusCode == 200) {
          if (data.returnMsg == 'SUCCESS') {
            if (
              data &&
              data.result &&
              data.result.message &&
              data.result.message == 'SUCCESS'
            ) {
              dispatch({
                type: CDS_CUSTOMER_VALIDATE_SUCCESS,
                tabKey: targetKey,
                payload: true,
              });
            } else {
              dispatch({
                type: CDS_CUSTOMER_VALIDATE_FAIL,
                tabKey: targetKey,
                payload: false,
              });
            }
          } else {
            dispatch({
              type: CDS_CUSTOMER_VALIDATE_FAIL,
              tabKey: targetKey,
              payload: false,
            });
          }
        } else {
          dispatch({
            type: CDS_CUSTOMER_VALIDATE_FAIL,
            tabKey: targetKey,
            payload: false,
          });
        }
        return data;
      });
  };
}

export function getViewColumns(reportDetails, targetKey) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({
      type: VIEW_COLUMNS,
      tabKey: targetKey,
      payload: '',
    });
    return api
      .post(
        'cds',
        formatParams(
          {
            type: 'CDS_VIEW_COLUMNS',
            uuid: getUUID(),
            details: {
              email: Cookies.get('email'),
              source: reportDetails.source,
              viewName: reportDetails.viewName,
              confirm: reportDetails.confirm,
              schema_name: reportDetails.schemaName,
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data.statusCode == 200) {
          if (data.returnMsg === 'SUCCESS') {
            let storeReportSetup = {
              CustomerDetails: reportDetails.CustomerDetails,
              source: reportDetails.source,
              viewName: reportDetails.viewName,
              schemaName: reportDetails.schemaName,
              confirm: reportDetails.confirm,
              oldReportName: data.result.reportName
                ? data.result.reportName
                : '',
              reportSetup: 'submitted',
              ColumnSelection:
                data.result.ColumnSelection !== undefined
                  ? data.result.ColumnSelection
                  : [],
            };
            dispatch({
              type: VIEW_COLUMNS_SUCCESS,
              tabKey: targetKey,
              payload: storeReportSetup,
            });
          } else {
            dispatch({
              type: VIEW_COLUMNS_FAIL,
              tabKey: targetKey,
              payload: data.returnMsg,
            });
          }
        } else {
          dispatch({
            type: VIEW_COLUMNS_FAIL,
            tabKey: targetKey,
            payload: data.returnMsg,
          });
        }
        return data;
      });
  };
}
export function getSampleDataViewColumns(targetKey) {
  return (dispatch) => {
    dispatch({
      type: VIEW_COLUMNS_SAMPLE_SUCCESS,
      tabKey: targetKey,
      payload: 'SUCCESS',
    });
    return true;
  };
}
export function reportSubmit(reportSubmitDetails, targetKey) {
  return (dispatch, _getState, { api }) => {
    dispatch({
      type: VIEW_COLUMNS,
      tabKey: targetKey,
      payload: '',
    });
    return api
      .post('cdssubmitaction', {
        TXN: {
          Header: {
            txn_type: 'UPDATE_REPORT',
            uuid: getUUID(),
            requestor: Cookies.get('email'),
            source: 'PORTAL',
            excel: 'NO',
          },
          ReportFormArray: reportSubmitDetails,
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.TXN && data.TXN[0].return_code == '0') {
          dispatch({
            type: CREATE_REPORT_SUCCESS,
            tabKey: targetKey,
            payload: 'Success!',
          });
        } else {
          dispatch({
            type: CREATE_REPORT_FAIL,
            tabKey: targetKey,
            payload: 'Fail!',
          });
        }
        return data;
      });
  };
}
