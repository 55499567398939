import React from 'react';
import { authHeader } from '../helpers/utils';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import store from '../redux/store';
import { resetInitAuthorization } from '../modules/layout/redux/modules/authorization';
import Cookies from 'js-cookie';

class InterceptHandler {
  constructor() {
    this.configs = {};
  }

  handleRequest(config) {
    config.headers = authHeader();
    return config;
  }

  modalConfirm(data) {
    return Modal.confirm({
      title: data.title,
      icon: <ExclamationCircleOutlined />,
      content: data.message,
      mask: true,
      cancelButtonProps: { style: { display: 'none' } },
    });
  }

  handleResponse(response, sessionExpireResCountCb) {
    const { data } = response;
    const { statusCode, result } = data;
    if (statusCode != undefined && statusCode) {
      if (statusCode == 401 && sessionExpireResCountCb() === 1) {
        const modal = this.modalConfirm(result);
        setTimeout(() => {
          modal.destroy();
          store.dispatch(resetInitAuthorization());
          Cookies.set('continueSession', false, { expires: 365 });
          Cookies.set('sessionExpired', true, { expires: 365 });
          window.location.reload();
        }, 1000);
      } else if (statusCode == 500) {
        this.modalConfirm(result);
      }
    }
    return response;
  }

  handleRequestError(response) {
    return response;
  }

  handleResponseError(response) {
    return response;
  }
}

const ServiceInterceptor = new InterceptHandler();

export default ServiceInterceptor;
