import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changePasswd } from './redux/modules/profile';
import { Form, Input, Button, Row, Col } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const ChangePasswd = (_props) => {
  const [confirmDirty, setConfirmDirty] = useState(false);
  const [form] = Form.useForm();
  const passwdChanged = useSelector(
    ({ profile: { user } }) => user.passwdChanged
  );
  const passwdChangeStatus = useSelector(
    ({ profile: { user } }) => user.passwdChangeStatus
  );
  const passwdChangeReq = useSelector(
    ({ profile: { user } }) => user.passwdChangeReq
  );
  const dispatch = useDispatch();

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      dispatch(changePasswd(values)).then((response) => {
        if (response.data.returnMsg === 'SUCCESS') {
          return form.resetFields();
        }
      });
    });
  };

  const handleConfirmBlur = (e) => {
    const { value } = e.target;
    setConfirmDirty(confirmDirty || !!value);
  };

  const resetForm = (_e) => {
    form.resetFields();
    setConfirmDirty(false);
  };

  useEffect(() => {
    if (passwdChanged) {
      resetForm();
    }
  }, [passwdChanged]);

  return (
    <>
      <p style={{ color: '#69c0ff' }}>
        {passwdChangeStatus !== null && passwdChangeStatus}
      </p>
      <Form
        form={form}
        onFinish={handleSubmit}
        name='changePasswd'
        className='login-form'>
        <Form.Item
          hasFeedback
          name='old_passwd'
          rules={[
            {
              required: true,
              message: 'Please input your old password!',
            },
          ]}>
          <Input.Password placeholder='Enter your old password' />
        </Form.Item>
        <Form.Item
          hasFeedback
          name='new_passwd'
          rules={[
            {
              required: true,
              message: 'Please input your new password!',
            },
          ]}>
          <Input.Password placeholder='Enter new password' />
        </Form.Item>
        <Form.Item
          hasFeedback
          dependencies={['new_passwd']}
          rules={[
            {
              required: true,
              message: 'Please confirm your new password!',
            },
            ({ getFieldValue }) => ({
              validator(_rule, value) {
                if (!value || getFieldValue('new_passwd') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  'The two passwords that you entered do not match!'
                );
              },
            }),
          ]}
          name='confirm_passwd'>
          <Input.Password
            placeholder='Confirm new password'
            onBlur={handleConfirmBlur}
          />
        </Form.Item>
        <Form.Item>
          <Row>
            <Col span={14}></Col>
            <Col span={5}>
              <Button
                type='default'
                block='true'
                htmlType='button'
                onClick={() => resetForm()}>
                Reset
              </Button>
            </Col>
            <Col span={5}>
              <Button
                type='primary'
                htmlType='submit'
                block='true'
                style={{ marginLeft: 8 }}>
                Submit {passwdChangeReq && <LoadingOutlined />}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  );
};

export default ChangePasswd;
