import Cookie from 'js-cookie';
import {
  getUUID,
  gaPageviewTimingTitle,
  getEpochTime,
} from '../../../../helpers/utils';
import { message } from 'antd';

export const GET_EDI_REQUEST_NO = 'ssp/edi-selection-form/GET_EDI_REQUEST_NO';
export const GET_EDI_REQUEST_NO_SUCCESS =
  'ssp/edi-selection-form/GET_EDI_REQUEST_NO_SUCCESS';
export const GET_EDI_REQUEST_NO_FAIL =
  'ssp/edi-selection-form/GET_EDI_REQUEST_NO_FAIL';

export const GET_EDI_DOCUMENT_TYPE =
  'ssp/edi-selection-form/GET_EDI_DOCUMENT_TYPE';
export const GET_EDI_DOCUMENT_TYPE_SUCCESS =
  'ssp/edi-selection-form/GET_EDI_DOCUMENT_TYPE_SUCCESS';
export const GET_EDI_DOCUMENT_TYPE_FAIL =
  'ssp/edi-selection-form/GET_EDI_DOCUMENT_TYPE_FAIL';

export const GET_EDI_SEGMENTS_DATA =
  'ssp/edi-selection-form/GET_EDI_SEGMENTS_DATA';
export const GET_EDI_SEGMENTS_DATA_SUCCESS =
  'ssp/edi-selection-form/GET_EDI_SEGMENTS_DATA_SUCCESS';
export const GET_EDI_SEGMENTS_DATA_FAIL =
  'ssp/edi-selection-form/GET_EDI_SEGMENTS_DATA_FAIL';

export const RESET_SEGMENTS = 'ssp/edi-selection-form/RESET_SEGMENTS';
export const RESET_DOC_TYPE = 'ssp/edi-selection-form/RESET_DOC_TYPE';

export const DESTROY_SEGMENTS_BY_TAB_KEY =
  'ssp/edi-selection-form/DESTROY_SEGMENTS_BY_TAB_KEY';
export const UPDATE_EDI_SEGMENTS = 'ssp/edi-selection-form/UPDATE_EDI_SEGMENTS';

export const SUBMIT_SEGMENT_SETUPS =
  'ssp/edi-selection-form/SUBMIT_SEGMENT_SETUPS';
export const SUBMIT_SEGMENT_SETUPS_SUCCESS =
  'ssp/edi-selection-form/SUBMIT_SEGMENT_SETUPS_SUCCESS';
export const SUBMIT_SEGMENT_SETUPS_FAIL =
  'ssp/edi-selection-form/SUBMIT_SEGMENT_SETUPS_FAIL';

const initialState = {
  newTab0: {
    ediRequestNoLoading: false,
    ediRequestNo: [],
    ediDocTypeLoading: false,
    ediDocType: [],
    ediSegmentsDataLoading: false,
    ediSegmentsData: [],
  },
};

const getCheckedKeys = (segments, callback) => {
  segments.map((segment) => {
    if (segment.children && segment.children.length) {
      if (segment.checked && segment.disabled) {
        callback(segment.key);
      }
      getCheckedKeys(segment.children, callback);
    } else {
      if (segment.checked) {
        callback(segment.key);
      }
    }
  });
};

const getMandatoryKeys = (segments, callback) => {
  segments.map((segment) => {
    if (segment.children && segment.children.length) {
      if (segment.mandatory) {
        callback(segment.key);
      }
      getMandatoryKeys(segment.children, callback);
    } else {
      if (segment.mandatory) {
        callback(segment.key);
      }
    }
  });
};

const resetSegments = () => ({
  leftSegments: [],
  rightSegments: [],
  checkedKeysLeft: [],
  checkedKeysRight: [],
  mandatoryKeysLeft: [],
  mandatoryKeysRight: [],
});

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_EDI_REQUEST_NO:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          ediRequestNoLoading: true,
        },
      };
    case GET_EDI_REQUEST_NO_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          ediRequestNoLoading: false,
          ediRequestNoLoaded: true,
          ediRequestNo: action.payload,
        },
      };
    case GET_EDI_REQUEST_NO_FAIL:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          ediRequestNoLoading: false,
          ediRequestNoLoaded: false,
        },
      };
    case GET_EDI_DOCUMENT_TYPE:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          ediDocTypeLoading: true,
          ediDocType: [],
          ediSubmitParams: {},
        },
      };
    case GET_EDI_DOCUMENT_TYPE_SUCCESS:
      const { docsList, ...rest } = action.payload;
      let docsPayload = docsList;
      const index = docsPayload.indexOf('850');
      if (index !== -1) {
        docsPayload.splice(index, 1);
      }
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          ediDocTypeLoading: false,
          ediDocTypeLoaded: true,
          ediDocType: docsPayload,
          ediSubmitParams: { ...rest },
        },
      };
    case GET_EDI_DOCUMENT_TYPE_FAIL:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          ediDocTypeLoading: false,
          ediDocTypeLoaded: false,
          ediDocType: [],
          ediSubmitParams: {},
          leftSegments: [],
          rightSegments: [],
        },
      };
    case RESET_DOC_TYPE:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          ediDocType: [],
          ediSubmitParams: {},
          ...resetSegments(),
          ediSegmentsDataLoaded: false,
        },
      };
    case GET_EDI_SEGMENTS_DATA:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          ediSegmentsDataLoading: true,
          ...resetSegments(),
        },
      };
    case GET_EDI_SEGMENTS_DATA_SUCCESS:
      const leftSegments = action.payload.slice(0, action.payload.length / 2);
      const rightSegments = action.payload.slice(action.payload.length / 2);

      let checkedKeysLeft = [];
      let checkedKeysRight = [];
      getCheckedKeys(leftSegments, (key) => checkedKeysLeft.push(key));
      getCheckedKeys(rightSegments, (key) => checkedKeysRight.push(key));

      let mandatoryKeysLeft = [];
      let mandatoryKeysRight = [];
      getMandatoryKeys(leftSegments, (key) => mandatoryKeysLeft.push(key));
      getMandatoryKeys(rightSegments, (key) => mandatoryKeysRight.push(key));

      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          ediSegmentsDataLoading: false,
          ediSegmentsDataLoaded: true,
          leftSegments,
          rightSegments,
          checkedKeysLeft,
          checkedKeysRight,
          mandatoryKeysLeft,
          mandatoryKeysRight,
        },
      };
    case RESET_SEGMENTS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          ediSegmentsDataLoading: false,
          ...resetSegments(),
        },
      };
    case DESTROY_SEGMENTS_BY_TAB_KEY:
      delete state[action.tabKey];
      return state;
    case UPDATE_EDI_SEGMENTS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          ...action.payload,
        },
      };
    case SUBMIT_SEGMENT_SETUPS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          isSubmittingSegments: true,
        },
      };
    case SUBMIT_SEGMENT_SETUPS_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          isSubmittingSegments: false,
        },
      };
    case SUBMIT_SEGMENT_SETUPS_FAIL:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          isSubmittingSegments: false,
          submitErrors: 'Something went wrong. Please try again.',
        },
      };
    default:
      return state;
  }
}

function getEDIRequestNo(tabKey) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: GET_EDI_REQUEST_NO, tabKey });
    return api
      .post(
        'ssp',
        formatParams(
          {
            type: 'EDI_SEGMENTS_REQUEST_NUM_LIST',
            details: {
              email: Cookie.get('email'),
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data) {
          dispatch({
            type: GET_EDI_REQUEST_NO_SUCCESS,
            payload: data.result,
            tabKey,
          });
        } else {
          dispatch({
            type: GET_EDI_REQUEST_NO_FAIL,
            payload: 'Error in loading Open View!',
            tabKey,
          });
        }
      });
  };
}

function getEDIDocumentType(b2b_req_no, tabKey) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: GET_EDI_DOCUMENT_TYPE, tabKey });
    return api
      .post(
        'ssp',
        formatParams(
          {
            type: 'EDI_SEGMENTS_DOCS_LIST',
            details: {
              email: Cookie.get('email'),
              b2b_req_no: b2b_req_no,
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data && data.statusCode == 200) {
          dispatch({
            type: GET_EDI_DOCUMENT_TYPE_SUCCESS,
            payload: data.result,
            tabKey,
          });
        } else {
          dispatch({
            type: GET_EDI_DOCUMENT_TYPE_FAIL,
            payload: 'Error in loading Open View!',
            tabKey,
          });
        }
      });
  };
}

function getEDISegmentsData(b2b_req_no, doc_type, tabKey) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: GET_EDI_SEGMENTS_DATA, tabKey });
    const currentTime = getEpochTime();
    return api
      .post(
        'ssp',
        formatParams(
          {
            type: 'GET_EDI_SEGMENTS_DATA',
            details: {
              email: Cookie.get('email'),
              b2b_req_no: b2b_req_no,
              doc_type: doc_type,
            },
          },
          getState
        )
      )
      .then((response) => {
        gaPageviewTimingTitle(`/SSP/getEDISegmentsData`, currentTime);
        const { data } = response;
        if (data && data.statusCode == 200) {
          dispatch({
            type: GET_EDI_SEGMENTS_DATA_SUCCESS,
            tabKey,
            payload: data.result,
          });
        } else {
          message.error('Error in loading segments!');
          dispatch({
            type: GET_EDI_SEGMENTS_DATA_FAIL,
            payload: 'Error in loading segments!',
            tabKey,
          });
        }
      });
  };
}

const destroySegmentByTabKey = (tabKey) => {
  return (dispatch) => dispatch({ type: DESTROY_SEGMENTS_BY_TAB_KEY, tabKey });
};

const updateEdiSegments = (segments, tabKey) => {
  return (dispatch) =>
    dispatch({ type: UPDATE_EDI_SEGMENTS, tabKey, payload: segments });
};

const submitEdiSegments = (segments, params, tabKey) => {
  return (dispatch, _getState, { api }) => {
    dispatch({ type: SUBMIT_SEGMENT_SETUPS, tabKey });
    const currentTime = getEpochTime();
    return api
      .post('sspsubmitaction', {
        TXN: [
          {
            uuid: getUUID(),
            transaction_type: 'SEG_SETUPS',
            ...params,
            created_by: Cookie.get('email'),
            ...segments,
          },
        ],
      })
      .then((response) => {
        gaPageviewTimingTitle(`/SSP/submitEdiSegments`, currentTime);
        const { data } = response;
        if (data) {
          if (data.TXN && data.TXN[0].return_msg == 'SUCCESS') {
            message.success('Segments data saved successfully.');
            dispatch({ type: SUBMIT_SEGMENT_SETUPS_SUCCESS, tabKey });
          } else {
            message.error('Error in saving segments. Please try again!');
            dispatch({ type: SUBMIT_SEGMENT_SETUPS_FAIL, tabKey });
          }
        } else {
          message.error('Error in saving segments. Please try again!');
        }
      });
  };
};

export {
  getEDIRequestNo,
  getEDIDocumentType,
  getEDISegmentsData,
  destroySegmentByTabKey,
  updateEdiSegments,
  submitEdiSegments,
};
