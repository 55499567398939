import React, { createRef } from 'react';
import { Select, Popover } from 'antd';

const { Option } = Select;

class CustomSelectEditor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      validationStatus: 'success',
      errorDesc: '',
      popoverVisibility: false,
    };
    this.inputRef = createRef();
    this.isNodeSelected = props.node.isSelected(); // NOSONAR
    this.nodeDataInitial = props.node.data;
    this.colId = this.props.column.getColId();
  }

  componentDidMount() {
    const errors = this.props.node.data.errors;
    if (errors && errors[this.colId] !== undefined) {
      this.setState({
        validationStatus: 'error',
        errorDesc: this.props.node.data.errors[this.colId],
      });
    }
    this.setState({ value: this.props.value });
  }

  componentDidUpdate(_prevProps, prevState) {
    const errors = this.props.node.data.errors;
    if (errors && errors[this.colId] !== undefined) {
      if (prevState.errorDesc !== this.props.node.data.errors[this.colId]) {
        this.setState({
          validationStatus: 'error',
          errorDesc: this.props.node.data.errors[this.colId],
        });
      }
    }
  }

  // prettier-ignore
  getValue() { // NOSONAR
    return this.props.node.data.isEditing
      ? this.state.value
      : this.nodeDataInitial[this.props.column.getColId()];
  }

  handleChange = (value) => {
    this.setState({ value: value });
    if (!this.props.node.isSelected()) {
      this.props.node.setSelected(true);
      this.props.api.refreshCells({
        force: true,
        columns: ['select', 'sub-check'],
      });
      this.props.api.refreshHeader();
    }
    this.props.node.setDataValue(this.colId, value);
  };

  // prettier-ignore
  focusIn() { // NOSONAR
    this.inputRef.current.focus();
  }

  handlePopoverVisibility = () => {
    if (this.state.errorDesc !== '') {
      this.setState({ popoverVisibility: true });
    }
  };

  getErrorDesc = () => (
    <div style={{ fontSize: 12 }}>{this.state.errorDesc}</div>
  );

  render() {
    return (
      <Popover
        content={() => this.getErrorDesc()}
        visible={this.state.popoverVisibility}
        title={null}
        placement='top'>
        <Select
          showSearch
          style={{ minWidth: 140, maxWidth: 223 }}
          optionFilterProp='children'
          filterOption={(input, option) =>
            option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          ref={this.inputRef}
          value={this.state.value}
          size='small'
          onChange={this.handleChange}
          className={this.state.validationStatus}
          onMouseOver={this.handlePopoverVisibility}
          onMouseOut={() => this.setState({ popoverVisibility: false })}>
          {this.props.values &&
            this.props.values.map((selectValues) => {
              return (
                <Option key={selectValues} value={selectValues}>
                  {selectValues}
                </Option>
              );
            })}
        </Select>
      </Popover>
    );
  }
}

export default CustomSelectEditor;
