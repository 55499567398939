import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tour, Button } from 'antd';
import TourActionButton from '../TourActionButton';
import { destroyTabs } from '../../layout/redux/modules/layout';
import { setWalkthrough } from '../redux/tour';
import Cookie from 'js-cookie';
const Helpwalkacp = (props) => {
  const [open, setOpen] = useState(false);
  const tourReferences = useSelector(({ tour }) => tour.tourReferences);
  useEffect(() => {
    Cookie.set('walkthroughCandidateWalkAdmin', 'Yes');
    Cookie.set('walkthroughCandidateWalkSupplier', 'Yes');
    Cookie.set('walkthroughCandidateWalkRsm', 'Yes');
    Cookie.set('walkthroughProfileWalk', 'Yes');
  }, [tourReferences]);
  const dispatch = useDispatch();
  const openTour = () => {
    props.handleVisiblity();
    dispatch(destroyTabs());
    setOpen(true);
  };
  const closeTour = () => {
    dispatch(setWalkthrough('ACP', 'dashboard', 'Skip'));
    setOpen(false);
  };
  const steps = [
    {
      title: 'Agility Candidate Portal',
      description: (
        <div>
          Welcome to Agility Candidates Portal! We put this short tour together
          to show you some key features. You can dismiss this message and come
          back to it at any time using the Support Link.
          <br />
          <TourActionButton
            type='skip'
            screen='dashboard'
            label='Skip'
            closeTour={closeTour}
          />
        </div>
      ),
      target: null,
    },
    {
      title: 'Agility Candidate Portal',
      description: (
        <div>
          Please click the Candidate Items Menu item to proceed with the next
          tip.
          <br />
          <TourActionButton
            type='skip'
            screen='dashboard'
            label='Skip'
            closeTour={closeTour}
          />
        </div>
      ),
      target: () => tourReferences.candidateItem.current,
    },
    {
      title: 'Agility Candidate Portal',
      description: (
        <div>
          The Support area has documentation for ACP, video help and contact
          information to seek further system help.
          <br />
          <TourActionButton
            type='skip'
            screen='dashboard'
            label='Skip'
            closeTour={closeTour}
          />
        </div>
      ),
      target: () => tourReferences.profileSupportDoc.current,
    },
    {
      title: 'Agility Candidate Portal',
      description: (
        <div>
          Press this Excel Download button to download the latest MS Excel
          template. All the functionality of the portal is available in the
          Excel Download template. You need only to download this template once
          (not every time you wish to see the ACP data).
          <br />
          <TourActionButton
            type='skip'
            screen='dashboard'
            label='Skip'
            closeTour={closeTour}
          />
        </div>
      ),
      target: () => tourReferences.excelDownload.current,
    },
    {
      title: 'Agility Candidate Portal',
      description: (
        <div>
          If you download more than 2000 records using this download button, we
          will perform the download offline and send you a mail when the
          download is ready. The download data will also remain available in the
          Export History Form available by selecting the “Export History” menu
          item in the Left Side Menu.
          <br />
          <TourActionButton
            type='skip'
            screen='dashboard'
            label='Skip'
            closeTour={closeTour}
          />
        </div>
      ),
      target: () => tourReferences.exportHistoryCdsTour.current,
    },
    {
      title: 'Agility Candidate Portal',
      description: (
        <div>
          Use the profile section to change your download text delimiter
          Parameter and to change your password when necessary.
          <br />
          <TourActionButton
            type='skip'
            screen='dashboard'
            label='Skip'
            closeTour={closeTour}
          />
        </div>
      ),
      target: () => tourReferences.profileSpaceTour.current,
    },
    {
      title: 'Agility Candidate Portal',
      description: (
        <div>
          Thanks for taking the tour! If you want to see this tour again, please
          look to the link in the support section.
          <br />
          <TourActionButton
            type='skip'
            screen='dashboard'
            label='Skip'
            closeTour={closeTour}
          />
        </div>
      ),
      target: () => tourReferences.profileSupportDoc.current,
    },
  ];
  return (
    <div>
      <Button type='primary' className='left-walkthrough' onClick={openTour}>
        Take a tour!
      </Button>
      <Tour open={open} onClose={closeTour} steps={steps} />
    </div>
  );
};

export default Helpwalkacp;
