import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tour } from 'antd';
import TourActionButton from './TourActionButton';
import { setWalkthrough } from './redux/tour';
import Cookie from 'js-cookie';
const Tourprofilesanm = (props) => {
  const [open, setOpen] = useState(true);
  const tourReferences = useSelector(({ tour }) => tour.tourReferences);
  const defaultAccess = useSelector(
    ({ authorization: { initAuthorization } }) =>
      initAuthorization.defaultAccess
  );
  useEffect(() => {
    const handleClickCard = (event) => {
      let getContentValue = event.target.innerHTML;
      let getContentLength = getContentValue.length;
      if (
        getContentValue != '' &&
        getContentValue != 'Next' &&
        getContentValue != 'Previous' &&
        getContentLength < 25
      ) {
        Cookie.set('walkthroughProfileWalk', 'No');
        setOpen(false);
      }
    };
    document.addEventListener('click', handleClickCard, true);
  }, [tourReferences]);
  const dispatch = useDispatch();
  const closeTour = () => {
    Cookie.set('walkthroughProfileWalk', 'No');
    dispatch(setWalkthrough(defaultAccess, 'profile', 'Skip'));
    setOpen(false);
  };
  const getTitle = (value) => {
    let setTitle = {
      CDS: 'Customer Data Sharing Portal',
      PC: 'Partner Connect Portal',
    };
    return setTitle[value];
  };
  const steps = [
    {
      title: getTitle(defaultAccess),
      description: (
        <div>
          You can choose your delimiter.
          <br />
          <TourActionButton
            type='skip'
            screen='profile'
            label='Skip'
            closeTour={closeTour}
          />
        </div>
      ),
      target: () => tourReferences.profileDelimiter.current,
    },
    {
      title: getTitle(defaultAccess),
      description: (
        <div>
          Thanks for taking the tour! If you want to see this tour again, please
          look to the link in the support section.
          <br />
          <TourActionButton
            type='skip'
            screen='profile'
            label='Skip'
            closeTour={closeTour}
          />
        </div>
      ),
      target: () => tourReferences.profileSupportDoc.current,
    },
  ];
  return <Tour open={open} onClose={closeTour} steps={steps} />;
};

export default Tourprofilesanm;
