import Cookies from 'js-cookie';

const EXPORT_HISTORY = 'cpt/export-history/EXPORT_HISTORY';
const EXPORT_HISTORY_SUCCESS = 'cpt/export-history/EXPORT_HISTORY_SUCCESS';
const EXPORT_HISTORY_FAIL = 'cpt/export-history/EXPORT_HISTORY_FAIL';

const initialState = {
  data: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case EXPORT_HISTORY:
      return { ...state, loading: true };
    case EXPORT_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case EXPORT_HISTORY_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
}

export function getExportHistory() {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: EXPORT_HISTORY });
    return api
      .post(
        'cpt',
        formatParams(
          {
            type: 'EXPORT_HISTORY',
            email: Cookies.get('email'),
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data && data.statusCode == 200) {
          if (data.responseCode == 1) {
            dispatch({
              type: EXPORT_HISTORY_SUCCESS,
              payload: data.result,
            });
          } else {
            dispatch({
              type: EXPORT_HISTORY_FAIL,
              payload: data.returnMsg,
            });
          }
        }

        return response;
      });
  };
}
