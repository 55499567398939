import { combineReducers } from 'redux';
import dashboard from '../cpt-dashboard/redux/dashboard-redux-ducks';
import cptForm from '../cpt-form/redux/cptform-redux-ducks';
import excelTemplate from '../excel-template/redux/excel-template';
import exportHistory from '../export-history/redux/export-history';

const cptReducer = combineReducers({
  dashboard,
  cptForm,
  excelTemplate,
  exportHistory,
});

export default cptReducer;
