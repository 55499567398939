import React from 'react';
import PropTypes from 'prop-types';
import { List, Avatar, Descriptions } from 'antd';
import { getSymbol } from 'helpers/utils';

const SupportDetail = (props) => {
  const getDescription = (item) => {
    const highlightEmail = (desc) => {
      const createDesc = () => {
        return {
          __html: desc.replace(
            'helpdesk@sanmina.com',
            '<b>helpdesk@sanmina.com</b>'
          ),
        };
      };
      return <div dangerouslySetInnerHTML={createDesc()}></div>;
    };

    const moduleDescription = (
      <List.Item.Meta
        description={highlightEmail(item.description)}
        style={{ marginBottom: 5 }}
      />
    );

    const contacts = (
      <Descriptions size={'small'} layout='horizontal'>
        {item.contacts.map((data) => {
          return (
            <Descriptions.Item key={getSymbol()} label={data.title} span={3}>
              {data.value}
            </Descriptions.Item>
          );
        })}
      </Descriptions>
    );

    return [moduleDescription, contacts];
  };

  return (
    <List
      itemLayout='horizontal'
      dataSource={props.supportDetails}
      loading={{
        spinning: props.loading,
        tip: 'Loading contacts...',
      }}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            avatar={
              <Avatar>
                {item.title.split(' ').map((i) => i.charAt(0).toUpperCase())}
              </Avatar>
            }
            title={item.title}
            description={getDescription(item)}
          />
        </List.Item>
      )}
    />
  );
};

SupportDetail.propTypes = {
  supportDetails: PropTypes.arrayOf(Object).isRequired,
  loading: PropTypes.bool,
};

export default SupportDetail;
