import { gaPageviewTimingTitle, getEpochTime } from '../../../../helpers/utils';
import Cookie from 'js-cookie';
import { getUUID } from 'helpers/utils';

const SSP_REQUEST_PROCEEED = 'ssp/request/store/SSP_REQUEST_PROCEEED';
const SSP_REQUEST_PROCEEED_SUCCESS =
  'ssp/request/store/SSP_REQUEST_PROCEEED_SUCCESS';
const SSP_REQUEST_DETAIL_SUCCESS =
  'ssp/request/store/SSP_REQUEST_DETAIL_SUCCESS';
const SSP_REQUEST_DETAIL_FAIL = 'ssp/request/store/SSP_REQUEST_DETAIL_FAIL';
const SSP_REQUEST_PROCEEED_RESET_SUCCESS =
  'ssp/request/store/SSP_REQUEST_PROCEEED_RESET_SUCCESS';
const SSP_REQUEST_CUSTOMER_PLANT =
  'ssp/requestREQUEST_FORM_DETAIL/store/SSP_REQUEST_CUSTOMER_PLANT';
const SSP_REQUEST_CUSTOMER_PLANT_SUCCESS =
  'ssp/request/store/SSP_REQUEST_CUSTOMER_PLANT_SUCCESS';
const SSP_REQUEST_SUPPLIER_EMAIL_SUCCESS =
  'ssp/request/store/SSP_REQUEST_SUPPLIER_EMAIL_SUCCESS';
const SSP_REQUEST_CUSTOMER_EMAIL_SUCCESS =
  'ssp/request/store/SSP_REQUEST_CUSTOMER_EMAIL_SUCCESS';
const SSP_REQUEST_CUSTOMER_FILE = 'ssp/request/store/SSP_REQUEST_CUSTOMER_FILE';
const SSP_REQUEST_CUSTOMER_FILE_SUCCESS =
  'ssp/request/store/SSP_REQUEST_CUSTOMER_FILE_SUCCESS';
const SSP_REQUEST_SUBMIT_SUCCESS = 'ssp/store/SSP_REQUEST_SUBMIT_SUCCESS';
const SSP_REQUEST_SUBMIT_FAIL = 'ssp/store/SSP_REQUEST_SUBMIT_FAIL';
const SSP_REQUEST_FORM_DROPDOWN_DATA_LOADING =
  'ssp/store/SSP_REQUEST_FORM_DROPDOWN_DATA_LOADING';
const SSP_REQUEST_FORM_DROPDOWN_DATA_SUCCESS =
  'ssp/store/SSP_REQUEST_FORM_DROPDOWN_DATA_SUCCESS';
const SSP_REQUEST_FORM_DROPDOWN_DATA_FAIL =
  'ssp/store/SSP_REQUEST_FORM_DROPDOWN_DATA_FAIL';
const SSP_REQUEST_NO_DATA_SUCCESS = 'ssp/store/SSP_REQUEST_NO_DATA_SUCCESS';
const SSP_REQUEST_NO_DATA_FAIL = 'ssp/store/SSP_REQUEST_NO_DATA_FAIL';
const SSP_REQUEST_DOC_SEND_SUCCESS = 'ssp/store/SSP_REQUEST_DOC_SEND_SUCCESS';
const SSP_REQUEST_CANCEL_DOC_SEND_SUCCESS =
  'ssp/store/SSP_REQUEST_CANCEL_DOC_SEND_SUCCESS';
const SSP_REQUEST_DOC_SEND_FAIL = 'ssp/store/SSP_REQUEST_DOC_SEND_FAIL';
const SSP_STORE_FILE_DETAILS_SUCCESS =
  'ssp/store/SSP_STORE_FILE_DETAILS_SUCCESS';
const SSP_REQUEST_FETCH_DOC_INIT = 'ssp/store/SSP_REQUEST_FETCH_DOC_INIT';
const SSP_REQUEST_FETCH_DOC_SUCCESS = 'ssp/store/SSP_REQUEST_FETCH_DOC_SUCCESS';
const SSP_REQUEST_FETCH_DOC_ERROR = 'ssp/store/SSP_REQUEST_FETCH_DOC_ERROR';

const initialState = {
  isLoading: false,
  requestEntryDataLoaded: false,
  requestEntryDataLoading: false,
  requestEntryData: [],
  requestDetailData: [],
  requestFileDetailsData: [],
  requestDropdownDataLoaded: false,
  requestDropdownDataLoading: false,
  requestDropdownData: [],
  requestNoDataLoading: false,
  requestNoData: [],
  supplierEmailData: [],
  customerEmailData: [],
  customerPlantDataLoading: false,
  customerPlantData: [],
  customerFileLoading: false,
  customerFileData: [],
  requestSubmitMsg: '',
  isDocLoading: false,
  base64StringDisplay: '',
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SSP_REQUEST_PROCEEED:
      return { ...state, isLoading: true };
    case SSP_REQUEST_PROCEEED_SUCCESS:
      return { ...state, requestEntryData: action.data, isLoading: false };
    case SSP_REQUEST_DETAIL_SUCCESS:
      return { ...state, requestDetailData: action.data };
    case SSP_REQUEST_FORM_DROPDOWN_DATA_LOADING:
      return { ...state, requestDropdownDataLoading: true };
    case SSP_REQUEST_FORM_DROPDOWN_DATA_SUCCESS:
      return {
        ...state,
        requestDropdownData: action.payload,
        requestDropdownDataLoading: false,
        requestDropdownDataLoaded: true,
      };
    case SSP_REQUEST_FORM_DROPDOWN_DATA_FAIL:
      return {
        ...state,
        requestDropdownData: action.payload,
        requestDropdownDataLoading: true,
        requestDropdownDataLoaded: false,
      };
    case SSP_REQUEST_NO_DATA_SUCCESS:
      return {
        ...state,
        requestNoData: action.payload,
        requestNoDataLoading: false,
        isLoading: false,
      };
    case SSP_REQUEST_NO_DATA_FAIL:
      return {
        ...state,
        requestNoData: action.payload,
        requestNoDataLoading: true,
        isLoading: false,
      };
    case SSP_REQUEST_PROCEEED_RESET_SUCCESS:
      return { ...state, requestEntryData: [], isLoading: false };
    case SSP_REQUEST_CUSTOMER_PLANT:
      return { ...state, customerPlantDataLoading: true };
    case SSP_REQUEST_CUSTOMER_FILE:
      return { ...state, customerFileData: [], customerFileLoading: true };
    case SSP_REQUEST_CUSTOMER_FILE_SUCCESS:
      return {
        ...state,
        customerFileData: action.data,
        customerFileLoading: false,
      };
    case SSP_REQUEST_CUSTOMER_PLANT_SUCCESS:
      return {
        ...state,
        customerPlantData: action.data,
        customerPlantDataLoading: false,
      };
    case SSP_REQUEST_SUPPLIER_EMAIL_SUCCESS:
      return { ...state, supplierEmailData: action.data };
    case SSP_REQUEST_CUSTOMER_EMAIL_SUCCESS:
      return { ...state, customerEmailData: action.data };
    case SSP_REQUEST_SUBMIT_SUCCESS:
      return { ...state, requestEntryData: action.data, isLoading: false };
    case SSP_REQUEST_SUBMIT_FAIL:
      return { ...state, isLoading: false };
    case SSP_REQUEST_DOC_SEND_SUCCESS:
      return { ...state, isLoading: false };
    case SSP_REQUEST_CANCEL_DOC_SEND_SUCCESS:
      return { ...state, requestEntryData: action.data, isLoading: false };
    case SSP_REQUEST_DOC_SEND_FAIL:
      return { ...state, isLoading: false };
    case SSP_STORE_FILE_DETAILS_SUCCESS:
      return { ...state, requestFileDetailsData: action.data };
    case SSP_REQUEST_FETCH_DOC_INIT:
      return { ...state, isDocLoading: true };
    case SSP_REQUEST_FETCH_DOC_SUCCESS:
      return {
        ...state,
        base64StringDisplay: action.data,
        isDocLoading: false,
      };
    case SSP_REQUEST_FETCH_DOC_ERROR:
      return { ...state, isDocLoading: false };
    default:
      return state;
  }
}

const getRequestFormDetails = (
  b2b_req_no,
  requestEntry,
  requestDetailData,
  targetKey
) => {
  return (dispatch) => {
    dispatch(requestFormDetails(b2b_req_no, targetKey, requestDetailData)).then(
      (response) => {
        if (response === 'SUCCESS') {
          dispatch({
            type: SSP_REQUEST_CANCEL_DOC_SEND_SUCCESS,
            data: requestEntry,
          });
          return 'SUCCESS';
        } else {
          return 'FAILURE';
        }
      }
    );
  };
};

export function proceedReq(formattedValues, targetKey, requestDetailData = []) {
  return (dispatch, _getState) => {
    dispatch({ type: SSP_REQUEST_PROCEEED });
    if (
      formattedValues[targetKey] &&
      formattedValues[targetKey].request_option == 'existing' &&
      formattedValues[targetKey].request_no !== undefined
    ) {
      dispatch(
        requestFormDetails(
          formattedValues[targetKey].request_no,
          targetKey,
          requestDetailData
        )
      ).then((response) => {
        if (response === 'SUCCESS') {
          dispatch(update(formattedValues));
          return 'SUCCESS';
        } else {
          return 'FAILURE';
        }
      });
    } else {
      dispatch(update(formattedValues));
    }
  };
  function update(data) {
    return { type: SSP_REQUEST_PROCEEED_SUCCESS, data };
  }
}

export function getBasestringDisplay(
  customerFileDetails = [],
  originalName = ''
) {
  return (dispatch, _getState) => {
    dispatch({ type: SSP_REQUEST_FETCH_DOC_INIT });
    if (customerFileDetails !== undefined && originalName !== '') {
      let displayStirng = '';
      for (const element of customerFileDetails) {
        if (element.original_file == originalName) {
          displayStirng = element.base_string;
          break;
        }
      }
      if (displayStirng !== undefined && displayStirng !== '') {
        dispatch(update(displayStirng));
      } else {
        dispatch({
          type: SSP_REQUEST_FETCH_DOC_ERROR,
        });
      }
    }
  };
  function update(data) {
    return { type: SSP_REQUEST_FETCH_DOC_SUCCESS, data };
  }
}

export function storeFileDetails(formattedValues) {
  return (dispatch, _getState) => {
    dispatch(update(formattedValues));
  };
  function update(data) {
    return { type: SSP_STORE_FILE_DETAILS_SUCCESS, data };
  }
}

export function requestFormDetails(request_no, targetKey, tabData = []) {
  return (dispatch, getState, { api, formatParams }) => {
    const currentTime = getEpochTime();
    return api
      .post(
        'ssp',
        formatParams(
          {
            type: 'REQUEST_FORM_DETAIL',
            uuid: getUUID(),
            customer: 'SANM',
            requestor: Cookie.get('email'),
            source: 'PORTAL',
            form_details: {
              b2b_req_no: request_no,
            },
          },
          getState
        )
      )
      .then((response) => {
        gaPageviewTimingTitle(
          `/SSP/getCustomerRequestFormDetails`,
          currentTime
        );
        const { data } = response;
        tabData[targetKey] = data;
        const TXNGET = data.form_details.b2b_req_no;
        if (TXNGET && TXNGET !== undefined) {
          dispatch(update(tabData));
          return 'SUCCESS';
        } else {
          dispatch({
            type: SSP_REQUEST_DETAIL_FAIL,
            payload: 'Error in fetching data',
          });
          return 'FAILURE';
        }
      });
  };
  function update(data) {
    return { type: SSP_REQUEST_DETAIL_SUCCESS, data };
  }
}

export function fetchRequestFormDropdownData() {
  return (dispatch, getState, { api, formatParams }) => {
    const authorized = Object.keys(
      getState().authorization.initAuthorization
    ).length;
    if (authorized) {
      dispatch({ type: SSP_REQUEST_FORM_DROPDOWN_DATA_LOADING });
      return api
        .post(
          'ssp',
          formatParams(
            {
              type: 'REQUEST_FORM_DROPDOWN_DATA',
            },
            getState
          )
        )
        .then((response) => {
          const { data } = response;
          if (data) {
            dispatch({
              type: SSP_REQUEST_FORM_DROPDOWN_DATA_SUCCESS,
              payload: data,
            });
          } else {
            dispatch({
              type: SSP_REQUEST_FORM_DROPDOWN_DATA_FAIL,
              payload: 'Error in fetching dropdown data',
            });
          }
          return response;
        });
    }
  };
}

export function fetchRequestNoData() {
  return (dispatch, getState, { api, formatParams }) => {
    const authorized = Object.keys(
      getState().authorization.initAuthorization
    ).length;
    if (authorized) {
      dispatch({ type: SSP_REQUEST_PROCEEED });
      return api
        .post(
          'ssp',
          formatParams(
            {
              type: 'REQUEST_NO_DROPDOWN_LIST',
              form_details: {
                email: Cookie.get('email'),
              },
            },
            getState
          )
        )
        .then((response) => {
          const { data } = response;
          if (data) {
            dispatch({
              type: SSP_REQUEST_NO_DATA_SUCCESS,
              payload: data,
            });
          } else {
            dispatch({
              type: SSP_REQUEST_NO_DATA_FAIL,
              payload: 'Error in fetching dropdown data',
            });
          }
          return response;
        });
    }
  };
}

export function requestSendDocsNew(formattedValues) {
  return (dispatch, getState, { api, formatParams }) => {
    const authorized = Object.keys(
      getState().authorization.initAuthorization
    ).length;
    if (authorized) {
      dispatch({ type: SSP_REQUEST_PROCEEED });
      const currentTime = getEpochTime();
      return api
        .post(
          'sspsubmitaction',
          formatParams(
            {
              transaction_type: 'DOC_REVIEW',
              documents: formattedValues.form_details.documents,
              uuid: getUUID(),
              source: 'PORTAL',
              form_details: {
                requester: Cookie.get('email'),
                b2b_req_no: '',
              },
              user: {
                email: formattedValues.form_details.email_id_doc,
              },
            },
            getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(`/SSP/requestFormSendDocsNew`, currentTime);
          const { data } = response;
          const TXNGET = data.TXN;
          if (TXNGET && TXNGET[0].return_msg === 'SUCCESS') {
            dispatch({
              type: SSP_REQUEST_DOC_SEND_SUCCESS,
              payload: data,
            });
          } else {
            dispatch({
              type: SSP_REQUEST_DOC_SEND_FAIL,
              payload: 'Error in fetching dropdown data',
            });
          }
          return TXNGET[0].return_msg;
        });
    }
  };
}

const getB2bType = (formattedValues) => {
  if (formattedValues.b2b_type) {
    if (formattedValues.b2b_type == 'Sanmina - Std Maps') {
      return 'SSP';
    } else if (formattedValues.b2b_type == 'Customer - Std Maps') {
      return 'SSP2';
    } else if (formattedValues.b2b_type == 'Customer - Custom Maps') {
      return 'CUSTOM_SSP2';
    } else {
      return formattedValues.b2b_type;
    }
  }
};

export function requestApprove(
  formattedValues,
  targetKey,
  requestDetailData,
  requestEntryData
) {
  return (dispatch, getState, { api, formatParams }) => {
    const authorized = Object.keys(
      getState().authorization.initAuthorization
    ).length;

    if (authorized) {
      dispatch({ type: SSP_REQUEST_PROCEEED });
      const currentTime = getEpochTime();
      return api
        .post(
          'sspsubmitaction',
          formatParams(
            {
              transaction_type: 'REVIEWER_DOC_STATUS',
              reviewer: formattedValues.reviewer,
              customer: 'SANM',
              uuid: getUUID(),
              source: 'PORTAL',
              form_details: {
                b2b_type: getB2bType(formattedValues),
                b2b_req_no: formattedValues.b2b_req_no,
                overall_status: formattedValues.overall_status,
                accept_flag: formattedValues.accept_flag,
              },
            },
            getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(`/SSP/approveCustomerForm`, currentTime);
          const { data } = response;
          const TXNGET = data.TXN;
          if (TXNGET && TXNGET[0].return_msg === 'SUCCESS') {
            requestEntryData[targetKey] = {
              request_option: 'existing',
              request_no: TXNGET[0].b2b_req_no,
            };
            if (TXNGET[0].b2b_req_no) {
              dispatch(
                getRequestFormDetails(
                  TXNGET[0].b2b_req_no,
                  requestEntryData,
                  requestDetailData,
                  targetKey
                )
              );
            }
          } else {
            dispatch({
              type: SSP_REQUEST_DOC_SEND_FAIL,
              payload: 'Error in fetching dropdown data',
            });
          }
          return TXNGET[0].return_msg;
        });
    }
  };
}

export function requestSendDocsOld(formattedValues) {
  return (dispatch, getState, { api, formatParams }) => {
    const authorized = Object.keys(
      getState().authorization.initAuthorization
    ).length;
    if (authorized) {
      dispatch({ type: SSP_REQUEST_PROCEEED });
      const currentTime = getEpochTime();
      return api
        .post(
          'sspsubmitaction',
          formatParams(
            {
              transaction_type: 'DOC_REVIEW',
              documents: formattedValues.documents,
              uuid: getUUID(),
              source: 'PORTAL',
              form_details: {
                requester: Cookie.get('email'),
                b2b_req_no: formattedValues.b2b_req_no,
                overall_status: 'Docs Sent',
              },
              user: {
                email: formattedValues.email_id,
              },
            },
            getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(
            `/SSP/requestSendDocsOldFormSendDocsOld`,
            currentTime
          );
          const { data } = response;
          const TXNGET = data.TXN;
          if (TXNGET && TXNGET[0].return_msg === 'SUCCESS') {
            dispatch({
              type: SSP_REQUEST_DOC_SEND_SUCCESS,
              payload: data,
            });
          } else {
            dispatch({
              type: SSP_REQUEST_DOC_SEND_FAIL,
              payload: 'Error in fetching dropdown data',
            });
          }
          return TXNGET[0].return_msg;
        });
    }
  };
}

export function requestSendUpdates(formattedValues) {
  return (dispatch, getState, { api, formatParams }) => {
    const authorized = Object.keys(
      getState().authorization.initAuthorization
    ).length;
    if (authorized) {
      dispatch({ type: SSP_REQUEST_PROCEEED });
      const currentTime = getEpochTime();
      return api
        .post(
          'sspsubmitaction',
          formatParams(
            {
              transaction_type: 'UPDATE_FEEDBACK',
              uuid: getUUID(),
              source: 'PORTAL',
              customer: 'SANM',
              requestor: formattedValues.requester,
              user_role: 'Requester',
              form_details: {
                requester: Cookie.get('email'),
                b2b_req_no: formattedValues.b2b_req_no,
                email_list: formattedValues.email_list,
                feedback_details: formattedValues.feedback_details,
                user_feedback: formattedValues.user_feedback,
                formtype: formattedValues.formtype,
              },
            },
            getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(`/SSP/requestFormSendUpdates`, currentTime);
          const { data } = response;
          const TXNGET = data.TXN;
          if (TXNGET && TXNGET[0].return_msg === 'SUCCESS') {
            dispatch({
              type: SSP_REQUEST_DOC_SEND_SUCCESS,
              payload: data,
            });
          } else {
            dispatch({
              type: SSP_REQUEST_DOC_SEND_FAIL,
              payload: 'Error in fetching dropdown data',
            });
          }
          return TXNGET[0].return_msg;
        });
    }
  };
}

export function requestSendCancel(
  formattedValues,
  targetKey,
  requestDetailData,
  requestEntryData
) {
  return (dispatch, getState, { api, formatParams }) => {
    const authorized = Object.keys(
      getState().authorization.initAuthorization
    ).length;

    if (authorized) {
      dispatch({ type: SSP_REQUEST_PROCEEED });
      const currentTime = getEpochTime();
      return api
        .post(
          'sspsubmitaction',
          formatParams(
            {
              transaction_type: 'RF_OVERALL_STATUS',
              uuid: getUUID(),
              customer: 'SANM',
              source: 'PORTAL',
              requestor: formattedValues.requester,
              user_role: 'Requester',
              b2b_req_no: formattedValues.b2b_req_no,
              overall_status_flag: '1',
              overall_status: 'Cancelled',
            },
            getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(`/SSP/requestFormCancelled`, currentTime);
          const { data } = response;
          const TXNGET = data.TXN;
          if (TXNGET && TXNGET[0].return_msg === 'SUCCESS') {
            requestEntryData[targetKey] = {
              request_option: 'existing',
              request_no: TXNGET[0].b2b_req_no,
            };
            if (TXNGET[0].b2b_req_no) {
              dispatch(
                getRequestFormDetails(
                  TXNGET[0].b2b_req_no,
                  requestEntryData,
                  requestDetailData,
                  targetKey
                )
              );
            }
          } else {
            dispatch({
              type: SSP_REQUEST_DOC_SEND_FAIL,
              payload: 'Error in fetching dropdown data',
            });
          }
          return TXNGET[0].return_msg;
        });
    }
  };
}

export function requestSendStatus(
  formattedValues,
  targetKey,
  requestDetailData,
  requestEntryData
) {
  return (dispatch, getState, { api, formatParams }) => {
    const authorized = Object.keys(
      getState().authorization.initAuthorization
    ).length;
    if (authorized) {
      dispatch({ type: SSP_REQUEST_PROCEEED });
      const currentTime = getEpochTime();
      return api
        .post(
          'sspsubmitaction',
          formatParams(
            {
              transaction_type: 'RF_OVERALL_STATUS',
              uuid: getUUID(),
              customer: 'SANM',
              source: 'PORTAL',
              requestor: formattedValues.requestor,
              user_role: formattedValues.user_role,
              b2b_req_no: formattedValues.b2b_req_no,
              overall_status_flag: formattedValues.overall_status_flag,
              overall_status: formattedValues.overall_status,
              feedback_details: formattedValues.feedback_details,
            },
            getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(`/SSP/updateStatusCustomerForm`, currentTime);
          const { data } = response;
          const TXNGET = data.TXN;
          if (TXNGET && TXNGET[0].return_msg === 'SUCCESS') {
            requestEntryData[targetKey] = {
              request_option: 'existing',
              request_no: TXNGET[0].b2b_req_no,
            };
            if (TXNGET[0].b2b_req_no) {
              dispatch(
                getRequestFormDetails(
                  TXNGET[0].b2b_req_no,
                  requestEntryData,
                  requestDetailData,
                  targetKey
                )
              );
            }
          } else {
            dispatch({
              type: SSP_REQUEST_DOC_SEND_FAIL,
              payload: 'Error in fetching dropdown data',
            });
          }
          return TXNGET[0].return_msg;
        });
    }
  };
}

export function requestSendStatusProd(
  formattedValues,
  targetKey,
  requestDetailData,
  requestEntryData
) {
  return (dispatch, getState, { api, formatParams }) => {
    const authorized = Object.keys(
      getState().authorization.initAuthorization
    ).length;

    if (authorized) {
      dispatch({ type: SSP_REQUEST_PROCEEED });
      const currentTime = getEpochTime();
      return api
        .post(
          'sspsubmitaction',
          formatParams(
            {
              transaction_type: 'ORACLEEDI_SETUPS',
              uuid: getUUID(),
              customer: 'SANM',
              source: 'PORTAL',
              requestor: formattedValues.requestor,
              user_role: formattedValues.user_role,
              b2b_req_no: formattedValues.b2b_req_no,
              overall_status_flag: formattedValues.overall_status_flag,
              overall_status: formattedValues.overall_status,
              instance_type: formattedValues.instance_type,
              osp_instance: formattedValues.osp_instance,
              doc_std: formattedValues.doc_std || '',
            },
            getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(
            `/SSP/updateProdStatusRequestForm`,
            currentTime
          );
          const { data } = response;
          const TXNGET = data.TXN;
          if (TXNGET && TXNGET[0].return_msg === 'SUCCESS') {
            requestEntryData[targetKey] = {
              request_option: 'existing',
              request_no: TXNGET[0].b2b_req_no,
            };
            if (TXNGET[0].b2b_req_no) {
              dispatch(
                getRequestFormDetails(
                  TXNGET[0].b2b_req_no,
                  requestEntryData,
                  requestDetailData,
                  targetKey
                )
              );
            }
          } else {
            dispatch({
              type: SSP_REQUEST_DOC_SEND_FAIL,
              payload: 'Error in fetching dropdown data',
            });
          }
          return TXNGET[0].return_msg;
        });
    }
  };
}

export function requestSendStatusOsp(
  formattedValues,
  targetKey,
  requestDetailData,
  requestEntryData
) {
  return (dispatch, getState, { api, formatParams }) => {
    const authorized = Object.keys(
      getState().authorization.initAuthorization
    ).length;

    if (authorized) {
      dispatch({ type: SSP_REQUEST_PROCEEED });
      const currentTime = getEpochTime();
      return api
        .post(
          'sspsubmitaction',
          formatParams(
            {
              transaction_type: 'ORACLEEDI_SETUPS',
              uuid: getUUID(),
              customer: 'SANM',
              source: 'PORTAL',
              requestor: formattedValues.requester,
              user_role: formattedValues.user_role,
              b2b_req_no: formattedValues.b2b_req_no,
              overall_status_flag: formattedValues.overall_status_flag,
              overall_status: formattedValues.overall_status,
              instance_type: formattedValues.instance_type,
              osp_instance: formattedValues.osp_instance,
            },
            getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(
            `/SSP/updateOspStatusCustomerForm`,
            currentTime
          );
          const { data } = response;
          const TXNGET = data.TXN;
          if (TXNGET && TXNGET[0].return_msg === 'SUCCESS') {
            requestEntryData[targetKey] = {
              request_option: 'existing',
              request_no: TXNGET[0].b2b_req_no,
            };

            if (TXNGET[0].b2b_req_no) {
              dispatch(
                getRequestFormDetails(
                  TXNGET[0].b2b_req_no,
                  requestEntryData,
                  requestDetailData,
                  targetKey
                )
              );
            }
          } else {
            dispatch({
              type: SSP_REQUEST_DOC_SEND_FAIL,
              payload: 'Error in fetching dropdown data',
            });
          }
          return TXNGET[0].return_msg;
        });
    }
  };
}

export function requestSendStatusUat(
  formattedValues,
  targetKey,
  requestDetailData,
  requestEntryData
) {
  return (dispatch, getState, { api, formatParams }) => {
    const authorized = Object.keys(
      getState().authorization.initAuthorization
    ).length;

    if (authorized) {
      dispatch({ type: SSP_REQUEST_PROCEEED });
      const currentTime = getEpochTime();
      return api
        .post(
          'sspsubmitaction',
          formatParams(
            {
              transaction_type: 'RF_OVERALL_STATUS',
              uuid: getUUID(),
              customer: 'SANM',
              source: 'PORTAL',
              requestor: formattedValues.requester,
              user_role: formattedValues.user_role,
              b2b_req_no: formattedValues.b2b_req_no,
              overall_status_flag: formattedValues.overall_status_flag,
              overall_status: formattedValues.overall_status,
              feedback_details: formattedValues.feedback_details,
            },
            getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(
            `/SSP/updateUatStatusCustomerForm`,
            currentTime
          );
          const { data } = response;
          const TXNGET = data.TXN;
          if (TXNGET && TXNGET[0].return_msg === 'SUCCESS') {
            requestEntryData[targetKey] = {
              request_option: 'existing',
              request_no: TXNGET[0].b2b_req_no,
            };
            if (TXNGET[0].b2b_req_no) {
              dispatch(
                getRequestFormDetails(
                  TXNGET[0].b2b_req_no,
                  requestEntryData,
                  requestDetailData,
                  targetKey
                )
              );
            }
          } else {
            dispatch({
              type: SSP_REQUEST_DOC_SEND_FAIL,
              payload: 'Error in fetching dropdown data',
            });
          }
          return TXNGET[0].return_msg;
        });
    }
  };
}

export function resetEntryData() {
  return (dispatch, _getState) => {
    dispatch({ type: SSP_REQUEST_PROCEEED_RESET_SUCCESS });
  };
}

export function customerPlantAdd(formattedValues) {
  return (dispatch, _getState) => {
    dispatch({ type: SSP_REQUEST_CUSTOMER_PLANT });
    dispatch({
      type: SSP_REQUEST_CUSTOMER_PLANT_SUCCESS,
      data: formattedValues,
    });
  };
}

export function customerEmailAdd(formattedValues) {
  return (dispatch, _getState) => {
    dispatch({
      type: SSP_REQUEST_CUSTOMER_EMAIL_SUCCESS,
      data: formattedValues,
    });
  };
}

export function supplierEmailAdd(formattedValues) {
  return (dispatch, _getState) => {
    dispatch({
      type: SSP_REQUEST_SUPPLIER_EMAIL_SUCCESS,
      data: formattedValues,
    });
  };
}

export function customerFileAdd(formattedValues) {
  return (dispatch, _getState) => {
    dispatch({ type: SSP_REQUEST_CUSTOMER_FILE });
    dispatch({
      type: SSP_REQUEST_CUSTOMER_FILE_SUCCESS,
      data: formattedValues,
    });
  };
}

const getCustRequestFormDetails = (formattedValues) => {
  return {
    b2b_req_no: formattedValues.form_details.b2bnumber || null,
    requester: formattedValues.form_details.requester || null,
    creation_date: formattedValues.form_details.creation_date || null,
    last_update_Date: formattedValues.form_details.last_update_date || null,
    last_updater: formattedValues.form_details.last_updater || null,
    overall_status_flag: false,
    b2b_type: getB2bType(formattedValues.form_details),
    draft_flag: formattedValues.draft_flag || null,
    sanmina_std_document_flag:
      formattedValues.form_details.sanmina_std_document_flag || null,
    overall_status:
      formattedValues.draft_flag && formattedValues.draft_flag == 'YES'
        ? 'Unreleased'
        : 'Docs Sent',
    inbound_860_txn: formattedValues.form_details.inbound_860_txn,
  };
};

const getDocuments = (getInbound860, documents) => {
  if (getInbound860 == 'Yes' && documents) {
    documents.push('860', '865');
  }
  return documents ? documents.join() : null;
};

const getCustUserDetails = (formattedValues) => {
  return {
    email: formattedValues.email_id || null,
    company: formattedValues.form_details.company || null,
    country: formattedValues.form_details.country || null,
    customer_code: null,
    role: 'user',
    multi_email:
      formattedValues.email_id && formattedValues.email_id.indexOf(',') > -1
        ? 'true'
        : 'false',
  };
};

const getCustNewPoDetails = (formattedValues) => {
  return {
    po_types: formattedValues.form_details.new_po_type
      ? formattedValues.form_details.new_po_type.join()
      : null,
    po_val_check: formattedValues.form_details.new_po_check
      ? formattedValues.form_details.new_po_check.join()
      : null,
  };
};

const getDummyFlagValue = (formattedValues) => {
  return formattedValues.form_details.docs &&
    !formattedValues.form_details.docs.includes('850')
    ? 'YES'
    : 'NO';
};

const getCustFlagDetails = (formattedValues) => {
  return {
    wf_act_flg: 'YES',
    active_flag: 'YES',
    booked_fl: formattedValues.form_details.booked_fl || null,
    drop_ship_fl: formattedValues.form_details.drop_ship_fl || null,
    contain_flag: formattedValues.form_details.contain_flag || null,
    dummy_flag: getDummyFlagValue(formattedValues),
  };
};

const getCustWorkflowDetails = (formattedValues) => {
  return {
    customer_no: formattedValues.form_details.cust_num || null,
    doc_id: formattedValues.form_details.doc_id || null,
    po_type: null,
    tp_code: formattedValues.form_details.tp_code || null,
    prod_tp_code: formattedValues.form_details.prod_tp_code || null,
    order_hdr_type_cd:
      formattedValues.form_details.order_header_type_code || null,
    order_ln_type_cd: formattedValues.form_details.order_line_type_code || null,
    po_rcvd_alrt_dl: formattedValues.form_details.po_alert_dl || null,
    ol_lface_alrt_dl: formattedValues.form_details.oracle_alert_dl || null,
    so_creation_alrt_dl: formattedValues.form_details.so_alert_dl || null,
    sparc_prog_mgr: '',
    sparc_sales_ch_code: '',
    sparc_prod_type: '',
    sparc_sales_rep_no: '',
    em_dl: null,
    sold_to_id: '',
    sold_to_edi_loc: '',
    pri_sold_to_address_enabled:
      formattedValues.form_details.pri_sold_to_address_enabled || null,
    doc_std: formattedValues.form_details.doc_std || null,
    val_tax_reg: formattedValues.form_details.val_tax_reg || null,
    ino_exc_xref: formattedValues.form_details.ino_exc_xref || null,
    ino_multi_curr: formattedValues.form_details.ino_multi_curr || null,
    ...getCustFlagDetails(formattedValues),
  };
};

const setCustPlantsList = (formattedValues, txnParams) => {
  if (
    formattedValues.customer_plant_details &&
    formattedValues.customer_plant_details.length > 0
  ) {
    txnParams.customer_plant_details = {
      plant_list: formattedValues.customer_plant_details,
    };
  } else {
    txnParams.customer_plant_details = null;
  }
};

const setCustFileDetails = (formattedValues, txnParams) => {
  if (
    formattedValues.customer_file_details &&
    formattedValues.customer_file_details.length > 0
  ) {
    txnParams.files_list = { files: formattedValues.customer_file_details };
    txnParams.customer_file_upload_details =
      formattedValues.customer_file_upload_details
        ? { file_upload_list: formattedValues.customer_file_upload_details }
        : null;
  } else {
    txnParams.files_list = null;
  }
};

const getCustChangePoAck = (formattedValues) => {
  return {
    send: null,
    change_po_val_ack_check: formattedValues.form_details.change_po_ack_1
      ? formattedValues.form_details.change_po_ack_1.join()
      : null,
  };
};

const getCustChangePo = (formattedValues) => {
  return {
    change_po_val_check: formattedValues.form_details.change_po_check
      ? formattedValues.form_details.change_po_check.join()
      : null,
  };
};

export function requestSubmit(
  formattedValues,
  targetKey,
  requestDetailData,
  requestEntryData
) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: SSP_REQUEST_PROCEEED });
    const txnParams = {
      transaction_type: formattedValues.advance
        ? 'ADVANCE_B2B_REQUEST'
        : 'REQUEST_FORM',
      documents: getDocuments(
        formattedValues.form_details.inbound_860_txn,
        formattedValues.form_details.docs
      ),
      uuid: getUUID(),
      customer: 'SANM',
      requestor: formattedValues.form_details.requester || null,
      source: 'PORTAL',
      b2b_type: getB2bType(formattedValues.form_details),
      user_type: formattedValues.user_type,
      form_details: getCustRequestFormDetails(formattedValues),
      user: getCustUserDetails(formattedValues),
      new_po: getCustNewPoDetails(formattedValues),
      po_ack: {
        send: null,
      },
      change_po: getCustChangePo(formattedValues),
      changed_po_ack: getCustChangePoAck(formattedValues),
      work_flow: getCustWorkflowDetails(formattedValues),
    };

    setCustPlantsList(formattedValues, txnParams);
    setCustFileDetails(formattedValues, txnParams);

    const currentTime = getEpochTime();
    return api
      .post('sspsubmitaction', formatParams(txnParams, getState))
      .then((response) => {
        gaPageviewTimingTitle(`/SSP/submitCustomerFormRequest`, currentTime);
        const { data } = response;
        const TXNGET = data.TXN;
        if (TXNGET) {
          if (TXNGET[0].return_msg === 'SUCCESS') {
            requestEntryData[targetKey] = {
              request_option: 'existing',
              request_no: TXNGET[0].b2b_req_no,
            };

            if (TXNGET[0].b2b_req_no) {
              dispatch(
                getRequestFormDetails(
                  TXNGET[0].b2b_req_no,
                  requestEntryData,
                  requestDetailData,
                  targetKey
                )
              );
            }
          } else {
            dispatch({
              type: SSP_REQUEST_SUBMIT_FAIL,
              payload: 'Server error please try again after some time 1',
            });
          }
          return TXNGET[0].return_msg;
        } else {
          return 'Server error please try again after some time 2';
        }
      });
  };
}

const getRequestorIdByForm = (formattedValues) => {
  let value = null;
  if (formattedValues.form_details.requester) {
    value = formattedValues.form_details.requester;
  } else if (formattedValues.requester) {
    value = formattedValues.requester;
  }

  return value;
};

const getSupplierWorkflow = (formattedValues) => {
  return {
    doc_std: formattedValues.form_details.doc_std || null,
    ship_to_id: formattedValues.form_details.ship_to_code || null,
    tp_code: formattedValues.form_details.tp_code || null,
    prod_tp_code: formattedValues.form_details.prod_tp_code || null,
    dummy_flag: 'NO',
    bill_to_id: formattedValues.form_details.bill_to_code || null,
  };
};

const getSupplierUserDetails = (formattedValues) => {
  return {
    email: formattedValues.email_id || null,
    supplier_name: formattedValues.form_details.supplier_name || null,
    supplier_number: formattedValues.form_details.supplier_number || null,
    supplier_site: formattedValues.form_details.supplier_site || null,
    plant_code: formattedValues.form_details.plant_code || null,
    role: 'user',
    multi_email:
      formattedValues.email_id && formattedValues.email_id.indexOf(',') > -1
        ? 'true'
        : 'false',
    po_types: formattedValues.form_details.new_po_type
      ? formattedValues.form_details.new_po_type.join()
      : null,
    scope_testing: formattedValues.form_details.scope_testing || null,
    location_code: formattedValues.form_details.bill_to_code || null,
  };
};

const getSupplierFormDetails = (formattedValues) => {
  return {
    b2b_req_no: formattedValues.form_details.b2bnumber || null,
    requester: getRequestorIdByForm(formattedValues),
    overall_status_flag: false,
    b2b_type: 'SSP3',
    draft_flag: formattedValues.draft_flag || null,
    sanmina_std_document_flag:
      formattedValues.form_details.sanmina_std_document_flag || null,
    overall_status:
      formattedValues.draft_flag && formattedValues.draft_flag == 'YES'
        ? 'Unreleased'
        : 'Docs Sent',
  };
};

export function requestSubmitSupplier(
  formattedValues,
  targetKey,
  requestDetailData,
  requestEntryData
) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: SSP_REQUEST_PROCEEED });
    const b2b_type = 'SSP3';
    const currentTime = getEpochTime();

    return api
      .post(
        'sspsubmitaction',
        formatParams(
          {
            transaction_type: 'REQUEST_FORM',
            documents:
              formattedValues.form_details.docs !== undefined
                ? formattedValues.form_details.docs.join()
                : null,
            uuid: getUUID(),
            customer: 'SANM',
            requestor: getRequestorIdByForm(formattedValues),
            source: 'PORTAL',
            b2b_type,
            user_type: formattedValues.user_type,
            form_details: getSupplierFormDetails(formattedValues),
            user: getSupplierUserDetails(formattedValues),
            work_flow: getSupplierWorkflow(formattedValues),
          },
          getState
        )
      )
      .then((response) => {
        gaPageviewTimingTitle(`/SSP/submitSupplierFormRequest`, currentTime);
        const { data } = response;
        const TXNGET = data.TXN;
        if (TXNGET) {
          if (TXNGET[0].return_msg === 'SUCCESS') {
            requestEntryData[targetKey] = {
              request_option: 'existing',
              request_no: TXNGET[0].b2b_req_no,
            };

            if (TXNGET[0].b2b_req_no) {
              dispatch(
                getRequestFormDetails(
                  TXNGET[0].b2b_req_no,
                  requestEntryData,
                  requestDetailData,
                  targetKey
                )
              );
            }
          } else {
            dispatch({
              type: SSP_REQUEST_SUBMIT_FAIL,
              payload: 'Server error please try again after some time 1',
            });
          }

          return TXNGET[0].return_msg;
        } else {
          return 'Server error please try again after some time 2';
        }
      });
  };
}
