import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tour, Button } from 'antd';
import TourActionButton from '../TourActionButton';
import Cookie from 'js-cookie';
import { setWalkthrough } from '../redux/tour';
import { destroyTabs } from '../../layout/redux/modules/layout';
const Helpwalk = (props) => {
  const [open, setOpen] = useState(false);
  const tourReferences = useSelector(({ tour }) => tour.tourReferences);
  const { defaultAccess } = useSelector(({ authorization }) => ({
    defaultAccess: authorization.initAuthorization.defaultAccess,
  }));
  const dispatch = useDispatch();
  useEffect(() => {
    const handleClickCard = (event) => {
      let getContentValue = event.target.innerHTML;
      let getContentLength = getContentValue.length;
      if (
        (defaultAccess == 'CDS' &&
          getContentValue != '' &&
          getContentValue != 'Next' &&
          getContentValue != 'Previous' &&
          getContentLength < 25) ||
        getContentValue.includes('ag-charts-aria')
      ) {
        enableWalkthrough();
        setOpen(false);
      }
    };
    document.addEventListener('click', handleClickCard, true);
    dispatch(destroyTabs());
  }, [tourReferences]);
  const openTour = () => {
    props.handleVisiblity();
    dispatch(destroyTabs());
    setOpen(true);
  };
  const enableWalkthrough = () => {
    Cookie.set('walkthroughProfileWalk', 'Yes');
    Cookie.set('walkthroughOrderStatus', 'Yes');
    Cookie.set('walkthroughOrderHistory', 'Yes');
    Cookie.set('walkthroughOrderReports', 'Yes');
  };
  const closeTour = () => {
    enableWalkthrough();
    dispatch(setWalkthrough('CDS', 'dashboard', 'Skip'));
    setOpen(false);
  };
  const steps = [
    {
      title: 'Customer Data Sharing Portal',
      description: (
        <div>
          Welcome to Customer Data Sharing! We put this short tour together to
          show you some key features. You can dismiss this message and come back
          to it at any time using the link in the Support area.
          <br />
          <TourActionButton
            type='skip'
            screen='dashboard'
            label='Skip'
            closeTour={closeTour}
          />
        </div>
      ),
      target: null,
    },
    {
      title: 'Customer Data Sharing Portal',
      description: (
        <div>
          Please click the Order Status Menu item to proceed with the next tip.
          <br />
          <TourActionButton
            type='skip'
            screen='dashboard'
            label='Skip'
            closeTour={closeTour}
          />
        </div>
      ),
      target: () => tourReferences.orderStatusCdsTour.current,
    },
    {
      title: 'Customer Data Sharing Portal',
      description: (
        <div>
          Please click the Order History Menu item to proceed with the next tip.
          <br />
          <TourActionButton
            type='skip'
            screen='dashboard'
            label='Skip'
            closeTour={closeTour}
          />
        </div>
      ),
      target: () => tourReferences.orderHistoryCdsTour.current,
    },
    {
      title: 'Customer Data Sharing Portal',
      description: (
        <div>
          To refresh the dashboard data, click here.
          <br />
          <TourActionButton
            type='skip'
            screen='dashboard'
            label='Skip'
            closeTour={closeTour}
          />
        </div>
      ),
      target: () => tourReferences.refreshScreen.current,
    },
    {
      title: 'Customer Data Sharing Portal',
      description: (
        <div>
          The values displayed in Dashboard Charts are based on the current PO
          Status Value of the POs. To analyze trends over time, select a
          specific time period at the top of the chart (for example, "Last 7
          Days").
          <br />
          <TourActionButton
            type='skip'
            screen='dashboard'
            label='Skip'
            closeTour={closeTour}
          />
        </div>
      ),
      target: () => tourReferences.ordersScreen.current,
    },
    {
      title: 'Customer Data Sharing Portal',
      description: (
        <div>
          To analyze trends over time, select a specific time period at the top
          of the chart (for example, "Last 7 Days").
          <br />
          <TourActionButton
            type='skip'
            screen='dashboard'
            label='Skip'
            closeTour={closeTour}
          />
        </div>
      ),
      target: () => tourReferences.ordersScreenPeriod.current,
    },
    {
      title: 'Customer Data Sharing Portal',
      description: (
        <div>
          Clicking a chart bar opens an Order Status or Order History form
          showing the detailed Orders for the selection. The selection will be
          based on the time period selected which may not contain all the orders
          available to the user.
          <br />
          <TourActionButton
            type='skip'
            screen='dashboard'
            label='Skip'
            closeTour={closeTour}
          />
        </div>
      ),
      target: () => tourReferences.ordersGraph.current,
    },
  ];
  return (
    <div>
      <Button type='primary' className='left-walkthrough' onClick={openTour}>
        Take a tour!
      </Button>
      <Tour open={open} onClose={closeTour} steps={steps} />
    </div>
  );
};

export default Helpwalk;
