import thunkMiddleware from 'redux-thunk';
import api from '../service/Service';
import { createStore, applyMiddleware } from 'redux';
import reducers from './reducers';
import { createLogger } from 'redux-logger';
import { isDevInstance } from '../helpers/utils';
const loggerMiddleware = createLogger();

const middlewareConfig = isDevInstance()
  ? applyMiddleware(thunkMiddleware.withExtraArgument(api), loggerMiddleware)
  : applyMiddleware(thunkMiddleware.withExtraArgument(api));

const store = createStore(reducers, middlewareConfig);

export default store;
