import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SiderBar from './Sider';
import HeaderLayout from './Header';
import FooterLayout from './Footer';
import { Layout as AppLayout, Tabs, Modal, Spin, theme } from 'antd';
import { QuestionCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { addTab, removeTab, setGridEditCount } from './redux/modules/layout';
import { useShallowEqualSelector } from '../../helpers/utils';

const { Content, Sider, Header } = AppLayout;
const { TabPane } = Tabs;

const Layout = (props) => {
  const [collapsed, toggleSider] = useState(false);
  const {
    tabs,
    copyRights,
    portalTitle,
    broadcast,
    authorizationRequest,
    dashboardClicked,
    tabCreating,
    defaultAccess,
    portalVersion,
    tourReferences,
  } = useShallowEqualSelector(({ layout, authorization, tour }) => ({
    tabs: layout.tabLayout.tabs,
    copyRights: authorization.initAuthorization.copyRights,
    portalTitle: authorization.initAuthorization.portalTitle,
    broadcast: authorization.initAuthorization.broadcast,
    authorizationRequest: authorization.loading,
    dashboardClicked: layout.contentLayoutSwitched,
    tabCreating: layout.tabCreating,
    defaultAccess: authorization.initAuthorization.defaultAccess,
    portalVersion: authorization.initAuthorization.version,
    tourReferences: tour.tourReferences,
  }));
  const activeKey = useSelector(
    ({ layout: { tabLayout } }) => tabLayout.activeKey
  );
  const gridEditDetails = useSelector(({ layout }) => layout.gridEditDetails);
  const isGridEdited =
    gridEditDetails[activeKey] &&
    gridEditDetails[activeKey]['gridEditCount'] > 0;

  const dispatch = useDispatch();

  const {
    token: { colorBgContainer, colorText, colorBgHeader },
  } = theme.useToken();

  const add = (type, title) => {
    if (tabs.length + 1 > 6) {
      Modal.warning({
        title: 'Too many tabs',
        content:
          'You have tried to open too many tabs. Please close an existing tab and try again.',
      });
    } else {
      dispatch(addTab(type, title));
    }
  };

  const remove = (targetKey) => {
    if (isGridEdited) {
      Modal.confirm({
        title: 'Confirm',
        icon: <QuestionCircleOutlined />,
        content: `You have unsaved changes that will be lost if you continue. Are you sure you want to continue?`,
        onOk: () => {
          dispatch(removeTab(targetKey));
          dispatch(setGridEditCount({ countType: 'reset', tabKey: targetKey }));
        },
        onCancel: () => {
          /* Do nothing */
        },
        cancelText: 'Stay on this page',
        okText: 'Continue',
      });
    } else {
      dispatch(removeTab(targetKey));
    }
  };

  const funcCollection = { remove };
  const onEdit = (targetKey, action) => funcCollection[action](targetKey);
  const onChange = (key) => {
    dispatch({ type: 'layout/SWITCH_TAB_SUCCESS', payload: key });
  };
  const tabExists = tabs && tabs.length > 0;

  return (
    <AppLayout>
      <Sider
        style={{ background: colorBgContainer, color: colorText }}
        trigger={null}
        breakpoint={{ xs: '480px', sm: '576px' }}
        collapsible
        collapsed={collapsed}
        onCollapse={() => toggleSider(!collapsed)}
        collapsedWidth={0}>
        <SiderBar
          addTab={(type, title) => add(type, title)}
          toggle={() => toggleSider(!collapsed)}
          portalTitle={portalTitle}
          collapsed={collapsed}
        />
      </Sider>
      <AppLayout>
        <Header style={{ background: colorBgHeader }}>
          <HeaderLayout
            toggle={() => toggleSider(!collapsed)}
            collapsed={collapsed}
          />
        </Header>
        <Content
          style={{
            margin: 0,
            height: 'calc(100vh - 62px)',
            overflowX: 'hidden',
          }}>
          {tabCreating && (
            <div className='center-align-loader'>
              <Spin size='default' tip={'Creating tab'} />
            </div>
          )}
          <div
            className={`dashboard-content ${
              !dashboardClicked ? 'display-none' : ''
            }`}>
            {tabExists && (
              <Tabs
                hideAdd
                onChange={(key) => onChange(key)}
                activeKey={activeKey}
                type='editable-card'
                className='dashboard-tab'
                onTabClick={() =>
                  dispatch({
                    type: 'layout/SWITCH_CONTENT_LAYOUT_SUCCESS',
                    payload: false,
                  })
                }
                onEdit={(targetKey, action) => onEdit(targetKey, action)}>
                {tabs.map((pane) => (
                  <TabPane tab={pane.title} key={pane.key}></TabPane>
                ))}
              </Tabs>
            )}
            {props.children}
          </div>
          {tabExists && (
            <div
              className={`tab-content ${
                dashboardClicked ? 'display-none' : ''
              }`}>
              <Tabs
                hideAdd
                onChange={(key) => onChange(key)}
                activeKey={activeKey}
                type='editable-card'
                onEdit={(targetKey, action) => onEdit(targetKey, action)}>
                {tabs.map((pane) => (
                  <TabPane
                    tab={<span>{pane.title}</span>}
                    key={pane.key}
                    closeIcon={
                      <CloseOutlined ref={tourReferences.tabCloseTour} />
                    }
                    className={'app-tabpane ' + pane.title.replace(' ', '-')}>
                    {pane.content ? (
                      React.createElement(
                        pane.content,
                        { ...pane, tabKey: pane.key },
                        null
                      )
                    ) : (
                      <div className='center-align-loader'>
                        <Spin size='default' tip={'Loading tab content...'} />
                      </div>
                    )}
                  </TabPane>
                ))}
              </Tabs>
            </div>
          )}
        </Content>
        <FooterLayout
          copyRights={copyRights}
          isLoading={authorizationRequest}
          broadcast={broadcast}
          defaultAccess={defaultAccess}
          portalVersion={portalVersion}
        />
      </AppLayout>
    </AppLayout>
  );
};

Layout.propTypes = { children: PropTypes.element };

export default Layout;
