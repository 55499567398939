import CustomSplitRowDetailRenderer from './CustomSplitRowDetailRenderer';

export default {
  width: 44,
  filter: false,
  sortable: false,
  headerName: '',
  field: 'split',
  suppressMenu: true,
  suppressSizeToFit: true,
  suppressMovable: true,
  suppressNavigable: true,
  suppressColumnsToolPanel: true,
  suppressFiltersToolPanel: true,
  editable: false,
  suppressCellFlash: true,
  resizable: false,
  colId: 'split',
  cellRenderer: CustomSplitRowDetailRenderer,
  lockPosition: true,
};
