import React, { useState, useEffect } from 'react';
import { Layout, Row, Col, theme } from 'antd';
import PulseLoader from 'react-spinners/PulseLoader';
import BroadcastMsg from '../popup/BroadcastMsg';
import SupportDocuments from '../support-document/SupportDocument';
import Cookies from 'js-cookie';

const Footer = (props) => {
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState('');

  const {
    token: { colorText },
  } = theme.useToken();

  const { broadcast, defaultAccess, portalVersion } = props;

  useEffect(() => {
    if (broadcast) {
      broadcast.map((data) => {
        if (
          data.flag &&
          Cookies.get(`${defaultAccess}-broadcast`) == undefined
        ) {
          Cookies.set(`${defaultAccess}-broadcast`, defaultAccess, {
            expires: data.timeInterval / 36000,
          });
          setVisible(true);
          setMessage(data.message);
        }
      });
    }
  }, [broadcast, defaultAccess]);

  return (
    <Row>
      <Col span={24}>
        <Layout.Footer style={{ color: colorText }}>
          <Row>
            <Col
              xs={{ span: 24 }}
              md={{ span: 6 }}
              style={{ textAlign: 'center' }}>
              {portalVersion !== '' && (
                <div className='footer-font-size'>
                  <span>Version {portalVersion}</span>
                </div>
              )}
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 12 }}
              style={{ textAlign: 'center' }}>
              <div className='copyright footer-font-size'>
                {props.isLoading && (
                  <PulseLoader size={6} color={'#FFFFFF'} loading={true} />
                )}
                {props.copyRights ? props.copyRights : null}
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 6 }}
              style={{ textAlign: 'center' }}>
              {defaultAccess !== 'SSP' && <SupportDocuments />}
            </Col>
          </Row>
          <BroadcastMsg
            visibility={visible}
            broadcastMsg={message}
            handleVisiblity={(data) => setVisible(data)}
          />
        </Layout.Footer>
      </Col>
    </Row>
  );
};

export default Footer;
