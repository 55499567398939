import { message } from 'antd';
import { getUserExportDelimiter, getAgGridDisplayedRowsData } from './utils';
import store from '../redux/store';
import { saveAs } from 'file-saver';

const getColKeys = (columnApi, keysToExclude = ['select']) => {
  const columns = columnApi.getAllDisplayedColumns();
  const columnKeys = columns.map((col) => col.colId);
  return columnKeys.filter((key) => !keysToExclude.includes(key));
};

const handleExcelExport = (args) => {
  const { columnApi, gridApi, fileName, data, grid } = args;
  let response = false;
  if (grid) {
    response = exportAgGridDataAsExcel(gridApi, fileName, columnApi);
    return response;
  }

  response = exportAsExcel(data, fileName, columnApi);

  return response;
};

const handleCsvExport = (args) => {
  const { columnApi, gridApi, fileName, data, grid } = args;
  let response = false;
  if (grid) {
    response = exportAgGridDataAsCsv(gridApi, fileName, columnApi);
    return response;
  }

  response = exportAsCsv(data, fileName, columnApi);

  return response;
};

const handleTextExport = (args) => {
  const { columnApi, gridApi, fileName, data, grid } = args;
  let response = false;
  if (grid) {
    const getGridData = getAgGridDisplayedRowsData(gridApi);
    let gridOutput = [];
    getGridData.forEach((obj) => {
      if (obj.errors !== undefined) {
        const { errors, hasError, isEditing, ...rest } = obj;
        gridOutput.push(rest);
      } else {
        gridOutput.push(obj);
      }
    });

    response = exportAsTxt(gridOutput, fileName, columnApi);
    return response;
  }

  response = exportAsTxt(data, fileName, columnApi);

  return response;
};

function exportDTo(args) {
  const { columnApi, gridApi, fileName, exportType, grid } = args;
  let response = false;

  if (fileName === 'ACP_users') {
    // ACP UMS file download
    exportDToAcp({ gridApi, fileName, columnApi, exportType, grid });
  } else {
    if (exportType === 'excel') {
      return handleExcelExport(args);
    } else if (exportType === 'csv') {
      return handleCsvExport(args);
    } else if (exportType === 'txt') {
      return handleTextExport(args);
    } else {
      return response;
    }
  }
}

const getAcpStatusValue = (obj) =>
  obj.status.value && obj.status.value !== undefined ? obj.status.value : null;

function exportDToAcp(args) {
  const { columnApi, gridApi, fileName, exportType, grid } = args;
  let response = false;
  if (exportType === 'excel') {
    if (grid) {
      response = exportAgGridDataAsExcelAcp(gridApi, fileName);
      return response;
    }
  } else if (exportType === 'csv') {
    if (grid) {
      response = exportAgGridDataAsCsvAcp(gridApi, fileName);
      return response;
    }
  } else if (exportType === 'txt') {
    if (grid) {
      const getGridData = getAgGridDisplayedRowsData(gridApi);
      let gridOutput = [];
      getGridData.forEach((obj) => {
        if (obj.errors !== undefined) {
          const { errors, hasError, isEditing, ...rest } = obj;
          gridOutput.push(rest);
        } else {
          obj.status = getAcpStatusValue(obj);
          gridOutput.push(obj);
        }
      });

      response = exportAsTxt(gridOutput, fileName, columnApi);
      return response;
    }
  }
  return response;
}

const modifyRow = (dataType, row, prop, rowModified) => {
  if (dataType === 'number') {
    return {
      ...rowModified,
      [prop]: row[prop] !== '' ? parseFloat(row[prop]) : '',
    };
  } else {
    return { ...rowModified, [prop]: row[prop] };
  }
};

function parseData(data, columnApi) {
  let parsedData = [];
  if (data.length) {
    data.map((row) => {
      if (Object.getOwnPropertyNames(row).length) {
        let rowModified = {};
        for (let prop in row) {
          const colInstance = columnApi.getColumn(prop);
          if (colInstance) {
            const dataType = colInstance.userProvidedColDef.type;
            rowModified = modifyRow(dataType, row, prop, rowModified);
          }
        }

        parsedData.push(rowModified);
      }
    });
  }

  return parsedData;
}

function getExportOutput(data, columnApi, exportType, fileName) {
  return import(/* webpackChunkName: "xlsx-lib" */ 'xlsx')
    .then(({ utils, write }) => {
      const parsedData = parseData(data, columnApi);
      const ws = utils.json_to_sheet(parsedData);
      let range = utils.decode_range(ws['!ref']);
      for (let i = range.s.c; i <= range.e.c; ++i) {
        let address = utils.encode_col(i) + '1'; // <-- first row, column number i
        if (!ws[address]) continue;
        ws[address].v = columnApi.getColumn(
          ws[address].v
        ).userProvidedColDef.headerName;
      }

      let output = null;
      if (exportType == 'xlsx') {
        const fileType =
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
        const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });
        output = new Blob([excelBuffer], { type: fileType });
      } else if (exportType == 'csv') {
        const fileType = 'text/csv;charset=utf-8';
        const csvBuffer = utils.sheet_to_csv(ws, {
          FS: getUserExportDelimiter(store),
        });
        output = new Blob([csvBuffer], { type: fileType });
      } else if (exportType == 'txt') {
        const fileType = 'text/plain;charset=UTF-8';
        const csvData = utils.sheet_to_csv(ws, {
          FS: getUserExportDelimiter(store),
        });
        // Replace quotes(""") around the string with "
        const textBuffer = csvData.replace(/"""/g, '"').replace(/""/, "'");
        output = new Blob([textBuffer], { type: fileType });
      }

      if (output) {
        saveAs(output, `${fileName}.${exportType}`);
      }
    })
    .catch(() => {
      message.error('Something went wrong. Please try again!');
    });
}

function exportAsCsv(data, fileName, columnApi) {
  return getExportOutput(data, columnApi, 'csv', fileName);
}

function exportAsExcel(data, fileName, columnApi) {
  return getExportOutput(data, columnApi, 'xlsx', fileName);
}

function exportAsTxt(data, fileName, columnApi) {
  return getExportOutput(data, columnApi, 'txt', fileName);
}

function exportAgGridDataAsExcel(api, fileName, columnApi) {
  const columnKeys = getColKeys(columnApi);
  api.exportDataAsExcel({
    fileName: fileName,
    sheetName: 'data',
    author: 'Sanmina',
    columnKeys,
  });

  return true;
}

const processCellCallback = (cell) => {
  if (cell.value && typeof cell.value === 'object' && cell.value !== null) {
    return cell.value.value;
  } else {
    return cell.value;
  }
};

function exportAgGridDataAsExcelAcp(api, fileName) {
  api.exportDataAsExcel({
    fileName: fileName,
    sheetName: 'data',
    author: 'Sanmina',
    processCellCallback: processCellCallback,
  });
  return true;
}

function exportAgGridDataAsCsv(api, fileName, columnApi) {
  const columnKeys = getColKeys(columnApi);
  api.exportDataAsCsv({
    fileName: fileName,
    columnKeys,
  });

  return true;
}

function exportAgGridDataAsCsvAcp(api, fileName) {
  api.exportDataAsCsv({
    fileName: fileName,
    processCellCallback: processCellCallback,
  });

  return true;
}

const getMassDownloadGridParams = (gridApi, columnApi) => {
  const columnKeys = getColKeys(columnApi);
  return {
    filterModel: gridApi.getFilterModel(),
    sortModel: columnApi
      .getColumnState()
      .filter((data) => data.sort)
      .map((data) => ({ colId: data.colId, sort: data.sort })),
    columnKeys,
  };
};

const exportDvrConsumptionReport = async (data, columns, handleVisibility) => {
  const {
    dailyConsumptionReportCols,
    dailyVendorReportCols,
    dailyConsignedReceiptsCols,
  } = columns;
  const { dailyConsumptionReport, dailyVendorReport, dailyConsignedReceipts } =
    data;
  const { utils, write } = await import(
    /* webpackChunkName: "xlsx-lib" */ 'xlsx'
  );
  const wsData1 = dailyConsumptionReport;
  const wsData2 = dailyVendorReport;
  const wsData3 = dailyConsignedReceipts;

  const ws1 = utils.json_to_sheet(wsData1);
  const range1 = utils.decode_range(ws1['!ref']);
  for (let i = range1.s.c; i <= range1.e.c; ++i) {
    let address = utils.encode_col(i) + '1';
    if (!ws1[address]) continue;
    const colId = ws1[address].v;
    ws1[address].v = dailyConsumptionReportCols[0][colId];
  }

  const ws2 = utils.json_to_sheet(wsData2);
  const range2 = utils.decode_range(ws2['!ref']);
  for (let i_1 = range2.s.c; i_1 <= range2.e.c; ++i_1) {
    let address_1 = utils.encode_col(i_1) + '1';
    if (!ws2[address_1]) continue;
    const colId_1 = ws2[address_1].v;
    ws2[address_1].v = dailyVendorReportCols[0][colId_1];
  }

  const ws3 = utils.json_to_sheet(wsData3);
  const range3 = utils.decode_range(ws2['!ref']);
  for (let i_2 = range3.s.c; i_2 <= range3.e.c; ++i_2) {
    let address_2 = utils.encode_col(i_2) + '1';
    if (!ws3[address_2]) continue;
    const colId_2 = ws3[address_2].v;
    ws3[address_2].v = dailyConsignedReceiptsCols[0][colId_2];
  }

  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws1, 'Daily Consumptions Report');
  utils.book_append_sheet(wb, ws2, 'Daily Vendor Report');
  utils.book_append_sheet(wb, ws3, 'Daily Consigned Receipts');

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });
  const output = new Blob([excelBuffer], { type: fileType });

  if (output) {
    saveAs(output, `Daily_Vendor_Report.xlsx`);
    handleVisibility();
  }
};

export {
  exportDTo,
  exportDToAcp,
  exportAsCsv,
  exportAsExcel,
  exportAsTxt,
  exportAgGridDataAsCsv,
  exportAgGridDataAsExcel,
  getMassDownloadGridParams,
  exportDvrConsumptionReport,
};
