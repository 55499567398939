import { combineReducers } from 'redux';
import dashboard from '../../dashboard/redux/dashboard';
import reportHistory from '../../report-history/redux/report-history';
import exportHistory from '../../export-history/redux/export-history';
import newReport from '../../new-report/redux/new-report';
import updateReport from '../../update-report/redux/new-report';
import reportData from '../../dynamic-report/redux/report-redux';
import reportSettings from '../../report-settings/redux/report-settings';
import orderStatus from '../../order-status/redux/order-status';
import orderReports from '../../order-reports/redux/order-reports';
import orderLines from '../../order-lines/redux/order-lines';
import orderHistory from '../../order-history/redux/order-history';

const reducers = combineReducers({
  dashboard,
  reportHistory,
  exportHistory,
  newReport,
  reportData,
  reportSettings,
  updateReport,
  orderStatus,
  orderHistory,
  orderLines,
  orderReports,
});

export default reducers;
