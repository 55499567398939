import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import ThemeProvider from './theme/ThemeProvider';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import './assets/sass/main.scss';

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider />
    </Provider>
  );
};

export default App;
