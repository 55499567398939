import Cookie from 'js-cookie';

export const SSP_GUIDELINES_LIST_INIT =
  'ssp/guidelines/SSP_GUIDELINES_LIST_INIT';
export const SSP_GUIDELINES_LIST_SUCCESS =
  'ssp/guidelines/SSP_GUIDELINES_LIST_SUCCESS';
export const SSP_GUIDELINES_LIST_ERROR =
  'ssp/guidelines/SSP_GUIDELINES_LIST_ERROR';
export const SSP_GUIDELINES_FETCH_DOC_INIT =
  'ssp/guidelines/SSP_GUIDELINES_FETCH_DOC_INIT';
export const SSP_GUIDELINES_FETCH_DOC_SUCCESS =
  'ssp/guidelines/SSP_GUIDELINES_FETCH_DOC_SUCCESS';
export const SSP_GUIDELINES_FETCH_DOC_ERROR =
  'ssp/guidelines/SSP_GUIDELINES_FETCH_DOC_ERROR';
export const DESTROY_SSP_GUIDELINES_BY_KEY =
  'ssp/guidelines/DESTROY_SSP_GUIDELINES_BY_KEY';

const initialState = {
  newTab0: {
    docsList: [],
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SSP_GUIDELINES_LIST_INIT:
      return {
        ...state,
        [action.tabKey]: { ...state[action.tabKey], isListLoading: true },
      };
    case SSP_GUIDELINES_LIST_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          isListLoading: false,
          docsList: action.payload,
        },
      };
    case SSP_GUIDELINES_LIST_ERROR:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          isListLoading: false,
          docsListError: action.errors,
        },
      };
    case SSP_GUIDELINES_FETCH_DOC_INIT:
      return {
        ...state,
        [action.tabKey]: { ...state[action.tabKey], isDocLoading: true },
      };
    case SSP_GUIDELINES_FETCH_DOC_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          isDocLoading: false,
          base64String: action.payload,
        },
      };
    case SSP_GUIDELINES_FETCH_DOC_ERROR:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          isDocLoading: false,
          docFetchError: action.errors,
        },
      };
    case DESTROY_SSP_GUIDELINES_BY_KEY:
      delete state[action.tabKey];
      return state;
    default:
      return state;
  }
}

const getGuidelinesDocsList = (tabKey) => {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: SSP_GUIDELINES_LIST_INIT, tabKey });
    return api
      .post(
        'ssp',
        formatParams(
          {
            type: 'SSP_GUIDELINES_LIST',
            details: {
              email: Cookie.get('email'),
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data && data.statusCode == '200') {
          const result = data.result;
          dispatch({
            type: SSP_GUIDELINES_LIST_SUCCESS,
            payload: result,
            tabKey,
          });
        } else {
          dispatch({
            type: SSP_GUIDELINES_LIST_ERROR,
            payload: 'Error in loading guideline document list!',
            tabKey,
          });
        }
      });
  };
};

const getDocument = (id, tabKey) => {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: SSP_GUIDELINES_FETCH_DOC_INIT, tabKey });
    return api
      .post(
        'ssp',
        formatParams(
          {
            type: 'SSP_GUIDELINES_DOC',
            details: { email: Cookie.get('email'), id },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data && data.statusCode == '200') {
          dispatch({
            type: SSP_GUIDELINES_FETCH_DOC_SUCCESS,
            payload: data.result,
            tabKey,
          });
        } else {
          dispatch({
            type: SSP_GUIDELINES_FETCH_DOC_ERROR,
            payload: 'Error in loading document!',
            tabKey,
          });
        }
      });
  };
};

const destroyGuidelinesByKey = (tabKey) => {
  return (dispatch) =>
    dispatch({ type: DESTROY_SSP_GUIDELINES_BY_KEY, tabKey });
};

export { getGuidelinesDocsList, getDocument, destroyGuidelinesByKey };
