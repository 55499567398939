/**
 * Created this file to get the details of file name and file link for Download ACP excel template using "Excel Download" menu option.
 */
/* Importing supports files  */
import Cookies from 'js-cookie';
/* Defining the Reducers */
const EXCEL_TEMPLATE = 'excel-template/EXCEL_TEMPLATE';
const EXCEL_TEMPLATE_SUCCESS = 'excel-template/EXCEL_TEMPLATE_SUCCESS';
const EXCEL_TEMPLATE_FAIL = 'excel-template/EXCEL_TEMPLATE_FAIL';
const initialState = {
  data: {
    excelDownload: [],
  },
};
/* Defining the Reducers and its return statement*/
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case EXCEL_TEMPLATE:
      return { ...state, loading: true };
    case EXCEL_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: null,
      };
    case EXCEL_TEMPLATE_FAIL:
      return { ...state, loading: false };
    default:
      return state;
  }
}

/* Defining the Api service call to fetch the details of the download excel templates based on the user access */
export function getExcelTemplateList() {
  return (dispatch, getState, { api, formatParams }) => {
    const authorized = Object.keys(
      getState().authorization.initAuthorization
    ).length;
    dispatch({ type: EXCEL_TEMPLATE });
    const email = Cookies.get('email');
    if (authorized) {
      const { defaultAccess } = getState().authorization.initAuthorization;
      return api
        .post(
          defaultAccess.toLowerCase(),
          formatParams({ type: 'EXCEL_TEMPLATE', email: email }, getState)
        )
        .then((response) => {
          dispatch({
            type: EXCEL_TEMPLATE_SUCCESS,
            payload: response.data,
          });

          return response;
        });
    }
  };
}
