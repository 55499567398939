import service from '../../../../service/Service';
import store from '../../../../redux/store';
import {
  exportDTo,
  getMassDownloadGridParams,
} from '../../../../helpers/export';
import {
  getLastRowIndex,
  getUUID,
  gaPageviewTimingTitle,
  getEpochTime,
} from '../../../../helpers/utils';
import Cookies from 'js-cookie';

export const TEST_LOGS_COLUMNS = 'ssp/testlogs/TEST_LOGS_COLUMNS';
export const TEST_LOGS_COLUMNS_SUCCESS =
  'ssp/testlogs/TEST_LOGS_COLUMNS_SUCCESS';
export const TEST_LOGS_COLUMNS_FAIL = 'ssp/testlogs/TEST_LOGS_COLUMNS_FAIL';
export const TEST_LOGS_ROWS_INFO_SUCCESS =
  'ssp/testlogs/TEST_LOGS_ROWS_INFO_SUCCESS';

export const TESTLOGS_ACKNOWLEGDE_DETAILS =
  'ssp/testlogs/TESTLOGS_ACKNOWLEGDE_DETAILS';
export const TESTLOGS_ACKNOWLEGDE_DETAILS_SUCCESS =
  'ssp/testlogs/TESTLOGS_ACKNOWLEGDE_DETAILS_SUCCESS';
export const TESTLOGS_ACKNOWLEGDE_DETAILS_FAIL =
  'ssp/testlogs/TESTLOGS_ACKNOWLEGDE_DETAILS_FAIL';

const initialState = {
  loaded: false,
  submittingItem: false,
  testLogsColumns: {
    rowModelType: null,
  },
  rowsInfo: {
    endRow: 0,
    lastRow: 0,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case TEST_LOGS_COLUMNS:
      return { ...state, loading: true };
    case TEST_LOGS_COLUMNS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        testLogsColumns: action.payload,
        error: null,
      };
    case TEST_LOGS_COLUMNS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case TEST_LOGS_ROWS_INFO_SUCCESS:
      return { ...state, rowsInfo: action.payload };

    case TESTLOGS_ACKNOWLEGDE_DETAILS:
      return { ...state, submittingItem: true };
    case TESTLOGS_ACKNOWLEGDE_DETAILS_SUCCESS:
      return { ...state, submittingItem: false, submittedItem: true };
    case TESTLOGS_ACKNOWLEGDE_DETAILS_FAIL:
      return { ...state, submittingItem: false };

    default:
      return state;
  }
}

export function getColumns() {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: TEST_LOGS_COLUMNS });
    const currentTime = getEpochTime();
    return api
      .post(
        'ssp',
        formatParams(
          {
            type: 'AG_GRID_COLUMNS',
            view: 'TEST_LOGS',
            details: {
              email: Cookies.get('email'),
            },
          },
          getState
        )
      )
      .then((response) => {
        gaPageviewTimingTitle(`/SSP/Testlogs/getTestLogsColumns`, currentTime);
        const { data } = response;
        if (data && data.statusCode === '200') {
          dispatch({
            type: TEST_LOGS_COLUMNS_SUCCESS,
            payload: data.result,
          });
        } else {
          dispatch({
            type: TEST_LOGS_COLUMNS_FAIL,
            payload: 'Error in loading Open View!',
          });
        }
      });
  };
}

export function getRows() {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      params.api.showLoadingOverlay();
      const currentTime = getEpochTime();
      return api
        .post(
          'ssp',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: 'TEST_LOGS',
              gridParams: { ...params.request },
              details: { email: Cookies.get('email') },
            },
            store.getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(`/SSP/Testlogs/getTestLogsRows`, currentTime);
          const { data } = response;
          if (Object.keys(data).length) {
            params.success({
              rowData: data.result.rowData,
              rowCount: getLastRowIndex(params.request, data.result.rowData),
            });
            const endRow =
              params.request.endRow > data.result.lastRow
                ? data.result.lastRow
                : params.request.endRow;
            store.dispatch({
              type: TEST_LOGS_ROWS_INFO_SUCCESS,
              payload: { lastRow: data.result.lastRow, endRow },
            });
            // Hide overlay loading on success
            params.api.hideOverlay();
            const rowsPerRequest = process.env.REACT_APP_AG_GRID_ROWS_LIMIT;
            if (
              !data.result.rowData.length &&
              params.request.startRow < rowsPerRequest
            ) {
              params.api.showNoRowsOverlay();
            }
          } else {
            params.fail();
          }
        })
        .catch(() => {
          params.fail();
        });
    },
  };
}

export function getMassDownloadReport(
  gridApi,
  exportType,
  totalRows,
  cb,
  columnApi
) {
  return (_dispatch, getState, { api, formatParams }) => {
    if (totalRows >= process.env.REACT_APP_MASS_DOWNLOAD_ENABLE_LIMIT) {
      return api
        .post(
          'ssp',
          formatParams(
            {
              type: 'MASS_DOWNLOAD',
              view: 'TEST_LOGS',
              exportType,
              totalRows,
              email: Cookies.get('email'),
              gridParams: {
                ...getMassDownloadGridParams(gridApi, columnApi),
              },
            },
            getState
          )
        )
        .then((response) => {
          cb(true);
          return response;
        })
        .catch((error) => {
          cb(false);
          return error;
        });
    } else {
      const currentTime = getEpochTime();
      return api
        .post(
          'ssp',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: 'TEST_LOGS',
              gridParams: {
                ...getMassDownloadGridParams(gridApi, columnApi),
                startRow: 0,
                endRow: totalRows,
              },
              details: { email: Cookies.get('email') },
            },
            store.getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(
            `/SSP/Testlogs/getMassDownloadReport`,
            currentTime
          );
          const { data } = response;
          if (Object.keys(data).length) {
            exportDTo({
              columnApi,
              fileName: 'SSP_TEST_LOGS_REPORT',
              data: data.result.rowData,
              exportType,
            }) && cb(true);
          }

          return response;
        });
    }
  };
}

export function updateAcknowledgeDetails(updatedData) {
  return (dispatch, _getState, { api }) => {
    dispatch({ type: TESTLOGS_ACKNOWLEGDE_DETAILS });
    const currentTime = getEpochTime();
    return api
      .post('sspsubmitaction', {
        TXN: [
          {
            transaction_type: 'LOG_FORM_ACK',
            cust_code: 'SANM',
            requestor: Cookies.get('email'),
            source: 'PORTAL',
            uuid: getUUID(),
            details: updatedData,
          },
        ],
      })
      .then((response) => {
        gaPageviewTimingTitle(
          `/SSP/Testlogs/updateAcknowledgeDetails`,
          currentTime
        );
        const { data } = response;
        const TXNGET = data.TXN;
        if (data && data.statusCode === '200') {
          if (TXNGET[0].return_msg === 'SUCCESS') {
            dispatch(update());
          } else {
            dispatch({
              type: TESTLOGS_ACKNOWLEGDE_DETAILS_FAIL,
              payload: 'Error in updating user',
            });
          }
        } else {
          dispatch({
            type: TESTLOGS_ACKNOWLEGDE_DETAILS_FAIL,
            payload: 'Error in updating user',
          });
        }
        return TXNGET[0].return_msg;
      });
  };
  function update() {
    return { type: TESTLOGS_ACKNOWLEGDE_DETAILS_SUCCESS };
  }
}
