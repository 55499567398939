import { gaPageviewTimingTitle, getEpochTime } from '../../../../helpers/utils';
const DASHBOARD = 'cpt/dashboard/DASBOARD';
const DASHBOARD_SUCCESS = 'cpt/dashboard/DASHBOARD_SUCCESS';
const DASHBOARD_FAIL = 'cpt/dashboard/DASHBOARD_FAIL';

const initialState = {
  loaded: false,
  data: {},
  error: null,
  loading: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case DASHBOARD:
      return { ...state, loading: true };
    case DASHBOARD_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        data: action.payload,
        error: null,
      };
    case DASHBOARD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error,
      };
    default:
      return state;
  }
}

export function getDashboardDetails() {
  return (dispatch, getState, { api, formatParams }) => {
    const authorized = Object.keys(getState().authorization.initAuthorization)
      .length;
    if (authorized) {
      dispatch({ type: DASHBOARD });
      const currentTime = getEpochTime();
      return api
        .post(
          'cpt',
          formatParams(
            {
              type: 'dashboard',
            },
            getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(`/CPT/DASHBOARD`,currentTime);
          const { data } = response;
          if (data) {
            dispatch({ type: DASHBOARD_SUCCESS, payload: data });
          }

          return response;
        })
        .catch((error) => {
          dispatch({
            type: DASHBOARD_FAIL,
            error,
          });
        });
    }
  };
}
