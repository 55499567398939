import { combineReducers } from 'redux';
import user from './profile';
import mfa from './mfa';

const reducers = combineReducers({
  user,
  mfa,
});

export default reducers;
