import Cookies from 'js-cookie';
import { getUUID } from '../../../../helpers/utils';
const NEW_REPORT = 'cds/new-report/NEW_REPORT';
const GET_CUSTOMERCODE_SUCCESS = 'cds/new-report/GET_CUSTOMERCODE_SUCCESS';
const GET_CUSTOMERCODE_FAIL = 'cds/new-report/GET_CUSTOMERCODE_FAIL';
const GET_CURRENTPLANTS_SUCCESS = 'cds/new-report/GET_CURRENTPLANTS_SUCCESS';
const CDS_CUSTOMER_ADD_SUCCESS = 'cds/new-report/CDS_CUSTOMER_ADD_SUCCESS';
const CDS_COLUMN_ADD_SUCCESS = 'cds/new-report/CDS_COLUMN_ADD_SUCCESS';
const REPORT_FREQUENCY_SUCCESS = 'cds/new-report/REPORT_FREQUENCY_SUCCESS';
const SET_CURRENTSTEPS_SUCCESS = 'cds/new-report/SET_CURRENTSTEPS_SUCCESS';
const GET_CURRENTPLANTS_FAIL = 'cds/new-report/GET_CURRENTPLANTS_FAIL';
const VIEW_COLUMNS = 'cds/new-report/VIEW_COLUMNS';
const VIEW_COLUMNS_SUCCESS = 'cds/new-report/VIEW_COLUMNS_SUCCESS';
const VIEW_COLUMNS_SAMPLE_SUCCESS =
  'cds/new-report/VIEW_COLUMNS_SAMPLE_SUCCESS';
const VIEW_COLUMNS_SAMPLE_FAIL = 'cds/new-report/VIEW_COLUMNS_SAMPLE_FAIL';
const REPORT_COLUMNS_SUCCESS = 'cds/new-report/REPORT_COLUMNS_SUCCESS';
const REPORT_CUSTOMERS_SUCCESS = 'cds/new-report/REPORT_CUSTOMERS_SUCCESS';
const REPORT_CUSTOMERS_INDEX_SUCCESS =
  'cds/new-report/REPORT_CUSTOMERS_INDEX_SUCCESS';
const REPORT_COMMUNICATION_SUCCESS =
  'cds/new-report/REPORT_COMMUNICATION_SUCCESS';
const REPORT_REFRESH_SUCCESS = 'cds/new-report/REPORT_REFRESH_SUCCESS';
const VIEW_COLUMNS_FAIL = 'cds/new-report/VIEW_COLUMNS_FAIL';
const CREATE_REPORT_SUCCESS = 'cds/new-report/CREATE_REPORT_SUCCESS';
const CREATE_REPORT_FAIL = 'cds/new-report/CREATE_REPORT_FAIL';
const CDS_REFRESH_VALIDATE_SUCCESS =
  'cds/new-report/CDS_REFRESH_VALIDATE_SUCCESS';
const CDS_REFRESH_VALIDATE_FAIL = 'cds/new-report/CDS_REFRESH_VALIDATE_FAIL';
const CDS_CUSTOMER_VALIDATE_SUCCESS =
  'cds/new-report/CDS_CUSTOMER_VALIDATE_SUCCESS';
const CDS_CUSTOMER_VALIDATE_FAIL = 'cds/new-report/CDS_CUSTOMER_VALIDATE_FAIL';
const REPORT_SUBMIT_SUCCESS = 'cds/new-report/REPORT_SUBMIT_SUCCESS';
const GET_CURRENTINPUTSOURCE_SUCCESS =
  'cds/new-report/GET_CURRENTINPUTSOURCE_SUCCESS';
const GET_CURRENTINPUTSOURCE_FAIL =
  'cds/new-report/GET_CURRENTINPUTSOURCE_FAIL';
const CDS_REPORT_VALIDATE_SUCCESS =
  'cds/new-report/CDS_REPORT_VALIDATE_SUCCESS';
const CDS_REPORT_VALIDATE_FAIL = 'cds/new-report/CDS_REPORT_VALIDATE_FAIL';
const SET_CHANGE_FORM_SUCCESS_SETUP =
  'cds/new-report/SET_CHANGE_FORM_SUCCESS_SETUP';
const SET_CHANGE_FORM_SUCCESS_COLUMN =
  'cds/new-report/SET_CHANGE_FORM_SUCCESS_COLUMN';
const SET_CHANGE_FORM_SUCCESS_REFRESH =
  'cds/new-report/SET_CHANGE_FORM_SUCCESS_REFRESH';
const SET_CHANGE_FORM_SUCCESS_CUSTOMER =
  'cds/new-report/SET_CHANGE_FORM_SUCCESS_CUSTOMER';
const SET_CHANGE_FORM_SUCCESS_COMMUNICATION =
  'cds/new-report/SET_CHANGE_FORM_SUCCESS_COMMUNICATION';

const initialState = {
  newTab0: {
    currentStep: 0,
    viewedSample: false,
    customerDropdownData: [],
    currentTabPlants: [],
    currentTabInputSource: [],
    customerDetailsdata: [],
    customerColumndata: [],
    customerColumndataCount: 0,
    reportFrequncy: [],
    reportSetupData: {},
    reportColumnData: {},
    reportRefreshData: {},
    reportCustomersData: {},
    reportCustomersDataIndex: [],
    reportCommunicationData: {},
    reportDrop: 'N',
    reportCrNo: '',
    reportChangeSetup: false,
    reportChangeColumn: false,
    reportChangeRefresh: false,
    reportChangeCustomer: false,
    reportChangeCommunication: false,
    reportExist: 'No',
    loading: false,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case NEW_REPORT:
      return { ...state, loading: true };
    case GET_CURRENTPLANTS_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          currentTabPlants: action.payload,
          error: null,
        },
      };
    case GET_CURRENTINPUTSOURCE_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          currentTabInputSource: action.payload,
          error: null,
        },
      };
    case SET_CURRENTSTEPS_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          currentStep: action.payload,
          error: null,
        },
      };
    case SET_CHANGE_FORM_SUCCESS_SETUP:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          reportChangeSetup: action.payload,
          error: null,
        },
      };
    case SET_CHANGE_FORM_SUCCESS_COLUMN:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          reportChangeColumn: action.payload,
          error: null,
        },
      };
    case SET_CHANGE_FORM_SUCCESS_REFRESH:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          reportChangeRefresh: action.payload,
          error: null,
        },
      };
    case SET_CHANGE_FORM_SUCCESS_CUSTOMER:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          reportChangeCustomer: action.payload,
          error: null,
        },
      };
    case SET_CHANGE_FORM_SUCCESS_COMMUNICATION:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          reportChangeCommunication: action.payload,
          error: null,
        },
      };
    case CREATE_REPORT_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
        },
      };
    case CREATE_REPORT_FAIL:
    case GET_CURRENTPLANTS_FAIL:
    case GET_CUSTOMERCODE_FAIL:
    case VIEW_COLUMNS_SAMPLE_FAIL:
    case CDS_CUSTOMER_VALIDATE_FAIL:
    case CDS_REFRESH_VALIDATE_FAIL:
    case VIEW_COLUMNS_FAIL:
    case GET_CURRENTPLANTS_FAIL:
    case GET_CURRENTINPUTSOURCE_FAIL:
    case CDS_REPORT_VALIDATE_FAIL:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          error: action.payload,
        },
      };
    case GET_CUSTOMERCODE_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          customerDropdownData: action.payload,
          error: null,
        },
      };
    case CDS_CUSTOMER_ADD_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          customerDetailsdata: action.payload,
          error: null,
        },
      };
    case CDS_COLUMN_ADD_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          reportSetupData: {
            ...state[action.tabKey].reportSetupData,
            ColumnSelection: action.payload,
          },
          customerColumndataCount: action.count,
          error: null,
        },
      };
    case REPORT_FREQUENCY_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          reportFrequncy: action.payload,
          error: null,
        },
      };
    case VIEW_COLUMNS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: true,
          error: null,
        },
      };
    case VIEW_COLUMNS_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          reportSetupData: action.payload,
          error: null,
          viewedSample: false,
        },
      };
    case VIEW_COLUMNS_SAMPLE_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          viewedSample: true,
          error: null,
        },
      };
    case REPORT_REFRESH_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          reportRefreshData: action.payload,
          error: null,
        },
      };
    case CDS_REFRESH_VALIDATE_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          reportRefreshData: {
            ...state[action.tabKey].reportRefreshData,
            validation: action.payload,
          },
          error: null,
        },
      };
    case CDS_CUSTOMER_VALIDATE_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          reportCustomersData: {
            ...state[action.tabKey].reportCustomersData,
            validation: action.payload,
          },
          error: null,
        },
      };
    case REPORT_CUSTOMERS_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          reportCustomersData: action.payload,
          error: null,
        },
      };
    case REPORT_CUSTOMERS_INDEX_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          reportCustomersDataIndex: action.payload,
          error: null,
        },
      };
    case REPORT_COMMUNICATION_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          reportCommunicationData: action.payload,
          error: null,
        },
      };
    case REPORT_COLUMNS_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          reportColumnData: action.payload,
          reportSetupData: {
            ...state[action.tabKey].reportSetupData,
            ColumnSelection: action.dataLoad,
          },
          error: null,
        },
      };
    case REPORT_SUBMIT_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          reportDrop: action.payloadDrop,
          reportCrNo: action.payloadCrNo,
          error: null,
        },
      };
    case CDS_REPORT_VALIDATE_SUCCESS:
      return {
        ...state,
        [action.tabKey]: {
          ...state[action.tabKey],
          loading: false,
          reportExist: action.payload,
          error: null,
        },
      };
    default:
      return state;
  }
}

export function addCurrentTabPlants(currentTabPlants, targetKey) {
  return (dispatch) => {
    if (currentTabPlants) {
      dispatch({
        type: GET_CURRENTPLANTS_SUCCESS,
        tabKey: targetKey,
        payload: currentTabPlants,
      });
    } else {
      dispatch({
        type: GET_CURRENTPLANTS_FAIL,
        tabKey: targetKey,
        payload: 'Something went wrong!',
      });
    }
  };
}
export function addCurrentTabInputSource(currentTabInputSource, targetKey) {
  return (dispatch) => {
    if (currentTabInputSource) {
      dispatch({
        type: GET_CURRENTINPUTSOURCE_SUCCESS,
        tabKey: targetKey,
        payload: currentTabInputSource,
      });
    } else {
      dispatch({
        type: GET_CURRENTINPUTSOURCE_FAIL,
        tabKey: targetKey,
        payload: 'Something went wrong!',
      });
    }
  };
}
export function setCurrentSteps(currentStep, targetKey) {
  return (dispatch, _getState) => {
    if (currentStep >= 0) {
      dispatch({
        type: SET_CURRENTSTEPS_SUCCESS,
        tabKey: targetKey,
        payload: currentStep,
      });
    }
  };
}
export function setFormChangeSetup(status, targetKey) {
  return (dispatch, _getState) => {
    if (status) {
      dispatch({
        type: SET_CHANGE_FORM_SUCCESS_SETUP,
        tabKey: targetKey,
        payload: status,
      });
    }
  };
}
export function setFormChangeColumn(status, targetKey) {
  return (dispatch, _getState) => {
    if (status) {
      dispatch({
        type: SET_CHANGE_FORM_SUCCESS_COLUMN,
        tabKey: targetKey,
        payload: status,
      });
    }
  };
}
export function setFormChangeRefresh(status, targetKey) {
  return (dispatch, _getState) => {
    if (status) {
      dispatch({
        type: SET_CHANGE_FORM_SUCCESS_REFRESH,
        tabKey: targetKey,
        payload: status,
      });
    }
  };
}
export function setFormChangeCustomer(status, targetKey) {
  return (dispatch, _getState) => {
    if (status) {
      dispatch({
        type: SET_CHANGE_FORM_SUCCESS_CUSTOMER,
        tabKey: targetKey,
        payload: status,
      });
    }
  };
}
export function setFormChangeCommunication(status, targetKey) {
  return (dispatch, _getState) => {
    if (status) {
      dispatch({
        type: SET_CHANGE_FORM_SUCCESS_COMMUNICATION,
        tabKey: targetKey,
        payload: status,
      });
    }
  };
}
export function customerDetailsAdd(formattedValues, targetKey) {
  return (dispatch) => {
    dispatch({
      type: CDS_CUSTOMER_ADD_SUCCESS,
      tabKey: targetKey,
      payload: formattedValues,
    });
  };
}

export function customerColumnAdd(formattedValues, getCount, targetKey) {
  return (dispatch) => {
    dispatch({
      type: CDS_COLUMN_ADD_SUCCESS,
      tabKey: targetKey,
      payload: formattedValues,
      count: getCount,
    });
  };
}

export function reportColumnAdd(formattedValues, targetKey) {
  return (dispatch) => {
    dispatch({
      type: REPORT_COLUMNS_SUCCESS,
      tabKey: targetKey,
      payload: formattedValues,
      dataLoad: formattedValues.columnDetailsStore,
    });
  };
}
export function reportSubmitUpdate(formattedValues, targetKey) {
  return (dispatch, _getState) => {
    dispatch({
      type: REPORT_SUBMIT_SUCCESS,
      tabKey: targetKey,
      payloadDrop: formattedValues.reportDrop,
      payloadCrNo: formattedValues.crNo,
    });
  };
}
export function reportFrequencyAdd(formattedValues, targetKey) {
  return (dispatch) => {
    dispatch({
      type: REPORT_FREQUENCY_SUCCESS,
      tabKey: targetKey,
      payload: formattedValues,
    });
  };
}

export function reportRefreshAdd(formattedValues, targetKey) {
  return (dispatch) => {
    dispatch({
      type: REPORT_REFRESH_SUCCESS,
      tabKey: targetKey,
      payload: formattedValues,
    });
  };
}

export function reportSetupAdd(formattedValues, targetKey) {
  return (dispatch) => {
    let storeReportSetup = {
      CustomerDetails: formattedValues.CustomerDetails,
      source: formattedValues.source,
      viewName: formattedValues.viewName,
      schemaName: formattedValues.schemaName,
      confirm: formattedValues.confirm,
      reportSetup: 'submitted',
    };
    dispatch({
      type: VIEW_COLUMNS_SUCCESS,
      tabKey: targetKey,
      payload: storeReportSetup,
    });
  };
}

export function reportCustomersAdd(formattedValues, targetKey) {
  return (dispatch) => {
    dispatch({
      type: REPORT_CUSTOMERS_SUCCESS,
      tabKey: targetKey,
      payload: formattedValues,
    });
  };
}

export function reportCustomersDataIndexAdd(formattedValues, targetKey) {
  return (dispatch) => {
    dispatch({
      type: REPORT_CUSTOMERS_INDEX_SUCCESS,
      tabKey: targetKey,
      payload: formattedValues,
    });
  };
}

export function reportCommunicationAdd(formattedValues, targetKey) {
  return (dispatch) => {
    dispatch({
      type: REPORT_COMMUNICATION_SUCCESS,
      tabKey: targetKey,
      payload: formattedValues,
    });
  };
}

export function getCustomerCodePlant(targetKey) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: NEW_REPORT });
    return api
      .post(
        'cds',
        formatParams(
          {
            type: 'CDS_CUSTOMER_CODE_PLANT',
            details: {
              email: Cookies.get('email'),
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data.statusCode == 200) {
          if (data.returnMsg == 'SUCCESS') {
            dispatch({
              type: GET_CUSTOMERCODE_SUCCESS,
              tabKey: targetKey,
              payload: data.result,
            });
          } else {
            dispatch({
              type: GET_CUSTOMERCODE_FAIL,
              tabKey: targetKey,
              payload: data.returnMsg,
            });
          }
        }
        return response;
      });
  };
}

export function getValidateRefresh(formattedValues, targetKey) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({
      type: VIEW_COLUMNS,
      tabKey: targetKey,
      payload: '',
    });
    return api
      .post(
        'cds',
        formatParams(
          {
            type: 'CDS_REFRESH_VALIDATE',
            details: formattedValues,
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data.statusCode == 200) {
          if (data.returnMsg == 'SUCCESS') {
            if (
              data &&
              data.result &&
              data.result.isConnected &&
              (data.result.isConnected === true ||
                data.result.isConnected == 'true')
            ) {
              dispatch({
                type: CDS_REFRESH_VALIDATE_SUCCESS,
                tabKey: targetKey,
                payload: true,
              });
            } else {
              dispatch({
                type: CDS_REFRESH_VALIDATE_FAIL,
                tabKey: targetKey,
                payload: false,
              });
            }
          } else {
            dispatch({
              type: CDS_REFRESH_VALIDATE_FAIL,
              tabKey: targetKey,
              payload: false,
            });
          }
        } else {
          dispatch({
            type: CDS_REFRESH_VALIDATE_FAIL,
            tabKey: targetKey,
            payload: false,
          });
        }
        return data;
      });
  };
}

export function getValidateCustomers(formattedValues, targetKey) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({
      type: VIEW_COLUMNS,
      tabKey: targetKey,
      payload: '',
    });
    return api
      .post(
        'cds',
        formatParams(
          {
            type: 'CDS_REFRESH_VALIDATE',
            details: formattedValues,
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data.statusCode == 200) {
          if (data.returnMsg == 'SUCCESS') {
            if (
              data &&
              data.result &&
              data.result.isConnected &&
              (data.result.isConnected === true ||
                data.result.isConnected == 'true')
            ) {
              dispatch({
                type: CDS_CUSTOMER_VALIDATE_SUCCESS,
                tabKey: targetKey,
                payload: true,
              });
            } else {
              dispatch({
                type: CDS_CUSTOMER_VALIDATE_FAIL,
                tabKey: targetKey,
                payload: false,
              });
            }
          } else {
            dispatch({
              type: CDS_CUSTOMER_VALIDATE_FAIL,
              tabKey: targetKey,
              payload: false,
            });
          }
        } else {
          dispatch({
            type: CDS_CUSTOMER_VALIDATE_FAIL,
            tabKey: targetKey,
            payload: false,
          });
        }
        return data;
      });
  };
}
export function getValidateReport(formattedValues, targetKey) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({
      type: VIEW_COLUMNS,
      tabKey: targetKey,
      payload: '',
    });
    return api
      .post(
        'cds',
        formatParams(
          {
            type: 'CDS_REPORT_CHECK',
            details: { report_name: formattedValues },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data.statusCode == 200) {
          if (data.returnCode == '0') {
            dispatch({
              type: CDS_REPORT_VALIDATE_SUCCESS,
              tabKey: targetKey,
              payload: 'No',
            });
          } else {
            dispatch({
              type: CDS_REPORT_VALIDATE_FAIL,
              tabKey: targetKey,
              payload: 'Yes',
            });
          }
        } else {
          dispatch({
            type: CDS_REPORT_VALIDATE_FAIL,
            tabKey: targetKey,
            payload: 'Yes',
          });
        }
        return data;
      });
  };
}

export function getViewColumns(reportDetails, targetKey) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({
      type: VIEW_COLUMNS,
      tabKey: targetKey,
      payload: '',
    });
    return api
      .post(
        'cds',
        formatParams(
          {
            type: 'CDS_VIEW_COLUMNS',
            uuid: getUUID(),
            details: {
              email: Cookies.get('email'),
              source: reportDetails.source,
              viewName: reportDetails.viewName,
              confirm: reportDetails.confirm,
              schema_name: reportDetails.schemaName,
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        if (data.statusCode == 200) {
          if (data.returnMsg === 'SUCCESS') {
            let storeReportSetup = {
              CustomerDetails: reportDetails.CustomerDetails,
              source: reportDetails.source,
              viewName: reportDetails.viewName,
              schemaName: reportDetails.schemaName,
              confirm: reportDetails.confirm,
              oldReportName: data.result.reportName || '',
              reportSetup: 'submitted',
              ColumnSelection:
                data.result.ColumnSelection !== undefined
                  ? data.result.ColumnSelection
                  : [],
            };
            dispatch({
              type: VIEW_COLUMNS_SUCCESS,
              tabKey: targetKey,
              payload: storeReportSetup,
            });
          } else {
            dispatch({
              type: VIEW_COLUMNS_FAIL,
              tabKey: targetKey,
              payload: data.returnMsg,
            });
          }
        } else {
          dispatch({
            type: VIEW_COLUMNS_FAIL,
            tabKey: targetKey,
            payload: data.returnMsg,
          });
        }
        return data;
      });
  };
}

export function getSampleDataViewColumns(targetKey) {
  return (dispatch) => {
    dispatch({
      type: VIEW_COLUMNS_SAMPLE_SUCCESS,
      tabKey: targetKey,
      payload: 'SUCCESS',
    });
    return true;
  };
}

export function reportSubmit(reportSubmitDetails, targetKey) {
  return (dispatch, _getState, { api }) => {
    dispatch({
      type: VIEW_COLUMNS,
      tabKey: targetKey,
      payload: '',
    });
    return api
      .post('cdssubmitaction', {
        TXN: {
          Header: {
            txn_type: 'CREATE_REPORT',
            uuid: getUUID(),
            requestor: Cookies.get('email'),
            source: 'PORTAL',
            excel: 'NO',
          },
          ReportFormArray: reportSubmitDetails,
        },
      })
      .then((response) => {
        const { data } = response;
        if (data.TXN && data.TXN[0].return_code == '0') {
          dispatch({
            type: CREATE_REPORT_SUCCESS,
            tabKey: targetKey,
            payload: 'Success!',
          });
        } else {
          dispatch({
            type: CREATE_REPORT_FAIL,
            tabKey: targetKey,
            payload: 'Fail!',
          });
        }
        return data;
      });
  };
}
