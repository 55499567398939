import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import store from '../../../../../redux/store';
import service from '../../../../../service/Service';
import {
  getLastRowIndex,
  getUUID,
  gaPageviewTimingTitle,
  getEpochTime,
} from '../../../../../helpers/utils';
import selectColumn from '../../../../common/ag-grid-select-column';
import Cookie from 'js-cookie';
import { message, Modal } from 'antd';
import {
  exportDTo,
  getMassDownloadGridParams,
} from '../../../../../helpers/export';

export const AGILITY_ADMIN_OPEN_COLUMNS =
  'acp/candidate-item/AGILITY_ADMIN_OPEN_COLUMNS';
export const AGILITY_ADMIN_OPEN_COLUMNS_SUCCESS =
  'acp/candidate-item/AGILITY_ADMIN_OPEN_COLUMNS_SUCCESS';
export const AGILITY_ADMIN_OPEN_COLUMNS_FAIL =
  'acp/candidate-item/AGILITY_ADMIN_OPEN_COLUMNS_FAIL';

export const AGILITY_ADMIN_CLOSED_COLUMNS =
  'acp/candidate-item/AGILITY_ADMIN_CLOSED_COLUMNS';
export const AGILITY_ADMIN_CLOSED_COLUMNS_SUCCESS =
  'acp/candidate-item/AGILITY_ADMIN_CLOSED_COLUMNS_SUCCESS';
export const AGILITY_ADMIN_CLOSED_COLUMNS_FAIL =
  'acp/candidate-item/AGILITY_ADMIN_CLOSED_COLUMNS_FAIL';

export const AGILITY_ADMIN_HISTORY_COLUMNS =
  'acp/candidate-item/AGILITY_ADMIN_HISTORY_COLUMNS';
export const AGILITY_ADMIN_HISTORY_COLUMNS_SUCCESS =
  'acp/candidate-item/AGILITY_ADMIN_HISTORY_COLUMNS_SUCCESS';
export const AGILITY_ADMIN_HISTORY_COLUMNS_FAIL =
  'acp/candidate-item/AGILITY_ADMIN_HISTORY_COLUMNS_FAIL';

export const AGILITY_ADMIN_OPEN_ROWS_INFO =
  'acp/candidate-item/AGILITY_ADMIN_OPEN_ROWS_INFO';
export const AGILITY_ADMIN_CLOSED_ROWS_INFO =
  'acp/candidate-item/AGILITY_ADMIN_CLOSED_ROWS_INFO';
export const AGILITY_ADMIN_HISTORY_ROWS_INFO =
  'acp/candidate-item/AGILITY_ADMIN_HISTORY_ROWS_INFO';

export const AGILITY_ADMIN_DATA_SUBMIT =
  'acp/candidate-item/AGILITY_ADMIN_DATA_SUBMIT';
export const AGILITY_ADMIN_DATA_SUBMIT_SUCCESS =
  'acp/candidate-item/AGILITY_ADMIN_DATA_SUBMIT_SUCCESS';
export const AGILITY_ADMIN_DATA_SUBMIT_FAIL =
  'acp/candidate-item/AGILITY_ADMIN_DATA_SUBMIT_FAIL';

const initialState = {
  loadingOpen: false,
  loadingClosed: false,
  loadingHistory: false,
  rowsInfoOpen: {
    endRow: 0,
    lastRow: 0,
  },
  rowsInfoClosed: {
    endRow: 0,
    lastRow: 0,
  },
  rowsInfoHistory: {
    endRow: 0,
    lastRow: 0,
  },
  agAdminOpenColumns: {
    rowModelType: null,
  },
  agAdminClosedColumns: {
    rowModelType: null,
  },
  agAdminHistoryColumns: {
    rowModelType: null,
  },
  submittingItem: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case AGILITY_ADMIN_OPEN_COLUMNS:
      return { ...state, loadingOpen: true };
    case AGILITY_ADMIN_CLOSED_COLUMNS:
      return { ...state, loadingClosed: true };
    case AGILITY_ADMIN_HISTORY_COLUMNS:
      return { ...state, loadingHistory: true };
    case AGILITY_ADMIN_OPEN_COLUMNS_SUCCESS:
      return {
        ...state,
        loadingOpen: false,
        loadingClosed: false,
        loadingHistory: false,
        loadedOpen: true,
        agAdminOpenColumns: action.payload,
        errorOpen: null,
      };
    case AGILITY_ADMIN_CLOSED_COLUMNS_SUCCESS:
      return {
        ...state,
        loadingOpen: false,
        loadingClosed: false,
        loadingHistory: false,
        loadedClosed: true,
        agAdminClosedColumns: action.payload,
        errorClosed: null,
      };
    case AGILITY_ADMIN_HISTORY_COLUMNS_SUCCESS:
      return {
        ...state,
        loadingOpen: false,
        loadingClosed: false,
        loadingHistory: false,
        loadedHistory: true,
        agAdminHistoryColumns: action.payload,
        errorHistory: null,
      };
    case AGILITY_ADMIN_OPEN_COLUMNS_FAIL:
      return { ...state, loadingOpen: false, errorOpen: action.payload };
    case AGILITY_ADMIN_CLOSED_COLUMNS_FAIL:
      return { ...state, loadingClosed: false, errorClosed: action.payload };
    case AGILITY_ADMIN_HISTORY_COLUMNS_FAIL:
      return { ...state, loadingHistory: false, errorHistory: action.payload };
    case AGILITY_ADMIN_OPEN_ROWS_INFO:
      return { ...state, rowsInfoOpen: action.payload, loadingOpen: false };
    case AGILITY_ADMIN_CLOSED_ROWS_INFO:
      return { ...state, rowsInfoClosed: action.payload, loadingClosed: false };
    case AGILITY_ADMIN_HISTORY_ROWS_INFO:
      return {
        ...state,
        rowsInfoHistory: action.payload,
        loadingClosed: false,
      };
    case AGILITY_ADMIN_DATA_SUBMIT:
      return { ...state, submittingItem: true };
    case AGILITY_ADMIN_DATA_SUBMIT_SUCCESS:
      return { ...state, submittingItem: false, submittedItem: true };
    case AGILITY_ADMIN_DATA_SUBMIT_FAIL:
      return { ...state, submittingItem: false };
    default:
      return state;
  }
}

function getAgAdminOpenColumns() {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: AGILITY_ADMIN_OPEN_COLUMNS });
    const currentTime = getEpochTime();
    return api
      .post(
        'acp',
        formatParams(
          {
            type: 'AG_GRID_COLUMNS',
            view: 'AGILITY_ADMIN_OPEN',
            details: {
              email: Cookie.get('email'),
            },
          },
          getState
        )
      )
      .then((response) => {
        gaPageviewTimingTitle(`/ACP/getAgAdminOpenColumns`, currentTime);
        const { data } = response;
        if (data && data.statusCode === '200') {
          data.result.columnDefs.unshift(selectColumn);
          dispatch({
            type: AGILITY_ADMIN_OPEN_COLUMNS_SUCCESS,
            payload: data.result,
          });
        } else {
          dispatch({
            type: AGILITY_ADMIN_OPEN_COLUMNS_FAIL,
            payload: 'Error in loading Open View!',
          });
        }
      });
  };
}

function getAgAdminOpenRows() {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      params.api.showLoadingOverlay();
      const currentTime = getEpochTime();
      return api
        .post(
          'acp',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: 'AGILITY_ADMIN_OPEN',
              gridParams: { ...params.request },
              details: { email: Cookie.get('email') },
            },
            store.getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(`/ACP/getAgAdminOpenRows`, currentTime);
          const { data } = response;
          if (data && data.statusCode === '200') {
            let rowData = data.result.rowData.map((row) => {
              return {
                ...row,
                isEditing: false,
                errors: {},
                hasError: false,
              };
            });
            params.success({
              rowData,
              rowCount: getLastRowIndex(params.request, rowData),
            });
            const endRow =
              params.request.endRow > data.result.lastRow
                ? data.result.lastRow
                : params.request.endRow;
            store.dispatch({
              type: AGILITY_ADMIN_OPEN_ROWS_INFO,
              payload: { lastRow: data.result.lastRow, endRow },
            });
            // Hide overlay loading on success
            params.api.hideOverlay();
            const rowsPerRequest = process.env.REACT_APP_AG_GRID_ROWS_LIMIT;
            if (!rowData.length && params.request.startRow < rowsPerRequest) {
              params.api.showNoRowsOverlay();
            }
          } else {
            params.fail();
          }
        })
        .catch(() => {
          params.fail();
        });
    },
  };
}

function getAgAdminClosedColumns() {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: AGILITY_ADMIN_CLOSED_COLUMNS });
    const currentTime = getEpochTime();
    return api
      .post(
        'acp',
        formatParams(
          {
            type: 'AG_GRID_COLUMNS',
            view: 'AGILITY_ADMIN_CLOSED',
            details: {
              email: Cookie.get('email'),
            },
          },
          getState
        )
      )
      .then((response) => {
        gaPageviewTimingTitle(`/ACP/getAgAdminClosedColumns`, currentTime);
        const { data } = response;
        if (data && data.statusCode === '200') {
          dispatch({
            type: AGILITY_ADMIN_CLOSED_COLUMNS_SUCCESS,
            payload: data.result,
          });
        } else {
          dispatch({
            type: AGILITY_ADMIN_CLOSED_COLUMNS_FAIL,
            payload: 'Error in loading Closed View!',
          });
        }
      });
  };
}

function getAgAdminClosedRows() {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      params.api.showLoadingOverlay();
      const currentTime = getEpochTime();
      return api
        .post(
          'acp',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: 'AGILITY_ADMIN_CLOSED',
              gridParams: { ...params.request },
              details: { email: Cookie.get('email') },
            },
            store.getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(`/ACP/getAgAdminClosedRows`, currentTime);
          const { data } = response;
          if (data && data.statusCode === '200') {
            params.success({
              rowData: data.result.rowData,
              rowCount: getLastRowIndex(params.request, data.result.rowData),
            });
            const endRow =
              params.request.endRow > data.result.lastRow
                ? data.result.lastRow
                : params.request.endRow;
            store.dispatch({
              type: AGILITY_ADMIN_CLOSED_ROWS_INFO,
              payload: { lastRow: data.result.lastRow, endRow },
            });
            // Hide overlay loading on success
            params.api.hideOverlay();
            const rowsPerRequest = process.env.REACT_APP_AG_GRID_ROWS_LIMIT;
            if (
              !data.result.rowData.length &&
              params.request.startRow < rowsPerRequest
            ) {
              params.api.showNoRowsOverlay();
            }
          } else {
            params.fail();
          }
        })
        .catch(() => {
          params.fail();
        });
    },
  };
}

function getAgAdminHistoryColumns() {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: AGILITY_ADMIN_HISTORY_COLUMNS });
    const currentTime = getEpochTime();
    return api
      .post(
        'acp',
        formatParams(
          {
            type: 'AG_GRID_COLUMNS',
            view: 'AGILITY_ADMIN_HISTORY',
            details: {
              email: Cookie.get('email'),
            },
          },
          getState
        )
      )
      .then((response) => {
        gaPageviewTimingTitle(`/ACP/getAgAdminHistoryColumns`, currentTime);
        const { data } = response;
        if (data && data.statusCode === '200') {
          dispatch({
            type: AGILITY_ADMIN_HISTORY_COLUMNS_SUCCESS,
            payload: data.result,
          });
        } else {
          dispatch({
            type: AGILITY_ADMIN_HISTORY_COLUMNS_FAIL,
            payload: 'Error in loading History View!',
          });
        }
      });
  };
}

function getAgAdminHistoryRows() {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      params.api.showLoadingOverlay();
      const currentTime = getEpochTime();
      return api
        .post(
          'acp',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: 'AGILITY_ADMIN_HISTORY',
              gridParams: { ...params.request },
              details: { email: Cookie.get('email') },
            },
            store.getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(`/ACP/getAgAdminHistoryRows`, currentTime);
          const { data } = response;
          if (data && data.statusCode === '200') {
            params.success({
              rowData: data.result.rowData,
              rowCount: getLastRowIndex(params.request, data.result.rowData),
            });
            const endRow =
              params.request.endRow > data.result.lastRow
                ? data.result.lastRow
                : params.request.endRow;
            store.dispatch({
              type: AGILITY_ADMIN_HISTORY_ROWS_INFO,
              payload: { lastRow: data.result.lastRow, endRow },
            });
            // Hide overlay loading on success
            params.api.hideOverlay();
            const rowsPerRequest = process.env.REACT_APP_AG_GRID_ROWS_LIMIT;
            if (
              !data.result.rowData.length &&
              params.request.startRow < rowsPerRequest
            ) {
              params.api.showNoRowsOverlay();
            }
          } else {
            params.fail();
          }
        })
        .catch(() => {
          params.fail();
        });
    },
  };
}

const handleFailedTxn = (failureResponse) => {
  Modal.error({
    title: 'Failed transactions!',
    icon: <ExclamationCircleOutlined />,
    content: (
      <div
        style={{
          maxHeight: 200,
          minHeight: 100,
          overflowY: 'auto',
        }}>
        <div style={{ borderBottom: '1px solid #CCC', padding: 5 }}>
          Org Item(s)
        </div>
        {failureResponse.map((item) => (
          <div
            key={item.acp_supplier_org_item}
            style={{
              borderBottom: '1px solid #CCC',
              padding: 5,
              fontSize: 12,
            }}>
            {item.acp_supplier_org_item}
          </div>
        ))}
      </div>
    ),
  });
};

function prepareAndSubmitData(nodes, action, gridApi) {
  let caItemDetails = [];
  if (nodes.length) {
    nodes.map(({ data }) => {
      caItemDetails.push({
        acp_rsm_owner: data.acp_rsm_owner,
        acp_supplier_org_item: data.acp_supplier_org_item,
        acp_org_item: data.acp_org_item,
        acp_org_code: data.acp_org_code,
        acp_business_plant_name: data.acp_business_plant_name,
        acp_business_division: data.acp_business_division,
        acp_geographic_region: data.acp_geographic_region,
        acp_item: data.acp_item,
        acp_demand_for_13_weeks: data.acp_demand_for_13_weeks,
        acp_ext_demand_for_13_weeks: data.acp_ext_demand_for_13_weeks,
        acp_annual_demand: data.acp_annual_demand,
        acp_ext_annual_demand: data.acp_ext_annual_demand,
        acp_customer_name: data.acp_customer_name,
        acp_item_desc: data.acp_item_desc,
        acp_final_supplier: data.acp_final_supplier,
        acp_final_manufacturer: data.acp_final_manufacturer,
        acp_final_mpn: data.acp_final_mpn,
        acp_source: data.acp_source,
        acp_feedback_to_supplier: data.acp_feedback_to_supplier,
        acp_item_status: data.acp_item_status,
        acp_master_seq_no: data.acp_master_seq_no,
      });
    });

    return (dispatch, _getState, { api }) => {
      dispatch({ type: AGILITY_ADMIN_DATA_SUBMIT });
      const currentTime = getEpochTime();
      return api
        .post('acpsubmitaction', {
          TXN: {
            header: {
              transaction_type: 'ADMIN_DATALOAD',
              status_type: action.toUpperCase(),
              uuid: getUUID(),
              customer: 'SANM',
              requestor: Cookie.get('email'),
              source: 'PORTAL',
              excel: 'NO',
            },
            details: caItemDetails,
          },
        })
        .then((response) => {
          gaPageviewTimingTitle(`/ACP/AgAdminOpenData${action}`, currentTime);
          const { data } = response;
          if (data && data.TXN) {
            const overallStatus = data.TXN[0].overall_txn_status;
            if (overallStatus === 'SUCCESS') {
              message.success('Data updated successfully!');
            } else if (overallStatus === 'FAILURE') {
              handleFailedTxn(data.TXN[0].FAILURE);
            } else {
              message.error('Something went wrong. Please try again!');
            }

            //Refresh grid
            gridApi.stopEditing();
            nodes.map((node) => {
              node.setSelected(false);
              node.updateData({
                ...node.data,
                isEditing: false,
                errors: {},
                hasError: false,
              });
            });
            gridApi.refreshServerSide({ purge: true });
            gridApi.refreshCells({ force: true });
          } else {
            message.error('Something went wrong. Please try again!');
          }
        });
    };
  }
}

export function getMassDownloadReport({
  gridApi,
  exportType,
  totalRows,
  cb,
  columnApi,
  fileName,
  view,
}) {
  return (_dispatch, getState, { api, formatParams }) => {
    if (totalRows >= process.env.REACT_APP_MASS_DOWNLOAD_ENABLE_LIMIT) {
      return api
        .post(
          'acp',
          formatParams(
            {
              type: 'MASS_DOWNLOAD',
              view: view,
              exportType,
              totalRows,
              email: Cookie.get('email'),
              gridParams: {
                ...getMassDownloadGridParams(gridApi, columnApi),
              },
            },
            getState
          )
        )
        .then((response) => {
          cb(true);
          return response;
        })
        .catch((error) => {
          cb(false);
          return error;
        });
    } else {
      const currentTime = getEpochTime();
      return api
        .post(
          'acp',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: view,
              gridParams: {
                ...getMassDownloadGridParams(gridApi, columnApi),
                startRow: 0,
                endRow: totalRows,
              },
              details: { email: Cookie.get('email') },
            },
            store.getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(
            `/ACP/getAgAdminOpenMassDownloadReport`,
            currentTime
          );
          const { data } = response;
          if (data) {
            const { result } = data;
            if (Object.keys(result).length) {
              exportDTo({
                columnApi,
                fileName: fileName,
                data: result.rowData,
                exportType,
              }) && cb(true);
            }
          }

          return response;
        });
    }
  };
}

export {
  getAgAdminOpenColumns,
  getAgAdminOpenRows,
  getAgAdminClosedColumns,
  getAgAdminClosedRows,
  getAgAdminHistoryColumns,
  getAgAdminHistoryRows,
  prepareAndSubmitData,
};
