import Cookie from 'js-cookie';
import store from '../../../../redux/store';
import service from '../../../../service/Service';
import {
  getLastRowIndex,
  gaPageviewTimingTitle,
  getEpochTime,
} from '../../../../helpers/utils';

export const SSP_DASHBOARD_COLUMNS_INIT =
  'ssp/dashboard/SSP_DASHBOARD_COLUMNS_INIT';
export const SSP_DASHBOARD_COLUMNS_SUCCESS =
  'ssp/dashboard/SSP_DASHBOARD_COLUMNS_SUCCESS';
export const SSP_DASHBOARD_COLUMNS_FAIL =
  'ssp/dashboard/SSP_DASHBOARD_COLUMNS_FAIL';

export const SSP_DASHBOARD_ROWS_INFO = 'ssp/dashboard/SSP_DASHBOARD_ROWS_INFO';

export default function reducer(state = {}, action = {}) {
  switch (action.type) {
    case SSP_DASHBOARD_COLUMNS_INIT:
      return { ...state, columns: { isLoading: true } };
    case SSP_DASHBOARD_COLUMNS_SUCCESS:
      return { ...state, columns: { isLoading: false, data: action.payload } };
    case SSP_DASHBOARD_COLUMNS_FAIL:
      return {
        ...state,
        columns: { isLoading: false, errors: action.payload },
      };
    default:
      return state;
  }
}

export function getColumns() {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: SSP_DASHBOARD_COLUMNS_INIT });
    const currentTime = getEpochTime();
    return api
      .post(
        'ssp',
        formatParams(
          {
            type: 'AG_GRID_COLUMNS',
            view: 'SSP_DASHBOARD',
            details: {
              email: Cookie.get('email'),
            },
          },
          getState
        )
      )
      .then((response) => {
        gaPageviewTimingTitle(
          `/SSP/Dashboard/getSspDashboardColumns`,
          currentTime
        );
        const { data } = response;
        if (data && data.statusCode === '200') {
          const result = data.result;
          dispatch({
            type: SSP_DASHBOARD_COLUMNS_SUCCESS,
            payload: result,
          });
        } else {
          dispatch({
            type: SSP_DASHBOARD_COLUMNS_FAIL,
            payload: 'Error in loading Open View!',
          });
        }
      });
  };
}

export function getRows() {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      params.api.showLoadingOverlay();
      const currentTime = getEpochTime();
      return api
        .post(
          'ssp',
          formatParams(
            {
              type: 'AG_GRID_ROWS',
              view: 'SSP_DASHBOARD',
              gridParams: { ...params.request },
              details: { email: Cookie.get('email') },
            },
            store.getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(
            `/SSP/Dashboard/getSspDashboardRows`,
            currentTime
          );
          const { data } = response;
          if (data && data.statusCode === '200') {
            const rowData = data.result.rowData;
            params.success({
              rowData: rowData,
              rowCount: getLastRowIndex(params.request, rowData),
            });
            // Hide overlay loading on success
            params.api.hideOverlay();
            if (!rowData.length) {
              params.api.showNoRowsOverlay();
            }
          } else {
            params.fail();
          }
        })
        .catch((_error) => {
          params.fail();
        });
    },
  };
}
