/** @jsxRuntime classic */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

/**
 * This function adds one to its input.
 * @param {number} input any number
 * @returns {number} that number, plus one.
 */
import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import * as serviceWorker from './serviceWorker';
import App from './App';
import {
  setAgGridLicense,
  getAgGridEnterPriseModule,
  getGATrackingCode,
} from './helpers/utils';
import ReactGA from 'react-ga4';
import './modules/common/polyfills/prepend';

//import ag-grid enterprise module
getAgGridEnterPriseModule();

// Setting Ag Grid License
setAgGridLicense();

// Initialize ReactGA

ReactGA.initialize(getGATrackingCode(), {
  gaOptions: {
    siteSpeedSampleRate: 100,
  },
});

ReactGA.send({
  hitType: 'pageview',
  page: window.location.pathname + window.location.search,
});

createRoot(document.getElementById('root')).render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
