import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import DocumentList from './DocumentList';
import SupportDetail from './SupportDetail';
import { Scrollbars } from 'react-custom-scrollbars';
import { getSupportDocs } from './redux/support-document';
import ErrorBoundary from './ErrorBoundary';
import Helpwalk from '../tour/cds/helpwalk';
import Helpwalkcommon from '../tour/cds/helpwalkcommon';
import HelpWalkOrders from '../tour/cds/helpWalkOrders';
import HelpWalkReports from '../tour/cds/helpWalkReports';
import Helpwalkacp from '../tour/acp/helpwalkacp';
import { getSymbol } from 'helpers/utils';

const SupportDocument = () => {
  const [visible, setVisible] = useState(false);
  const {
    systemRequirements,
    supportDocuments,
    supportDetails,
    loading,
    defaultAccess,
    userRole,
  } = useSelector(({ support, authorization }) => ({
    systemRequirements: support.data.systemRequirements,
    supportDocuments: support.data.supportDocuments,
    supportDetails: support.data.supportDetails,
    loading: support.loading,
    defaultAccess: authorization.initAuthorization.defaultAccess,
    userRole: authorization.initAuthorization.userRole,
  }));
  const { tourReferences } = useSelector(({ tour }) => ({
    tourReferences: tour.tourReferences,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    if (visible) dispatch(getSupportDocs());
  }, [visible]); // get support documents

  const handleVisiblity = () => setVisible(false);

  const showWalkthrough = () => {
    if (defaultAccess == 'UMS') {
      return null;
    }
    if (defaultAccess == 'CDS') {
      if (userRole === 'cds_it_admin') {
        return <Helpwalk handleVisiblity={handleVisiblity} />;
      } else if (userRole == 'orders') {
        return <HelpWalkOrders handleVisiblity={handleVisiblity} />;
      } else if (userRole == 'reports') {
        return <HelpWalkReports handleVisiblity={handleVisiblity} />;
      } else if (userRole == 'orders,reports' || userRole == 'reports,orders') {
        return <Helpwalkcommon handleVisiblity={handleVisiblity} />;
      } else {
        return null;
      }
    } else if (defaultAccess == 'ACP') {
      return <Helpwalkacp handleVisiblity={handleVisiblity} />;
    }
  };

  return (
    <div className='support-docs footer-font-size'>
      <a
        onClick={() => setVisible(!visible)}
        ref={tourReferences.profileSupportDoc}>
        Support
      </a>
      <Modal
        title={'Support Documents'}
        wrapClassName='support-docs-modal'
        footer={[
          <>{showWalkthrough()}</>,
          <Button key={getSymbol()} type='primary' onClick={handleVisiblity}>
            Ok
          </Button>,
        ]}
        visible={visible}
        onCancel={handleVisiblity}>
        <Scrollbars style={{ height: 340 }}>
          <ErrorBoundary>
            <a href={systemRequirements.url} target='_blank' title='Download'>
              <DownloadOutlined />{' '}
              {systemRequirements ? systemRequirements.title : ''}
            </a>
            {supportDocuments.length ? (
              <DocumentList
                supportDocuments={supportDocuments}
                loading={loading}
              />
            ) : null}
            <SupportDetail supportDetails={supportDetails} loading={loading} />
          </ErrorBoundary>
        </Scrollbars>
      </Modal>
    </div>
  );
};

export default SupportDocument;
