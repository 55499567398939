import { combineReducers } from 'redux';
import authentication from '../modules/login/redux/modules/login';
import layout from '../modules/layout/redux/modules/layout';
import authorization from '../modules/layout/redux/modules/authorization';
import profile from '../modules/profile/redux/modules/reducers';
import cds from '../modules/cds/redux/modules/reducers';
import ums from '../modules/ums/redux/reducers';
import support from '../modules/support-document/redux/support-document';
import acp from '../modules/acp/redux/acp-reducer';
import cpt from '../modules/cpt/redux/cpt-reducer';
import ssp from '../modules/ssp/redux/ssp-reducer';
import tour from '../modules/tour/redux/tour';
import theme from './theme';

const reducers = combineReducers({
  authentication,
  authorization,
  layout,
  profile,
  cds,
  ums,
  support,
  acp,
  cpt,
  ssp,
  tour,
  theme,
});

export default reducers;
