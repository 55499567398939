import { message } from 'antd';
import { getLastRowIndex, getUUID } from '../../../../helpers/utils';
import service from '../../../../service/Service';
import store from '../../../../redux/store';
import Cookie from 'js-cookie';

//below 3 const is for fetching SSP userlist columns
const SSP_USER_LIST_COLUMNS = 'ums/ssp/store/SSP_USER_LIST_COLUMNS';
const SSP_USER_LIST_COLUMNS_SUCCESS =
  'ums/ssp/store/SSP_USER_LIST_COLUMNS_SUCCESS';
const SSP_USER_LIST_COLUMNS_FAIL = 'ums/ssp/store/SSP_USER_LIST_COLUMNS_FAIL';

//below 3 const is for fetching SSP userlist rows
const SSP_USER_LIST_ROWS_INFO_SUCCESS =
  'ums/ssp/store/SSP_USER_LIST_ROWS_INFO_SUCCESS';

//below 3 const is for SSP add new user
const ADD_SSP_USER = 'ums/ssp/store/ADD_SSP_USER';
const ADD_SSP_USER_SUCCESS = 'ums/ssp/store/ADD_SSP_USER_SUCCESS';
const ADD_SSP_USER_FAIL = 'ums/ssp/store/ADD_SSP_USER_FAIL';

//below 2 const is for SSP edit existing user details
const UPDATE_SSP_USER = 'ums/ssp/store/UPDATE_SSP_USER';
const UPDATE_SSP_USER_SUCCESS = 'ums/ssp/store/UPDATE_SSP_USER_SUCCESS';

//below 2 const is for fetching SSP specific existing user details
const GET_SPECIFIC_SSP_USER_DETAIL =
  'ums/ssp/store/GET_SPECIFIC_SSP_USER_DETAIL';
const GET_SPECIFIC_SSP_USER_DETAIL_SUCCESS =
  'ums/ssp/store/GET_SPECIFIC_SSP_USER_DETAIL_SUCCESS';

//below 3 const is for fetching dropdown data while creating SSP new user
const SSP_CREATE_USER_DROPDOWN_DATA_LOADING =
  'ums/ssp/store/SSP_CREATE_USER_DROPDOWN_DATA_LOADING';
const SSP_CREATE_USER_DROPDOWN_DATA_SUCCESS =
  'ums/ssp/store/SSP_CREATE_USER_DROPDOWN_DATA_SUCCESS';
const SSP_CREATE_USER_DROPDOWN_DATA_FAIL =
  'ums/ssp/store/SSP_CREATE_USER_DROPDOWN_DATA_FAIL';

const initialState = {
  isLoading: false,
  sspCreateUserDropdownDataLoaded: false,
  sspCreateUserDropdownDataLoading: false,
  sspCreateUserDropdownData: [],
  addUserMsg: '',
  userName: '',
  userDetails: [],
  updateUserMsg: '',
  updatingUserState: false,
  storeColumns: {},
  rowsInfo: {
    endRow: 0,
    lastRow: 0,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SSP_CREATE_USER_DROPDOWN_DATA_LOADING:
      return { ...state, sspCreateUserDropdownDataLoaded: true };
    case SSP_CREATE_USER_DROPDOWN_DATA_SUCCESS:
      return {
        ...state,
        sspCreateUserDropdownDataLoaded: true,
        sspCreateUserDropdownDataLoading: false,
        sspCreateUserDropdownData: action.payload,
        error: null,
      };
    case SSP_CREATE_USER_DROPDOWN_DATA_FAIL:
      return {
        ...state,
        sspCreateUserDropdownDataLoading: false,
        error: action.payload,
      };

    case ADD_SSP_USER:
      return { ...state, isLoading: true };
    case ADD_SSP_USER_SUCCESS:
      return { ...state, addUserMsg: action.data, isLoading: false };
    case ADD_SSP_USER_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case UPDATE_SSP_USER:
      return { ...state, isLoading: true };
    case UPDATE_SSP_USER_SUCCESS:
      return { ...state, updateUserMsg: 'SUCCESS', isLoading: false };
    case GET_SPECIFIC_SSP_USER_DETAIL:
      return { ...state, isLoading: true };
    case GET_SPECIFIC_SSP_USER_DETAIL_SUCCESS:
      return { ...state, userDetails: action.data, isLoading: false };
    case SSP_USER_LIST_COLUMNS:
      return { ...state, isLoading: true };
    case SSP_USER_LIST_COLUMNS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        storeColumns: action.payload,
        error: null,
      };
    case SSP_USER_LIST_COLUMNS_FAIL:
      return { ...state, isLoading: false, error: action.payload };
    case SSP_USER_LIST_ROWS_INFO_SUCCESS:
      return { ...state, rowsInfo: action.payload };
    default:
      return state;
  }
}

export function getColumns() {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: SSP_USER_LIST_COLUMNS });
    return api
      .post(
        'ums',
        formatParams({ type: 'AG_GRID_COLUMNS_SSP', view: 'SSP' }, getState)
      )
      .then((response) => {
        const { data } = response;
        if (data) {
          dispatch({
            type: SSP_USER_LIST_COLUMNS_SUCCESS,
            payload: data,
          });
        } else {
          dispatch({
            type: SSP_USER_LIST_COLUMNS_FAIL,
            payload: 'Something went wrong! Please try again.',
          });
        }
      });
  };
}

export function getRows() {
  const { api, formatParams } = service;
  return {
    getRows: (params) => {
      params.api.showLoadingOverlay();
      return api
        .post(
          'ums',
          formatParams(
            {
              type: 'AG_GRID_ROWS_SSP',
              view: 'SSP',
              gridParams: { ...params.request },
            },
            store.getState
          )
        )
        .then((response) => {
          const { data } = response;
          if (Object.keys(data).length) {
            let rowData = data.rowData.map((row) => {
              return {
                ...row,
                isEditing: false,
                errors: {},
                hasError: false,
              };
            });
            params.success({
              rowData: rowData,
              rowCount: getLastRowIndex(params.request, rowData),
            });
            const endRow =
              params.request.endRow > data.lastRow
                ? data.lastRow
                : params.request.endRow;
            store.dispatch({
              type: SSP_USER_LIST_ROWS_INFO_SUCCESS,
              payload: { lastRow: data.lastRow, endRow },
            });
            // Hide overlay loading on success
            params.api.hideOverlay();
            const rowsPerRequest = process.env.REACT_APP_AG_GRID_ROWS_LIMIT;
            if (
              !data.rowData.length &&
              params.request.startRow < rowsPerRequest
            ) {
              params.api.showNoRowsOverlay();
            }
          } else {
            params.fail();
          }
        })
        .catch(() => {
          params.fail();
        });
    },
  };
}

//below function is for fetch plant field dropdown details before add a new user screen loads
export function fetchDropdownData() {
  return (dispatch, getState, { api, formatParams }) => {
    const authorized = Object.keys(
      getState().authorization.initAuthorization
    ).length;

    if (authorized) {
      dispatch({ type: SSP_CREATE_USER_DROPDOWN_DATA_LOADING });
      return api
        .post(
          'ums',
          formatParams(
            {
              type: 'SSP_DROPDOWN_DATA',
            },
            getState
          )
        )
        .then((response) => {
          const { data } = response;
          if (data) {
            dispatch({
              type: SSP_CREATE_USER_DROPDOWN_DATA_SUCCESS,
              payload: data,
            });
          } else {
            dispatch({
              type: SSP_CREATE_USER_DROPDOWN_DATA_FAIL,
              payload: 'Error in fetching dropdown data',
            });
          }
          return response;
        });
    }
  };
}

//below function is for calling api service and handling response for creating a new user
export function add(formattedValues) {
  return (dispatch, _getState, { api }) => {
    dispatch({ type: ADD_SSP_USER });
    let userDetails = [];
    let getRole = formattedValues.user_role;

    userDetails.push({
      user_email: formattedValues.email,
      first_name: formattedValues.first_name,
      last_name: formattedValues.last_name,
      // title: formattedValues.title,
      country: formattedValues.country,
      // edit: 't',
      role: getRole.join(),
    });

    return api
      .post('ums', {
        TXN: {
          header: {
            transaction_type: 'AMS_RQSTR_CREATION',
            type: 'ADD',
            uuid: getUUID(),
            customer: 'SANM',
            requestor: Cookie.get('email'),
            source: 'PORTAL',
            insert_flag: 'true',
          },
          details: userDetails,
        },
      })
      .then((response) => {
        const { data } = response;
        const TXNGET = data.TXN;
        if (TXNGET[0].return_msg === 'SUCCESS') {
          dispatch(update(data));
        } else {
          dispatch({
            type: ADD_SSP_USER_FAIL,
            payload: 'Error in fetching data',
          });
        }
        return TXNGET;
      });
  };
  function update(data) {
    return { type: ADD_SSP_USER_SUCCESS, data };
  }
}

//below function is for calling api service and handling response for fetching details of a specific user
export function getUserDetails(email) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: GET_SPECIFIC_SSP_USER_DETAIL });
    return api
      .post(
        'ums',
        formatParams(
          {
            type: 'VIEW_SPECIFIC_USER_SSP',
            details: {
              email: email,
            },
          },
          getState
        )
      )
      .then((response) => {
        if (response) {
          const data = response.data;
          if (data) {
            dispatch(update(data));
          }
        }
        return response;
      });
  };
  function update(data) {
    return { type: GET_SPECIFIC_SSP_USER_DETAIL_SUCCESS, data };
  }
}

//below function is for calling api service and handling response for updating an existing user
export function updateUserDetails(formattedValues) {
  return (dispatch, _getState, { api }) => {
    dispatch({ type: UPDATE_SSP_USER });
    let userDetails = [];
    let getRole = formattedValues.user_role_edit;

    userDetails.push({
      user_email: formattedValues.email_edit,
      first_name: formattedValues.first_name_edit,
      last_name: formattedValues.last_name_edit,
      country: formattedValues.country_edit,
      // edit: getUpdate.join(),
      role: getRole.join(),
    });

    return api
      .post('ums', {
        TXN: {
          header: {
            transaction_type: 'AMS_RQSTR_CREATION',
            type: 'EDIT',
            uuid: getUUID(),
            customer: 'SANM',
            requestor: Cookie.get('email'),
            source: 'PORTAL',
            insert_flag: 'false',
          },
          details: userDetails,
        },
      })
      .then((response) => {
        const { data } = response;
        const TXNGET = data.TXN;
        if (TXNGET[0].return_msg === 'SUCCESS') {
          dispatch(update());
        } else {
          dispatch({
            type: ADD_SSP_USER_FAIL,
            payload: 'Error in updating user',
          });
        }
        return TXNGET[0].return_msg;
      });
  };
  function update() {
    return { type: UPDATE_SSP_USER_SUCCESS };
  }
}

//below function is for calling api service and handling response for deleting an existing user
export function deleteUser(email) {
  return (_dispatch, _getState, { api }) => {
    let userDetails = [];
    userDetails.push({
      user_email: email,
    });

    return api
      .post('ums', {
        TXN: {
          header: {
            transaction_type: 'AMS_RQSTR_CREATION',
            type: 'DELETE',
            uuid: getUUID(),
            customer: 'SANM',
            requestor: Cookie.get('email'),
            source: 'PORTAL',
          },
          details: userDetails,
        },
      })
      .then((response) => {
        const { data } = response;
        const TXNGET = data.TXN;
        if (TXNGET[0].return_msg === 'SUCCESS') {
          message.success('User deleted successfully');
        } else {
          message.error('Something went wrong. Please try again!');
        }
      });
  };
}
