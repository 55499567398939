import React from 'react';
import {
  getUUID,
  gaPageviewTimingTitle,
  getEpochTime,
} from '../../../../helpers/utils';
import { message } from 'antd';
import Cookie from 'js-cookie';

const FORM_DETAILS = 'questionnaire/FORM_DETAILS';
const FORM_USERDETAILS_SUCCESS = 'questionnaire/FORM_USERDETAILS_SUCCESS';
const FORM_USERDETAILS_FAILURE = 'questionnaire/FORM_USERDETAILS_FAILURE';
const FORM_EDIDETAILS_SUCCESS = 'questionnaire/FORM_EDIDETAILS_SUCCESS';
const FORM_EDIDETAILS_FAILURE = 'questionnaire/FORM_EDIDETAILS_FAILURE';
const FORM_REQDETAILS_SUCCESS = 'questionnaire/FORM_REQDETAILS_SUCCESS';
const FORM_REQDETAILS_FAILURE = 'questionnaire/FORM_REQDETAILS_FAILURE';
const FORM_FILES = 'questionnaire/FORM_FILES';
const FORM_FILES_SUCCESS = 'questionnaire/FORM_FILES_SUCCESS';
const FORM_FILES_FAILURE = 'questionnaire/FORM_FILES_FAILURE';
const B2BNUMBER_DROPDOWN_DATA = 'questionnaire/B2BNUMBER_DROPDOWN_DATA';
const B2BNUMBER_DROPDOWN_DATA_SUCCESS =
  'questionnaire/B2BNUMBER_DROPDOWN_DATA_SUCCESS';
const B2BNUMBER_DROPDOWN_DATA_FAIL =
  'questionnaire/B2BNUMBER_DROPDOWN_DATA_FAIL';
const FORM_SUBMIT = 'questionnaire/FORM_SUBMIT';
const FORM_SUBMIT_SUCCESS = 'questionnaire/FORM_SUBMIT_SUCCESS';
const FORM_SUBMIT_FAILURE = 'questionnaire/FORM_SUBMIT_FAILURE';
const FORM_STATUS_SUBMIT = 'questionnaire/FORM_STATUS_SUBMIT';
const FORM_STATUS_SUBMIT_SUCCESS = 'questionnaire/FORM_STATUS_SUBMIT_SUCCESS';
const FORM_STATUS_SUBMIT_FAIL = 'questionnaire/FORM_STATUS_SUBMIT_FAIL';
const SEND_EMAIL = 'questionnaire/SEND_EMAIL';
const SEND_EMAIL_SUCCESS = 'questionnaire/SEND_EMAIL_SUCCESS';
const SEND_EMAIL_FAIL = 'questionnaire/SEND_EMAIL_FAIL';

const initialState = {
  loading: false,
  user_data: {},
  edi_data: {},
  files: [],
  req_details: {},
  form_submit: false,
  submitted: '',
  req_number: [],
  send_email_details: '',
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FORM_DETAILS:
    case FORM_FILES:
    case FORM_SUBMIT:
    case B2BNUMBER_DROPDOWN_DATA:
      return {
        ...state,
        loading: true,
      };
    case FORM_USERDETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        user_data: action.payload,
      };
    case FORM_USERDETAILS_FAILURE:
    case FORM_EDIDETAILS_FAILURE:
    case FORM_REQDETAILS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case FORM_EDIDETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        edi_data: action.payload,
      };
    case FORM_REQDETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        req_details: action.payload,
      };
    case FORM_FILES_SUCCESS:
    case FORM_FILES_FAILURE:
      return {
        ...state,
        loading: false,
        files: action.payload,
      };
    case FORM_SUBMIT_SUCCESS:
    case FORM_SUBMIT_FAILURE:
      return {
        ...state,
        loading: false,
        submitted: action.payload,
      };
    case B2BNUMBER_DROPDOWN_DATA_SUCCESS:
    case B2BNUMBER_DROPDOWN_DATA_FAIL:
      return {
        ...state,
        loading: false,
        req_number: action.payload,
      };
    case SEND_EMAIL:
      return {
        ...state,
      };
    case SEND_EMAIL_SUCCESS:
    case SEND_EMAIL_FAIL:
      return {
        ...state,
        send_email_details: action.payload,
      };
    default:
      return state;
  }
}

function fetchRequestNoData() {
  return (dispatch, getState, { api, formatParams }) => {
    const authorized = Object.keys(
      getState().authorization.initAuthorization
    ).length;
    if (authorized) {
      dispatch({ type: B2BNUMBER_DROPDOWN_DATA });
      return api
        .post(
          'ssp',
          formatParams(
            {
              type: 'REQUEST_NO_DROPDOWN_LIST',
              form_details: {
                email: Cookie.get('email'),
              },
            },
            getState
          )
        )
        .then((response) => {
          const { data } = response;
          const req = data.request_no;
          if (data) {
            dispatch({
              type: B2BNUMBER_DROPDOWN_DATA_SUCCESS,
              payload: req,
            });
          } else {
            dispatch({
              type: B2BNUMBER_DROPDOWN_DATA_FAIL,
              payload: 'Error in fetching dropdown data',
            });
          }
          return response;
        });
    }
  };
}

function getFormDetails(b2bNumber) {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: FORM_DETAILS });
    const currentTime = getEpochTime();

    const handleSuccess = (response) => {
      const edi_data = response.data.result.edi_details[0];
      const user_data = response.data.result.user_details[0];
      dispatch({ type: FORM_REQDETAILS_SUCCESS, payload: response.data });
      dispatch({ type: FORM_USERDETAILS_SUCCESS, payload: user_data });
      dispatch({ type: FORM_EDIDETAILS_SUCCESS, payload: edi_data });
      if (response.data.result.files_list) {
        const files_list = response.data.result.files_list.files;
        dispatch({ type: FORM_FILES_SUCCESS, payload: files_list });
      }
    };

    return api
      .post(
        'ssp',
        formatParams(
          {
            type: 'QUESTIONNAIRE_FORM',
            b2b_req_no: b2bNumber,
            uuid: getUUID(),
            source: 'PORTAL',
          },
          getState
        )
      )
      .then((response) => {
        gaPageviewTimingTitle(`/SSP/getQuestionnaireFormDetails`, currentTime);
        if (response.status == '200') {
          if (response.data.result) {
            if (
              response.data.result.edi_details &&
              response.data.result.user_details
            ) {
              handleSuccess(response);
            } else {
              dispatch({
                type: FORM_REQDETAILS_FAILURE,
                payload: 'Error in fetching questionnaire form details',
              });
              dispatch({
                type: FORM_USERDETAILS_FAILURE,
                payload: 'Error in fetching user details',
              });
              dispatch({
                type: FORM_EDIDETAILS_FAILURE,
                payload: 'Error in fetching edi details',
              });
            }
            return response;
          }
        }
      });
  };
}

function submitForm(values, details) {
  let userDetails = {
    user_email: values.email,
    user_first: values.firstname,
    user_last: values.lastname,
    user_title: values.title,
    user_phone: values.phone,
    user_country: values.country,
    role: 'user',
    user_type: details.user_type,
  };

  let ediDetails = {
    van_name: values.vanName,
    test_isa_or_unb_qualifier: values.testISAorUNBQualifier,
    test_isa_or_unb_id: values.testISAorUNBID,
    test_unb_reverse_routing: values.testUNBReverseRouting,
    test_gs_id: values.testGSID,
    prod_isa_or_unb_qualifier: values.prodISAorUNBQualifier,
    prod_isa_or_unb_id: values.prodISAorUNBID,
    prod_unb_reverse_routing: values.prodUNBReverseRouting,
    prod_gs_id: values.prodGSID,
    contain_flag: 'No',
    doc_std: details.doc_std,
    tp_code: details.tp_code,
    prod_tp_code: '',
    overall_status_flag: true,
    overall_status: 'Released for Testing',
  };

  if (values.b2bType == 'SSP3') {
    userDetails = {
      ...userDetails,
      supplier_name: values.supplierName,
      supplier_number: values.supplierNumber,
      supplier_site: values.supplierSite,
    };
  } else {
    userDetails = {
      ...userDetails,
      comp_name: values.companyName,
      cust_no: values.customerNumber,
    };
  }

  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: FORM_SUBMIT });
    const currentTime = getEpochTime();
    return api
      .post(
        'sspsubmitaction',
        formatParams(
          {
            transaction_type: 'QUESTIONNAIRE',
            uuid: getUUID(),
            customer: 'SANM',
            requestor: details.requestor,
            source: 'portal',
            b2b_req_no: values.b2bNumber,
            b2b_type: values.b2bType,
            user: userDetails,
            edi_details: ediDetails,
          },
          getState
        )
      )
      .then((response) => {
        gaPageviewTimingTitle(
          `/SSP/Questionnaire/submit${values.b2bType}FormData`,
          currentTime
        );
        const { data } = response;
        if (data.TXN && data.TXN[0].return_msg == 'SUCCESS') {
          message.success('Questionnaire submitted successfully.');
          dispatch({
            type: FORM_SUBMIT_SUCCESS,
            payload: 'Data saved successfully',
          });
        } else {
          message.warning(data.TXN[0].return_msg);
          dispatch({
            type: FORM_SUBMIT_FAILURE,
            payload: 'Error in saving data',
          });
        }
        return response;
      });
  };
}

function rfOverallStatus(type, details) {
  let status = '';
  if (type == 'selftest') {
    status = 'Self Test Complete';
  } else if (type == 'uattest') {
    status = 'UAT Testing Complete';
  }
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: FORM_STATUS_SUBMIT });
    const currentTime = getEpochTime();
    return api
      .post(
        'sspsubmitaction',
        formatParams(
          {
            transaction_type: 'RF_OVERALL_STATUS',
            uuid: getUUID(),
            customer: 'SANM',
            requestor: details.requestor,
            source: 'portal',
            b2b_req_no: details.result.user_details[0].b2b_req_no,
            overall_status_flag: true,
            overall_status: status,
            feedback_details: '',
          },
          getState
        )
      )
      .then((response) => {
        gaPageviewTimingTitle(
          `/SSP/Questionnaire/updateStatusOf${type}Complete`,
          currentTime
        );
        if (response.status == 200) {
          message.success('Success');
          dispatch({
            type: FORM_STATUS_SUBMIT_SUCCESS,
            payload: 'Data saved successfully',
          });
        } else {
          dispatch({
            type: FORM_STATUS_SUBMIT_FAIL,
            payload: 'Error in saving data',
          });
        }
        return response;
      });
  };
}

function sendEmail(values) {
  return (dispatch, getState, { api, formatParams }) => {
    const authorized = Object.keys(
      getState().authorization.initAuthorization
    ).length;
    if (authorized) {
      dispatch({ type: SEND_EMAIL });
      const currentTime = getEpochTime();
      return api
        .post(
          'sspsubmitaction',
          formatParams(
            {
              transaction_type: 'UPDATE_FEEDBACK',
              uuid: getUUID(),
              source: 'PORTAL',
              customer: 'SANM',
              requestor: values.requester,
              user_role: 'user',
              form_details: {
                b2b_req_no: values.b2b_req_no,
                requester: values.requester,
                email_list: values.email_list,
                feedback_details: values.feedback_details,
                user_feedback: values.user_feedback,
                formtype: values.formtype,
              },
            },
            getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(
            `/SSP/Questionnaire/UpdateFeedbackSendEmail`,
            currentTime
          );
          const { data } = response;
          const TXNGET = data.TXN;
          if (TXNGET && TXNGET[0].return_msg === 'SUCCESS') {
            dispatch({
              type: SEND_EMAIL_SUCCESS,
              payload: data,
            });
          } else {
            dispatch({
              type: SEND_EMAIL_FAIL,
              payload: 'Error in fetching dropdown data',
            });
          }
          return TXNGET[0].return_msg;
        });
    }
  };
}

export {
  fetchRequestNoData,
  getFormDetails,
  submitForm,
  rfOverallStatus,
  sendEmail,
};
