import { gaPageviewTimingTitle, getEpochTime } from '../../../../helpers/utils';

const DASHBOARD = 'cds/dashboard/DASBOARD';
const DASHBOARD_SUCCESS = 'cds/dashboard/DASHBOARD_SUCCESS';
const DASHBOARD_FAIL = 'cds/dashboard/DASHBOARD_FAIL';

const ADMIN_CUST_DASHBOARD = 'cds/dashboard/ADMIN_CUST_DASHBOARD';
const ADMIN_CUST_DASHBOARD_SUCCESS =
  'cds/dashboard/ADMIN_CUST_DASHBOARD_SUCCESS';
const ADMIN_CUST_DASHBOARD_FAIL = 'cds/dashboard/ADMIN_CUST_DASHBOARD_FAIL';

const SET_SELECTED_CUSTOMER = 'cds/dashboard/SET_SELECTED_CUSTOMER';

const initialState = {
  loaded: false,
  data: {},
  error: null,
  loading: false,
  admin: {
    customer: {},
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case DASHBOARD:
      return { ...state, loading: true };
    case DASHBOARD_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        data: action.payload,
        error: null,
      };
    case DASHBOARD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        data: null,
        error: action.error,
      };
    case ADMIN_CUST_DASHBOARD:
      return {
        ...state,
        admin: {
          customer: {
            loading: true,
            data: [],
          },
        },
      };
    case ADMIN_CUST_DASHBOARD_SUCCESS:
      return {
        ...state,
        admin: {
          ...state.admin,
          customer: {
            ...state.admin.customer,
            loading: false,
            data: action.payload,
          },
        },
      };
    case ADMIN_CUST_DASHBOARD_FAIL:
      return {
        ...state,
        admin: {
          customer: {
            loading: false,
            error: action.error,
          },
        },
      };
    case SET_SELECTED_CUSTOMER:
      return {
        ...state,
        selectedCustomer: action.payload,
      };
    default:
      return state;
  }
}

export function getDashboardDetails() {
  return (dispatch, getState, { api, formatParams }) => {
    const initAuthorization = getState().authorization.initAuthorization;
    const authorized = Object.keys(initAuthorization).length;
    if (authorized) {
      dispatch({ type: DASHBOARD });
      const currentTime = getEpochTime();
      return api
        .post('cds', formatParams({ type: 'dashboard' }, getState))
        .then((response) => {
          gaPageviewTimingTitle(`/CDS/DASHBOARD`, currentTime);
          const { data } = response;
          if (data && data.statusCode == '200') {
            dispatch({
              type: DASHBOARD_SUCCESS,
              payload: data,
            });
          }
          return response;
        })
        .catch((error) => {
          dispatch({
            type: DASHBOARD_FAIL,
            error,
          });
        });
    }
  };
}

export function getDashboardDetailsByCustomer({ customerCode, customerName }) {
  return (dispatch, getState, { api, formatParams }) => {
    const authorized = Object.keys(
      getState().authorization.initAuthorization
    ).length;
    if (authorized) {
      dispatch({ type: ADMIN_CUST_DASHBOARD });
      const currentTime = getEpochTime();
      return api
        .post(
          'cds',
          formatParams(
            {
              type: 'CDS_CUSTOMER_DASHBOARD_REPORT',
              details: { customerCode, customerName },
            },
            getState
          )
        )
        .then((response) => {
          gaPageviewTimingTitle(`/CDS/DASHBOARD`, currentTime);
          const { data } = response;
          if (data && data.statusCode == '200') {
            dispatch({
              type: SET_SELECTED_CUSTOMER,
              payload: `${customerCode}$$${customerName}`,
            });
            dispatch({
              type: ADMIN_CUST_DASHBOARD_SUCCESS,
              payload: data.result,
            });
          }

          return response;
        })
        .catch((error) => {
          dispatch({
            type: ADMIN_CUST_DASHBOARD_FAIL,
            error,
          });
        });
    }
  };
}
