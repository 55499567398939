import React from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { Row, Col, Menu, Dropdown, Avatar, Modal, theme } from 'antd';
import {
  AppstoreOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { getInitAuthorization } from './redux/modules/authorization';
import PulseLoader from 'react-spinners/PulseLoader';
import { UseGAEventsTracker } from './../../helpers/utils';
import logo from '../../assets/img/logo/logo.svg';
import SiderProfile from './Sider/SiderProfile';
import { destroyTabs } from './redux/modules/layout';
const Header = (props) => {
  const { portalAccess, authorizationRequest, portalTitle, tabs, userDetails } =
    useSelector(
      ({ authorization, layout: { tabLayout }, profile: { user } }) => ({
        tabs: tabLayout.tabs,
        portalAccess: authorization.initAuthorization.portalAccess,
        authorizationRequest: authorization.authorizationRequest,
        portalTitle: authorization.initAuthorization.portalTitle,
        userDetails: user,
      }),
      shallowEqual
    );
  const defaultAccess = useSelector(
    ({ authorization }) => authorization.initAuthorization.defaultAccess
  );

  const dispatch = useDispatch();
  const { toggle } = props;

  const {
    token: { colorPrimaryActive, colorPrimary, colorText },
  } = theme.useToken();

  const changeModule = (module) => {
    if (tabs.length) {
      Modal.confirm({
        title: 'Changing the Profile Data will close all the opened tabs',
        icon: <ExclamationCircleOutlined />,
        okText: 'Continue',
        okType: 'danger',
        cancelText: 'Cancel',
        onOk() {
          dispatch(destroyTabs());
          dispatch(getInitAuthorization(module, 'switch'));
        },
      });
    } else {
      dispatch(getInitAuthorization(module, 'switch'));
    }
  };

  const GAEventTracker = UseGAEventsTracker('External links');

  const getModuleStyle = (access) => {
    let style = { padding: 10 };
    if (defaultAccess === access) {
      style = { ...style, background: colorPrimaryActive };
    }

    return style;
  };

  const multiModuleMenus = () => {
    return [
      {
        key: 'multiModMenu',
        label: (
          <Row>
            <Col span={24}>
              <Row>
                {portalAccess && portalAccess.length
                  ? portalAccess.map((data) => {
                      return (
                        <Col span={8} key={data.access}>
                          <div
                            title={data.title}
                            style={getModuleStyle(data.access)}
                            onClick={() => changeModule(data.access)}>
                            <a
                              target='_blank'
                              rel='noopener noreferrer'
                              onClick={() =>
                                GAEventTracker(data.access.toUpperCase())
                              }>
                              <Avatar
                                className='multi-module-avatar'
                                style={{ backgroundColor: colorPrimary }}
                                size='large'>
                                {data.access.substr(0, 1).toUpperCase()}
                              </Avatar>
                              <span
                                style={{
                                  display: 'block',
                                  marginTop: 5,
                                  color:
                                    defaultAccess === data.access
                                      ? colorPrimary
                                      : colorText,
                                }}>
                                {data.access}
                              </span>
                            </a>
                          </div>
                        </Col>
                      );
                    })
                  : null}
              </Row>
            </Col>
          </Row>
        ),
      },
    ];
  };

  const getHeaderMenus = () => {
    const menuItems = [];
    if (portalAccess) {
      if (portalAccess.length > 1) {
        menuItems.push({
          key: 'multiModMenuIcon',
          label: (
            <Dropdown
              menu={{ items: multiModuleMenus() }}
              trigger={['click']}
              overlayClassName='multi-mod-menu'
              placement='bottomLeft'>
              <AppstoreOutlined style={{ color: '#FFFFFF', fontSize: 22 }} />
            </Dropdown>
          ),
        });
      }
    }

    return menuItems;
  };
  return (
    <>
      <Row>
        <Col flex={125}>
          <Avatar shape='square' className='logo-avator' src={logo} />
          {props.collapsed ? (
            <MenuUnfoldOutlined className='trigger-red' onClick={toggle} />
          ) : (
            <MenuFoldOutlined className='trigger' onClick={toggle} />
          )}
        </Col>
        <Col flex={400}>
          <div className='title-content' style={{ textAlign: 'center' }}>
            <div className='title-caption'>
              {authorizationRequest && (
                <PulseLoader size={6} color={'#FFFFFF'} loading={true} />
              )}
              {portalTitle ? portalTitle : ''}
            </div>
          </div>
        </Col>
        <Col flex={'auto'} style={{ marginTop: 5, marginRight: 10 }}>
          <>
            <Menu
              items={getHeaderMenus()}
              mode='horizontal'
              className='header-menu-option'
            />
            {userDetails && userDetails.userDetailLoaded && (
              <SiderProfile {...userDetails} />
            )}
          </>
        </Col>
      </Row>
    </>
  );
};

export default Header;
