import React from 'react';
import Cookies from 'js-cookie';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { logout } from '../../../login/redux/modules/login';
import { gaPageviewTimingTitle, getEpochTime } from '../../../../helpers/utils';

const INIT_AUTHORIZATION = 'authorize/INIT_AUTHORIZATION';
const INIT_AUTHORIZATION_SUCCESS = 'authorize/INIT_AUTHORIZATION_SUCCESS';
const INIT_AUTHORIZATION_FAIL = 'authorize/INIT_AUTHORIZATION_FAIL';
const INIT_AUTHORIZATION_RESET = 'authorize/INIT_AUTHORIZATION_RESET';

const initialState = {
  loaded: false,
  loading: false,
  initAuthorization: {},
  forceLogin: '',
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case INIT_AUTHORIZATION:
      return { ...state, loading: true };
    case INIT_AUTHORIZATION_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        initAuthorization: action.initAuthorization,
        forceLogin: action.forceLogin,
        error: null,
      };
    case INIT_AUTHORIZATION_FAIL:
      return { ...state, loading: false, error: action.payload };
    case INIT_AUTHORIZATION_RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}

const handleAuthorizeSuccess = (data, forceLogin) => {
  if (data && data.returnMsg == 'SUCCESS') {
    Cookies.set('firstName', data.result.firstName, { expires: 365 });
    Cookies.set('lastName', data.result.lastName, { expires: 365 });
    if (data.result.defaultAccess == 'CPT') {
      Cookies.set('cpt_userrole', data.result.userRole);
    }

    return {
      type: INIT_AUTHORIZATION_SUCCESS,
      initAuthorization: data.result,
      forceLogin,
    };
  }
};

export function getInitAuthorization(userAccess = '', forceLogin = '') {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: INIT_AUTHORIZATION });
    const authorization = getState().authorization;
    const currentTime = getEpochTime();

    const getForceLoginProp = () => {
      if (authorization.forceLogin !== '') {
        return { forceLogin: authorization.forceLogin };
      } else {
        return { forceLogin };
      }
    };

    return api
      .post(
        'authorize',
        formatParams(
          {
            type: 'AUTHORIZATION_DETAILS',
            details: {
              email: Cookies.get('email'),
              userAccess,
              ...getForceLoginProp(),
            },
          },
          getState
        )
      )
      .then((response) => {
        if (userAccess)
          gaPageviewTimingTitle(`/${userAccess}/updateportal`, currentTime);

        const data = response.data;

        if (forceLogin === 'false') {
          const { navigate } = getState().layout;
          dispatch(logout(false));
          navigate('/');
        } else {
          if (data.statusCode == 200) {
            dispatch(handleAuthorizeSuccess(data, forceLogin));
          } else if (data.statusCode == 201) {
            Modal.confirm({
              title: data.result.title,
              icon: <ExclamationCircleOutlined />,
              content: data.result.message,
              mask: true,
              onOk: () => dispatch(getInitAuthorization('', 'true')),
              onCancel: () => dispatch(getInitAuthorization('', 'false')),
            });
          }
        }

        return response;
      })
      .catch((err) => {
        dispatch({ type: INIT_AUTHORIZATION_FAIL });
        return err;
      });
  };
}

export const resetInitAuthorization = () => {
  return (dispatch) => {
    dispatch({ type: INIT_AUTHORIZATION_RESET });
  };
};
