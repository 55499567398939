import React, { Suspense, lazy } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes as Switch,
} from 'react-router-dom';
import Spinner from '../modules/layout/Loading';
import PrivateRoute from './PrivateRoute';

const Login = lazy(() => import('../modules/login/Login'));
const Dashboard = lazy(() => import('../modules/dashboard/Dashboard'));

/* Routes configuration */
const Routes = () => {
  return (
    <Router>
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route path='/' element={<Login />} />
          <Route
            path='/dashboard'
            element={<PrivateRoute component={Dashboard} />}
          />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default Routes;
