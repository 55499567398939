import Cookie from 'js-cookie';
import { getWalkthrough } from '../../../helpers/utils';
import store from '../../../redux/store';
export const VIEW_TOUR_SUCCESS = 'pc/tour/VIEW_TOUR_SUCCESS';
export const VIEW_TOUR = 'pc/tour/VIEW_TOUR';
const INIT_TOUR_SUCCESS = 'authorize/INIT_TOUR_SUCCESS';
const initialState = {
  tourDetails: {},
  isLoading: false,
  tourReferences: {},
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case VIEW_TOUR:
      return { ...state, isLoading: true };
    case VIEW_TOUR_SUCCESS:
      return { ...state, tourDetails: action.payload, isLoading: false };
    case INIT_TOUR_SUCCESS:
      return {
        ...state,
        tourReferences: action.payload,
      };
    default:
      return state;
  }
}
export function saveTourDetails(tourDetails) {
  return {
    type: INIT_TOUR_SUCCESS,
    payload: tourDetails,
  };
}
export function setWalkthrough(module, screen, label) {
  const walkthrough = store.getState().tour.tourDetails;
  const walkthroughUpdated = {
    ...walkthrough,
    [module.toLowerCase()]: {
      ...(walkthrough && walkthrough[module.toLowerCase()]
        ? walkthrough[module.toLowerCase()]
        : {}),
      [screen]: false,
    },
  };
  return (dispatch, _getState, { api, formatParams }) => {
    return api
      .post(
        'ums',
        formatParams({
          type: 'WALKTHROUGH',
          details: {
            email: Cookie.get('email'),
            walkthrough: walkthroughUpdated,
          },
        })
      )
      .then((response) => {
        const { returnMsg } = response.data;
        if (returnMsg === 'SUCCESS') {
          dispatch(getwalkthroughData());
          if (label !== 'Close') {
            if (module == 'CDS') {
              Cookie.set('walkthroughReportWalk', 'No');
              Cookie.set('walkthroughReportDropWalk', 'No');
              Cookie.set('walkthroughProfileWalk', 'No');
            } else if (module == 'ACP') {
              Cookie.set('walkthroughCandidateWalkAdmin', 'No');
            }
          }
          dispatch(update(walkthroughUpdated));
          Cookie.set('walkthrough', JSON.stringify(walkthroughUpdated));
        }
      });
    function update(data) {
      return { type: VIEW_TOUR_SUCCESS, payload: data };
    }
  };
}

export function getwalkthroughData() {
  return (dispatch, getState, { api, formatParams }) => {
    dispatch({ type: VIEW_TOUR });
    return api
      .post(
        'ums',
        formatParams(
          {
            type: 'WALKTHROUGH_DATA',
            details: {
              email: Cookie.get('email'),
            },
          },
          getState
        )
      )
      .then((response) => {
        const { data } = response;
        const TXNGET = data.TXN;
        if (TXNGET && TXNGET[0].Result) {
          if (TXNGET[0].Result) {
            dispatch(update(TXNGET[0].Result));
          }
          return TXNGET[0].Result;
        }
        return response;
      });
  };
  function update(data) {
    return { type: VIEW_TOUR_SUCCESS, payload: data };
  }
}
