import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Menu, Spin } from 'antd';
import Icon, {
  DashboardOutlined,
  PlusOutlined,
  AppstoreOutlined,
  FolderOpenOutlined,
  CiOutlined,
  FileExcelOutlined,
  BarChartOutlined,
  AreaChartOutlined,
  UsergroupAddOutlined,
  HistoryOutlined,
  TrademarkOutlined,
  QuestionCircleOutlined,
  CheckSquareOutlined,
  SelectOutlined,
  InfoCircleOutlined,
  FormOutlined,
  SettingOutlined,
  FileSearchOutlined,
} from '@ant-design/icons';
import { getExcelTemplateList } from '../../acp/excel-template/redux/excel-template';
import ExcelTemplate from '../../acp/excel-template/ExcelTemplate';

const { SubMenu } = Menu;

const siderIcons = {
  DashboardOutlined,
  PlusOutlined,
  AppstoreOutlined,
  FolderOpenOutlined,
  CiOutlined,
  FileExcelOutlined,
  BarChartOutlined,
  AreaChartOutlined,
  UsergroupAddOutlined,
  HistoryOutlined,
  TrademarkOutlined,
  QuestionCircleOutlined,
  CheckSquareOutlined,
  SelectOutlined,
  InfoCircleOutlined,
  FormOutlined,
  SettingOutlined,
  FileSearchOutlined,
};

const Sider = (props) => {
  const tourReferences = useSelector(({ tour }) => tour.tourReferences);
  // Use the below state and set state for Download Excel Template Functionality
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dispatch = useDispatch();
  //  Use this "excelTemplateData" variable to access the reducer (api response from the service) for the Download Excel Template Functionality
  const { excelTemplateData } = useSelector(({ acp: { excelTemplate } }) => ({
    excelTemplateData: excelTemplate.data,
  }));

  const isSiderLoading = useSelector(({ layout }) => layout.siderLoading);

  const showexceltemplate = () => {
    dispatch(getExcelTemplateList()).then(() => {
      setIsModalVisible(true);
    });
  };

  const getTourRefs = (menuItem) => {
    switch (menuItem) {
      case 'Dashboard':
        return tourReferences.adminCdsDashboard;
      case 'New_Report':
        return tourReferences.newReportCdsTour;
      case 'EXPORT_HISTORY':
        return tourReferences.exportHistoryCdsTour;
      case 'REPORT_SETTINGS':
        return tourReferences.reportSettingsCdsTour;
      case 'REPORT_HISTORY':
        return tourReferences.reportHistoryCdsTour;
      case 'ORDER_STATUS':
        return tourReferences.orderStatusCdsTour;
      case 'ORDER_HISTORY':
        return tourReferences.orderHistoryCdsTour;
      case 'EXCEL_DOWNLOAD':
        return tourReferences.excelDownload;
      case 'CANDIDATE_ITEM':
        return tourReferences.candidateItem;
      default:
        return null;
    }
  };

  const siderItem = (options) => {
    const menuArr = [];
    options.map((menu) => {
      if (menu.submenu) {
        menuArr.push(
          <SubMenu
            key={menu.name}
            title={
              <span className={menu.label.replace(' ', '_')}>
                <Icon component={siderIcons[menu.icon]} />
                <span>{menu.label}</span>
              </span>
            }>
            {menu.childrens.length ? siderItem(menu.childrens) : null}
          </SubMenu>
        );
      } else {
        menuArr.push(
          <Menu.Item key={`${menu.name}`}>
            {(() => {
              if (menu.tab) {
                return (
                  <span
                    className={menu.label.replace(' ', '_')}
                    onClick={props.addTab.bind(
                      this,
                      `${menu.name}`,
                      `${menu.label}`
                    )}
                    ref={getTourRefs(menu.name)}>
                    <Icon component={siderIcons[menu.icon]} />
                    <span className={menu.label.replace(' ', '_')}>
                      {menu.label}
                    </span>
                  </span>
                );
              } else if (menu.name === 'EXCEL_DOWNLOAD') {
                return (
                  <span
                    className={menu.label.replace(' ', '_')}
                    onClick={() => showexceltemplate()}
                    ref={tourReferences.excelDownload}>
                    {' '}
                    {/*We call this showexceltemplate to display the download excel template popup  */}
                    <Icon component={siderIcons[menu.icon]} />
                    <span className={menu.label.replace(' ', '_')}>
                      {menu.label}
                    </span>
                  </span>
                );
              } else {
                return (
                  <Link
                    className={menu.label.replace(' ', '_')}
                    to={`/${menu.name.toLowerCase()}`}
                    onClick={() => props.handlePageClick(menu.name)}>
                    <Icon component={siderIcons[menu.icon]} />
                    <span className={menu.label.replace(' ', '_')}>
                      {menu.label}
                    </span>
                  </Link>
                );
              }
            })()}
          </Menu.Item>
        );
      }
    });

    return menuArr;
  };

  const getLoadingElement = () => [
    <div className='center-align-loader' key={'loading'}>
      <Spin size={'small'} tip='Fetching menus...'>
        <div
          style={{
            padding: 40,
            borderRadius: 4,
          }}
        />
      </Spin>
    </div>,
  ];

  return (
    <>
      <Menu
        theme={props.theme}
        mode={props.mode}
        defaultSelectedKeys={[props.defaultSelectedKeys]}
        selectedKeys={[props.defaultSelectedKeys]}>
        {/** Function should return same type - Sonar Scan Rule */}
        {(() => {
          let siderItems = null;
          if (props.authorizationRequest || isSiderLoading) {
            siderItems = getLoadingElement();
          }

          if (props.menuOptions && props.menuOptions.length) {
            siderItems = siderItem(props.menuOptions);
          }

          return siderItems;
        })()}
      </Menu>
      {/* Use the Component to display the Download Excel Template popup, here we are passing the visible and data for that component */}
      <ExcelTemplate
        visible={isModalVisible}
        data={excelTemplateData}
        handlevisiblity={setIsModalVisible}
      />
    </>
  );
};

export default Sider;
